import { combineReducers, Reducer } from 'redux';
import {
  SellerOrgReducerState,
  SetSellerOrgs,
  APIClientReducerState,
  ContextOptionsAction,
  UsersReducerState,
} from './contextOptions.types';
import {
  SET_SELLER_ORGS,
  SET_API_CLIENTS,
  SET_USERS,
} from './contextOptions.constants';
import { CopyTabContext } from '../contextSelections/contextSelections.types';
import { COPY_TAB_CONTEXT } from '../contextSelections/contextSelections.constants';
const sellerOrgs: Reducer<SellerOrgReducerState, SetSellerOrgs> = (
  state: SellerOrgReducerState = {},
  action: SetSellerOrgs | CopyTabContext
) => {
  switch (action.type) {
    case SET_SELLER_ORGS:
      const { sellerOrgsList, isSearchResult } = action.payload;
      // search results are store on keys of the tabID, if non search shown on defaultTab
      const tabID = isSearchResult ? action.payload.tabID : 'defaultTab';
      if (state[tabID]) {
        const newItems =
          sellerOrgsList.Meta.Page === 1
            ? sellerOrgsList.Items
            : [...state[tabID].Items, ...sellerOrgsList.Items];
        return {
          // Meta should always represent the most recently added items
          ...state,
          [tabID]: {
            Meta: sellerOrgsList.Meta,
            Items: newItems,
          },
        };
      } else {
        return { ...state, [tabID]: sellerOrgsList };
      }
    case COPY_TAB_CONTEXT:
      // when a tab context is copied, the search options need to be copied as well
      const { copiedTabID, newTabID } = action.payload;
      const sellerOrgsListToCopy = state[copiedTabID];
      return { ...state, [newTabID]: sellerOrgsListToCopy };
    default:
      return state;
  }
};

const apiClients: Reducer<APIClientReducerState, ContextOptionsAction> = (
  state: APIClientReducerState = {},
  action: ContextOptionsAction | CopyTabContext
) => {
  switch (action.type) {
    case SET_API_CLIENTS:
      const {
        isSearchResult,
        tabID,
        sellerOrgID,
        apiClientsList,
      } = action.payload;
      const lookupID = isSearchResult ? tabID : sellerOrgID;
      // in this case, so the data as tabID and sellerOrgID concatenated

      const newItems =
        apiClientsList.Meta.Page === 1
          ? apiClientsList.Items
          : [...state[lookupID].Items, ...apiClientsList.Items];

      return {
        ...state,
        [lookupID]: {
          // Meta should always represent the most recently added items
          Meta: apiClientsList.Meta,
          Items: newItems,
        },
      };
    case COPY_TAB_CONTEXT:
      const { copiedTabID, newTabID } = action.payload;
      const apiClientListToCopy = state[copiedTabID];
      return {
        ...state,
        [newTabID]: apiClientListToCopy,
      };
    default:
      return state;
  }
};

const users: Reducer<UsersReducerState, ContextOptionsAction> = (
  state: UsersReducerState = {},
  action: ContextOptionsAction | CopyTabContext
) => {
  switch (action.type) {
    case SET_USERS:
      const { userList, tabID } = action.payload;
      const newItems =
        userList.Meta.Page === 1
          ? userList.Items
          : [...state[tabID].Items, ...userList.Items];
      return {
        ...state,
        [tabID]: {
          Items: newItems,
          Meta: userList.Meta,
        },
      };
    case COPY_TAB_CONTEXT:
      const { copiedTabID, newTabID } = action.payload;
      const usersListToCopy = state[copiedTabID];
      return {
        ...state,
        [newTabID]: usersListToCopy,
      };
    default:
      return state;
  }
};

export default combineReducers({ sellerOrgs, apiClients, users });
