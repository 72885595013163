import React from 'react';
import { Tooltip } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { maroon } from '../../../../../themes/ocPalette.constants';
import FieldSection from '../FieldSection';

interface ClientIDProps {
  clientID: string;
}

const apiClientStyles = {
  fontFamily: 'monospace',
  color: maroon[500],
};

class ClientID extends React.Component<ClientIDProps> {
  state = {
    toolTipOpen: false,
  };

  handleTooltipClose = () => {
    setTimeout(() => {
      this.setState({ toolTipOpen: false });
    }, 2000);
  };

  handleTooltipOpen = () => {
    this.setState({ toolTipOpen: true });
    this.handleTooltipClose();
  };

  stopPropagation = event => {
    event.stopPropagation();
  };

  public render() {
    return (
      <FieldSection
        headerText="Client ID"
        infoText="Read-only unique ID is used for OAuth 2.0 workflows and OrderCloud
      impersonation to represent this Client Application.
      "
      >
        <span style={apiClientStyles}>{this.props.clientID}</span>
        <span style={{ marginLeft: '20px' }} />
        <Tooltip
          PopperProps={{ disablePortal: true }}
          onClose={this.handleTooltipClose}
          open={this.state.toolTipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied"
        >
          <CopyToClipboard
            text={this.props.clientID}
            onCopy={this.handleTooltipOpen}
          >
            <IconButton aria-label="copy" onClick={this.stopPropagation}>
              <CopyIcon
                color={this.state.toolTipOpen ? 'secondary' : 'disabled'}
              />
            </IconButton>
          </CopyToClipboard>
        </Tooltip>
      </FieldSection>
    );
  }
}

export default ClientID;
