export const CREATE_TAB = 'CREATE_TAB';
export type CREATE_TAB = typeof CREATE_TAB;

export const COPY_TAB = 'COPY_TAB';
export type COPY_TAB = typeof COPY_TAB;

export const DESTROY_TABS = 'DESTROY_TABS';
export type DESTROY_TABS = typeof DESTROY_TABS;

export const SET_TAB = 'SET_TAB';
export type SET_TAB = typeof SET_TAB;

export const SET_TAB_RESOURCE = 'SET_TAB_RESOURCE';
export type SET_TAB_RESOURCE = typeof SET_TAB_RESOURCE;

export const SET_TAB_OPERATION = 'SET_TAB_OPERATION';
export type SET_TAB_OPERATION = typeof SET_TAB_OPERATION;

export const SET_CONTEXT = 'SET_CONTEXT';
export type SET_CONTEXT = typeof SET_CONTEXT;

export const SET_TAB_NAME = 'SET_TAB_NAME';
export type SET_TAB_NAME = typeof SET_TAB_NAME;

export const SET_TAB_ENTRY_TYPE = 'SET_TAB_ENTRY_TYPE';
export type SET_TAB_ENTRY_TYPE = typeof SET_TAB_ENTRY_TYPE;

export const SET_TAB_REQUEST = 'SET_TAB_REQUEST';
export type SET_TAB_REQUEST = typeof SET_TAB_REQUEST;

export const SET_TAB_TOKEN = 'SET_TAB_TOKEN';
export type SET_TAB_TOKEN = typeof SET_TAB_TOKEN;

export const CLEAR_TAB_TOKENS = 'CLEAR_TAB_TOKENS';
export type CLEAR_TAB_TOKENS = typeof CLEAR_TAB_TOKENS;

export const DEFAULT_TAB_ID = '0000000000000';
export const DEFAULT_TABS = {
  [DEFAULT_TAB_ID]: { id: DEFAULT_TAB_ID, name: 'Untitled', entryType: 'form' },
};
export const DEFAULT_TAB_IDS = [Object.keys(DEFAULT_TABS)[0]];
export const TAB_ID_LENGTH = DEFAULT_TAB_ID.length;

export const THIS_TAB = 'THIS_TAB';
export type THIS_TAB = typeof THIS_TAB;

export const OTHER_TABS = 'OTHER_TABS';
export type OTHER_TABS = typeof OTHER_TABS;

export const TABS_TO_THE_RIGHT = 'TABS_TO_THE_RIGHT';
export type TABS_TO_THE_RIGHT = typeof TABS_TO_THE_RIGHT;

export const TABS_TO_THE_LEFT = 'TABS_TO_THE_LEFT';
export type TABS_TO_THE_LEFT = typeof TABS_TO_THE_LEFT;

export const ALL_TABS = 'ALL_TABS';
export type ALL_TABS = typeof ALL_TABS;

export const MOVE_TAB = 'MOVE_TAB';
export type MOVE_TAB = typeof MOVE_TAB;

export const SET_IS_VIEWING_PRETTY_UI = 'SET_IS_VIEWING_PRETTY_UI';
export type SET_IS_VIEWING_PRETTY_UI = typeof SET_IS_VIEWING_PRETTY_UI;
