import { Hidden, useMediaQuery } from '@material-ui/core';
import React, { Fragment, useMemo, useState } from 'react';
import PortalNavigation, {
  DEFAULT_PORTAL_NAVIGATION_WIDTH,
  MINI_PORTAL_NAVIGATION_WIDTH,
} from './PortalNavigation';
import Header, { navHeight, navHeightMobile } from './Header';
import { PortalAuth } from '../Shared/hooks/usePortalAuth';

interface LayoutProps {
  children: any;
  location: any;
  history: any;
  onLogout: PortalAuth['logout'];
}

const Layout = (props: LayoutProps) => {
  const { children, location, history, onLogout } = props;
  const isMobile = useMediaQuery<any>(theme => theme.breakpoints.down('sm'));
  const isConsole = useMemo(() => {
    return location.pathname === '/console';
  }, [location.pathname]);

  const [menuOpen, setMenuOpen] = useState(true);
  return (
    <Fragment>
      <Header history={history} onLogout={onLogout} />
      <Hidden smDown>
        <PortalNavigation
          history={history}
          permanent={!isConsole}
          open={menuOpen}
          onSetMenu={setMenuOpen}
        />
      </Hidden>
      <main
        style={{
          marginTop: isMobile ? navHeightMobile : navHeight,
          marginLeft: isMobile
            ? undefined
            : isConsole
            ? MINI_PORTAL_NAVIGATION_WIDTH
            : DEFAULT_PORTAL_NAVIGATION_WIDTH,
        }}
      >
        {children}
      </main>
    </Fragment>
  );
};

export default Layout;
