import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './config/yup-additions';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Configuration } from 'ordercloud-javascript-sdk';
import appConstants from './config/app.constants';
import Application from './components/App/Application';

Configuration.Set({
  baseApiUrl: appConstants.ocApiUrl,
  apiVersion: 'v1',
});

ReactDOM.render(
  <Application />,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
