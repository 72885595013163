import React from 'react';
import { ApiClient, ApiClientAssignment } from 'ordercloud-javascript-sdk';
import { TextField, Typography, Switch } from '@material-ui/core';
import ClientID from './ClientID';
import ClientSecret from './ClientSecret';
import TokenDurations from './TokenDuration';
import ClientAccessConfiguration from './ClientAccessConfiguration';
import DefaultContextUser from './DefaultContextUser';
import FieldSection from '../FieldSection';

interface EditApiClientProps {
  hasEditAccess: boolean;
  isCreatingNew: boolean;
  apiClientUpdated: Partial<ApiClient>;
  handleChangeEvent: (field: string) => () => void;
  handleChangeValue: (field: string) => (newValue: any) => void;
  apiClientAssignmentsUpdated: ApiClientAssignment[];
  setApiClientAssignmentsUpdated: (
    apiClientAssignments: ApiClientAssignment[]
  ) => void;
}

function EditApiClient(props: EditApiClientProps) {
  const {
    hasEditAccess,
    handleChangeEvent,
    handleChangeValue,
    apiClientUpdated,
    isCreatingNew,
    setApiClientAssignmentsUpdated,
    apiClientAssignmentsUpdated,
  } = props;

  return (
    <React.Fragment>
      <FieldSection headerText="General Information">
        <TextField
          disabled={!hasEditAccess}
          InputProps={{
            readOnly: !hasEditAccess,
          }}
          fullWidth
          label="Application Name *"
          value={(apiClientUpdated && apiClientUpdated.AppName) || ''}
          margin="normal"
          error={!apiClientUpdated.AppName}
          helperText={!apiClientUpdated.AppName ? 'Must provide Name' : ' '}
          onChange={handleChangeEvent('AppName')}
          variant="outlined"
        />
        <div
          style={{
            marginBottom: '40px',
          }}
        />
        <Typography variant="h6" display="inline">
          Enabled?
        </Typography>
        <Switch
          disabled={!hasEditAccess}
          color="secondary"
          checked={apiClientUpdated && apiClientUpdated.Active}
          onChange={() => handleChangeValue('Active')(!apiClientUpdated.Active)}
        />
      </FieldSection>
      {!isCreatingNew && (
        <ClientID clientID={(apiClientUpdated && apiClientUpdated.ID) || ''} />
      )}
      <ClientSecret
        readOnly={!hasEditAccess}
        onChange={handleChangeEvent('ClientSecret')}
        clientSecret={(apiClientUpdated && apiClientUpdated.ClientSecret) || ''}
      />
      <TokenDurations
        readOnly={!hasEditAccess}
        apiClient={apiClientUpdated}
        onChange={handleChangeEvent}
      />
      <ClientAccessConfiguration
        readOnly={!hasEditAccess}
        apiClientUpdated={apiClientUpdated}
        onChange={handleChangeValue}
        apiClientID={apiClientUpdated.ID || ''}
        setApiClientAssignmentsUpdated={setApiClientAssignmentsUpdated}
        apiClientAssignmentsUpdated={apiClientAssignmentsUpdated}
      />
      <DefaultContextUser
        readOnly={!hasEditAccess}
        apiClientUpdated={apiClientUpdated}
        isCreatingNew={isCreatingNew}
        currentDefaultContextUsername={
          apiClientUpdated && apiClientUpdated.DefaultContextUserName
        }
        handleChangeDefaultContextUser={handleChangeValue(
          'DefaultContextUserName'
        )}
      />
      <FieldSection
        headerText="Anonymous Buyer"
        infoText="This setting enables anonymous shopping. It only works when a Buyer User is the Default Context User. You will only be able to authenticate anonymously for that user's specific Buyer Company."
      >
        <Typography variant="h6" display="inline">
          Enabled?
        </Typography>
        <Switch
          disabled={!hasEditAccess}
          color="secondary"
          checked={apiClientUpdated && apiClientUpdated.IsAnonBuyer}
          onChange={() =>
            handleChangeValue('IsAnonBuyer')(!apiClientUpdated.IsAnonBuyer)
          }
        />
      </FieldSection>
      <FieldSection headerText="Checkout Integration Event ID">
        <TextField
          disabled={!hasEditAccess}
          InputProps={{
            readOnly: !hasEditAccess,
          }}
          fullWidth
          label="Checkout Integration Event ID"
          value={
            (apiClientUpdated &&
              apiClientUpdated.OrderCheckoutIntegrationEventID) ||
            ''
          }
          margin="normal"
          onChange={handleChangeEvent('OrderCheckoutIntegrationEventID')}
          variant="outlined"
        />
      </FieldSection>
      <FieldSection headerText="Order Return Integration Event ID">
        <TextField
          disabled={!hasEditAccess}
          InputProps={{
            readOnly: !hasEditAccess,
          }}
          fullWidth
          label="Order Return Integration Event ID"
          value={
            (apiClientUpdated &&
              apiClientUpdated.OrderReturnIntegrationEventID) ||
            ''
          }
          margin="normal"
          onChange={handleChangeEvent('OrderReturnIntegrationEventID')}
          variant="outlined"
        />
      </FieldSection>
    </React.Fragment>
  );
}

export default EditApiClient;
