import { Dispatch } from 'react';
import {
  RequestStartAction,
  RequestClearErrorAction,
  RequestEndAction,
  RequestFailureAction,
} from './requestStatus.types';
import {
  REQUEST_START,
  REQUEST_CLEAR_ERROR,
  REQUEST_END,
  REQUEST_FAILURE,
} from './requestStatus.constants';

export default async function asyncWrapper(
  dispatch: Dispatch<any>,
  requestName: string,
  asyncRequest: () => {}
): Promise<string> {
  dispatch(requestClearError(requestName));
  dispatch(requestStart(requestName));
  try {
    await asyncRequest();
    dispatch(requestEnd(requestName));
    return new Promise(resolve => {
      resolve('success');
    });
  } catch (error) {
    dispatch(requestEnd(requestName));
    dispatch(requestFailure(requestName, error));
    return new Promise(resolve => {
      resolve('failure');
    });
  }
}

function requestStart(requestName: string): RequestStartAction {
  return {
    type: REQUEST_START,
    payload: {
      requestName,
    },
  };
}

function requestClearError(requestName: string): RequestClearErrorAction {
  return {
    type: REQUEST_CLEAR_ERROR,
    payload: {
      requestName,
    },
  };
}

function requestEnd(requestName: string): RequestEndAction {
  return {
    type: REQUEST_END,
    payload: {
      requestName,
    },
  };
}

function requestFailure(requestName: string, error): RequestFailureAction {
  return {
    type: REQUEST_FAILURE,
    payload: {
      requestName,
      error,
    },
  };
}
