import React from 'react';
import { RoleComparison } from './requestStatus';
import {
  Paper,
  createStyles,
  Theme,
  Typography,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { red, teal } from '@material-ui/core/colors';

interface RolesRequiredComparison {
  roleComparison: RoleComparison;
  resourceName: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperRoot: {
      width: '100%',
      overflowX: 'auto',
      backgroundColor: red[50],
      padding: theme.spacing(2),
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    chipAssigned: {
      marginRight: '2px',
      marginTop: '2px',
    },
    chipRequired: {
      backgroundColor: red[300],
      marginRight: '2px',
      marginTop: '2px',
    },
    chipContainer: {
      border: `2px dotted ${teal[200]}`,
      borderRadius: theme.spacing(2),
      padding: '3px',
    },
  })
);

export function RolesRequiredComparison(props: RolesRequiredComparison) {
  const classes = useStyles();

  return (
    <Paper className={classes.paperRoot}>
      <Typography variant="h5" display="inline">
        Authentication Failure
      </Typography>
      <Typography variant="body1">
        Your OrderCloud user does not have the data access required to manage{' '}
        {props.resourceName}. You can be granted access by a user with user
        administration priviledges in the organizations contributor settings.
      </Typography>{' '}
      <Typography variant="h6" display="inline" className={classes.marginTop}>
        Required Roles
      </Typography>
      <div className={classes.chipContainer}>
        {props.roleComparison.RequiredRoles.map(role => (
          <Chip
            label={role}
            key={role + 'a'}
            color="secondary"
            className={classes.chipRequired}
          />
        ))}
      </div>
      <Typography variant="h6" display="inline" className={classes.marginTop}>
        Your Assigned Roles
      </Typography>
      <div className={classes.chipContainer}>
        {props.roleComparison.AssignedRoles.map(role => (
          <Chip
            label={role}
            key={role + 'a'}
            color="secondary"
            className={classes.chipAssigned}
          />
        ))}
      </div>
    </Paper>
  );
}
