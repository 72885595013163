import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: `calc(100vh - ${theme.spacing(16)}px)`,
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

const NoMatchPage = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h1" display="block">
        404 - Not Found
      </Typography>
      <Typography variant="h4" display="block" style={{ marginBottom: 16 }}>
        This page does not exist
      </Typography>
      <Button size="large" href="/" color="primary" variant="contained">
        Return To Dashboard
      </Button>
    </div>
  );
};

export default NoMatchPage;
