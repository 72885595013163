export type Environment = 'local' | 'qa' | 'staging' | 'sandbox' | 'prod';

const appConstants = getAppConstants();
export default appConstants;

interface AppConstants {
  environment: Environment;
  clientID: string;
  algoliaAppID: string;
  algoliaSearchApiKey: string;
  algoliaIndexName: string;
  algoliaSortByIndexName: string;
  recaptchaClientKey: string;
  docsUrl: string;
  middlewareUrl: string;
  ocAuthUrl: string;
  ocApiUrl: string;
  choneDeliveryApiKey: string;
  choneDeliveryApiUrl: string;
}

function getAppConstants(): AppConstants {
  const environment = getEnvironment();

  let result: AppConstants;
  switch (environment) {
    case 'local': {
      result = {
        environment: 'local',
        clientID: '6cc9c96c-203f-4f40-9f4b-21aa84b4b386',
        algoliaAppID: '6HHOY2V1LG',
        algoliaSearchApiKey: '8c758419bad069a3a8a6cf54b0a47251',
        algoliaIndexName: 'ordercloud_redesigned',
        algoliaSortByIndexName: 'articles_date_desc', // KB index sorted by publish date
        recaptchaClientKey: '',
        docsUrl: 'http://localhost:8000',
        ocApiUrl: 'https://qaapi.ordercloud.io',
        ocAuthUrl: 'https://qaauth.ordercloud.io',
        middlewareUrl:
          process.env.REACT_APP_LOCAL_MIDDLEWARE_URL ||
          'https://portal.ordercloud-qa.com',
        choneDeliveryApiKey:
          'ODBUOWtLWWNUVHFCNlpTVXAyZEY1U3VVbjNqK09sRTU3NU9tUzd4NWFMVT18aGMtY2xvdWQtY29tbWVyY2UtMDQwM2Q=',
        choneDeliveryApiUrl: 'https://edge.sitecorecloud.io/api/graphql/v1',
      };
      break;
    }
    case 'qa':
      result = {
        environment: 'qa',
        clientID: '6cc9c96c-203f-4f40-9f4b-21aa84b4b386',
        algoliaAppID: '6HHOY2V1LG',
        algoliaSearchApiKey: '8c758419bad069a3a8a6cf54b0a47251',
        algoliaIndexName: 'ordercloud_redesigned',
        algoliaSortByIndexName: 'articles_date_desc',
        recaptchaClientKey: '6LdZzXokAAAAAClY5XqVaA6_353_1sPPi0ZtjXYc',
        docsUrl: 'https://docs.ordercloud-qa.com',
        ocApiUrl: 'https://qaapi.ordercloud.io',
        ocAuthUrl: 'https://qaauth.ordercloud.io',
        middlewareUrl: window.location.origin,
        choneDeliveryApiKey:
          'ODBUOWtLWWNUVHFCNlpTVXAyZEY1U3VVbjNqK09sRTU3NU9tUzd4NWFMVT18aGMtY2xvdWQtY29tbWVyY2UtMDQwM2Q=',
        choneDeliveryApiUrl: 'https://edge.sitecorecloud.io/api/graphql/v1',
      };
      break;
    case 'staging':
      result = {
        environment: 'staging',
        clientID: '9457433c-510f-481c-bd59-a3da9ba73722',
        algoliaAppID: '6HHOY2V1LG',
        algoliaSearchApiKey: '8c758419bad069a3a8a6cf54b0a47251',
        algoliaIndexName: 'ordercloud_redesigned',
        algoliaSortByIndexName: 'articles_date_desc',
        recaptchaClientKey: '6LdZzXokAAAAAClY5XqVaA6_353_1sPPi0ZtjXYc',
        docsUrl: 'https://docs.ordercloud-qa.com',
        ocApiUrl: 'https://stagingapi.ordercloud.io',
        ocAuthUrl: 'https://stagingauth.ordercloud.io',
        middlewareUrl: window.location.origin,
        choneDeliveryApiKey:
          'ODBUOWtLWWNUVHFCNlpTVXAyZEY1U3VVbjNqK09sRTU3NU9tUzd4NWFMVT18aGMtY2xvdWQtY29tbWVyY2UtMDQwM2Q=',
        choneDeliveryApiUrl: 'https://edge.sitecorecloud.io/api/graphql/v1',
      };
      break;
    case 'sandbox':
      result = {
        environment: 'sandbox',
        clientID: 'a1e557dc-7c0a-44af-97fc-7faa2c19003c',
        algoliaAppID: '6HHOY2V1LG',
        algoliaSearchApiKey: '8c758419bad069a3a8a6cf54b0a47251',
        algoliaIndexName: 'ordercloud_redesigned',
        algoliaSortByIndexName: 'articles_date_desc',
        recaptchaClientKey: '6LdZzXokAAAAAClY5XqVaA6_353_1sPPi0ZtjXYc',
        docsUrl: 'https://docs.ordercloud-qa.com',
        ocApiUrl: 'https://sandboxapi.ordercloud.io',
        ocAuthUrl: 'https://sandboxauth.ordercloud.io',
        middlewareUrl: window.location.origin,
        choneDeliveryApiKey:
          'ODBUOWtLWWNUVHFCNlpTVXAyZEY1U3VVbjNqK09sRTU3NU9tUzd4NWFMVT18aGMtY2xvdWQtY29tbWVyY2UtMDQwM2Q=',
        choneDeliveryApiUrl: 'https://edge.sitecorecloud.io/api/graphql/v1',
      };
      break;
    case 'prod':
      result = {
        environment: 'prod',
        clientID: 'b097a9aa-5bc3-4937-b6bb-6ea37e004486',
        algoliaAppID: '6HHOY2V1LG',
        algoliaSearchApiKey: '8c758419bad069a3a8a6cf54b0a47251',
        algoliaIndexName: 'ordercloud_redesigned',
        algoliaSortByIndexName: 'articles_date_desc',
        recaptchaClientKey: '6LdZzXokAAAAAClY5XqVaA6_353_1sPPi0ZtjXYc',
        docsUrl: 'https://ordercloud.io',
        ocApiUrl: 'https://api.ordercloud.io',
        ocAuthUrl: 'https://auth.ordercloud.io',
        middlewareUrl: window.location.origin,
        choneDeliveryApiKey:
          'ODBUOWtLWWNUVHFCNlpTVXAyZEY1U3VVbjNqK09sRTU3NU9tUzd4NWFMVT18aGMtY2xvdWQtY29tbWVyY2UtMDQwM2Q=',
        choneDeliveryApiUrl: 'https://edge.sitecorecloud.io/api/graphql/v1',
      };
  }

  return result;
}

function getEnvironment(): Environment {
  switch (window.location.hostname) {
    case 'ocportal.azurewebsites.net':
    case 'portal.ordercloud.io':
    case 'ocportalprod-portal.azurewebsites.net':
      return 'prod';
    case 'ocportaltest.azurewebsites.net':
    case 'portal.octestregion.com':
    case 'oc-portalqa.azurewebsites.net':
    case 'portal.ordercloud-qa.com':
    case 'ocportalqa-portal.azurewebsites.net':
      return 'qa';
    case 'ocportalstaging-portal.azurewebsites.net':
    case 'portal.ordercloud-staging.com':
      return 'staging';
    default:
      return 'local';
  }
}
