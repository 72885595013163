import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { User } from '@ordercloud/portal-javascript-sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { updateDevcenterUser } from '../../redux/auth/authThunk.actions';
import { isValidInput } from '../../services/utilites.helper';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = (value?: string) => {
  if (!value) return false;
  return EMAIL_REGEX.test(String(value).toLowerCase());
};

const UpdateUsernameDialog = (props: any) => {
  const { user, updateUser } = props;

  const [username, setUsername] = useState('');

  const usernameIsEmail = useMemo(() => {
    return isEmail(user.Username);
  }, [user]);

  const usernameIsInvalid = useMemo(() => {
    return !isValidInput(user.Username);
  }, [user]);

  const newUsernameIsInvalid = useMemo(() => {
    return !isValidInput(username);
  }, [username]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!username) return;
      let userToUpdate = Object.assign({}, user, { Username: username });
      // check if name is also invalid
      // if so, scrub invalid special chars so user can submit username change
      if (user.Name && !isValidInput(user.Name, true)) {
        const cleanName = user.Name.replace(
          /[~`@!#$%^&*+=[\];,/{}|\\":<>?]/g,
          ''
        );
        Object.assign(userToUpdate, { Name: cleanName });
      }
      updateUser(userToUpdate);
    },
    [updateUser, user, username]
  );

  useEffect(() => {
    setUsername('');
  }, [user.Username]);

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
  };

  return (
    <Dialog
      open={usernameIsEmail || usernameIsInvalid}
      onClose={handleClose}
      disableEscapeKeyDown
      aria-labelledby="update-username-dialog"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="update-username-dialog">
          Change Your Username
        </DialogTitle>
        <DialogContent>
          {usernameIsEmail ? (
            <DialogContentText>
              Usernames are now visible to all Portal users. Your username is
              currently <b>{user.Username}</b>. Please update your username to
              something other than an email address.
            </DialogContentText>
          ) : (
            <DialogContentText>
              Only usernames with characters Aa-Zz 0-9 - _ are allowed. Your
              username is currently <b>{user.Username}</b>. Please update your
              username to follow these new standards.
            </DialogContentText>
          )}
          <TextField
            autoFocus
            id="username"
            label="New Username"
            type="text"
            fullWidth
            required
            variant="outlined"
            value={username}
            error={Boolean(username && newUsernameIsInvalid)}
            helperText={
              Boolean(username && newUsernameIsInvalid) &&
              `Username can only contain characters Aa-Zz 0-9 - _`
            }
            onChange={e => setUsername(e.target.value)}
          />
          <DialogActions>
            <Button
              type="submit"
              disabled={!username || newUsernameIsInvalid}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    user: state.devCenterUser,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (user: User) => {
      return dispatch(updateDevcenterUser(user));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateUsernameDialog);
