import React from 'react';
import { Switch, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

interface PrehookToggleProps {
  readOnly?: boolean;
  isPrehook: boolean;
  onChange: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    webhookInfoContainer: {
      width: '100%',
      margin: 0,
    },
  })
);

function PrehookToggle(props: PrehookToggleProps) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div
        style={{
          marginBottom: '40px',
        }}
      />
      <Typography variant="h6" display="inline">
        Pre-hook
      </Typography>
      <Switch
        disabled={props.readOnly}
        color="primary"
        checked={props.isPrehook}
        onChange={() => props.onChange(!props.isPrehook)}
      />
      <div className={classes.webhookInfoContainer}>
        <Typography
          classes={{ root: classes.webhookInfoContainer }}
          variant="body1"
        >
          When checked the url will be called before the OrderCloud trigger call
          is made. If the webhook acceptor returns{' '}
          <code>{'{proceed: true}'}</code> the call will be made to OrderCloud.
          If the webhook acceptor returns <code>{'{proceed: false}'}</code> the
          call will not be made.
        </Typography>
      </div>
    </React.Fragment>
  );
}

export default PrehookToggle;
