import React from 'react';
import PrettyResourceNavigation from '../PrettyResourceNavigation';
import {
  ApiClient,
  ApiClients as ocApiClients,
} from 'ordercloud-javascript-sdk';
import ApiClientTableRow, {
  ApiClientTableHeaderRow,
} from './ApiClientTableRows';
import EditApiClient from './EditApiClient';
import { API_CLIENTS } from '../PrettyResourceNavigation/constants';

const newApiClient: Partial<ApiClient> = {
  AccessTokenDuration: 600,
  Active: true,
  AllowAnyBuyer: false,
  AllowAnySupplier: false,
  AllowSeller: false,
  AppName: 'New API Client',
  AssignedBuyerCount: 0,
  AssignedSupplierCount: 0,
  IsAnonBuyer: false,
  RefreshTokenDuration: 0,
  xp: null,
};

const isValidApiClient = (apiClient: Partial<ApiClient>) => {
  return (
    !!apiClient.AppName &&
    (!apiClient.AccessTokenDuration ||
      (!isNaN(apiClient.AccessTokenDuration) &&
        apiClient.AccessTokenDuration >= 10 &&
        apiClient.AccessTokenDuration <= 600))
  );
};

function ApiClients() {
  return (
    <PrettyResourceNavigation
      editRole="ApiClientAdmin"
      headerComponent={ApiClientTableHeaderRow}
      tableRowComponent={ApiClientTableRow}
      isValidResource={isValidApiClient}
      renderEditComponent={(
        hasEditAccess,
        updatedResource,
        resources,
        handleChangeEvent,
        handleChangeValue,
        handleChangeObject,
        isCreatingNew,
        apiClientAssignmentsUpdated,
        setApiClientAssignmentsUpdated
      ) => (
        <EditApiClient
          hasEditAccess={hasEditAccess}
          apiClientUpdated={updatedResource}
          handleChangeEvent={handleChangeEvent}
          handleChangeValue={handleChangeValue}
          isCreatingNew={isCreatingNew}
          apiClientAssignmentsUpdated={apiClientAssignmentsUpdated}
          setApiClientAssignmentsUpdated={setApiClientAssignmentsUpdated}
        />
      )}
      resourceName="API Clients"
      emptyResource={newApiClient}
      ocService={ocApiClients}
      numberOfTableColumns={6}
      resourceType={API_CLIENTS}
    />
  );
}

export default ApiClients;
