import React from 'react';
import PrettyResourceNavigation from '../PrettyResourceNavigation';
import {
  ApiRole,
  SecurityProfiles as ocSecurityProfiles,
} from 'ordercloud-javascript-sdk';
import EditSecurityProfile from './EditSecurityProfile';
import {
  SecurityProfileTableRow,
  SecurityProfileTableHeaderRow,
} from './SecurityProfileTableRows';
import { SECURITY_PROFILES } from '../PrettyResourceNavigation/constants';

export interface PasswordConfig2 {
  LimitPasswordReuse?: number;
  MaxConsecutiveDupeChars?: number;
  MaximumPasswordAge?: number;
  MinimumPasswordAge?: number;
  AllowedFailedAttempts?: number;
  LockoutDuration?: number;
  UpperCaseRequired?: boolean;
  LowerCaseRequired?: boolean;
  SpecialCharacterRequired?: boolean;
  NumericRequired?: boolean;
  MinimumCharacterCount?: number;
}

export interface SecurityProfile2 {
  ID?: string;
  Name: string;
  Roles?: ApiRole[];
  CustomRoles?: string[];
  PasswordConfig: PasswordConfig2;
}

const newSecurityProfile: Partial<SecurityProfile2> = {
  ID: '',
  Name: 'New Security Profile',
  Roles: [],
  CustomRoles: [],
};

type PasswordConfigNumberErrors = {
  [Property in keyof PasswordConfig2]: string;
};

const checkValidNumberValue = (
  errors: PasswordConfigNumberErrors,
  config: PasswordConfig2,
  key: keyof PasswordConfig2,
  min?: number,
  max?: number
) => {
  const value = config[key];
  if (!value) return;
  if (min && value < min) return (errors[key] = `Minimum value is ${min}`);
  if (max && value > max) return (errors[key] = `Maximum value is ${max}`);
};

export const isValidPasswordConfig = (config?: PasswordConfig2) => {
  const errors: PasswordConfigNumberErrors = {};
  if (!config) return errors;

  checkValidNumberValue(errors, config, 'MinimumCharacterCount', 10);
  checkValidNumberValue(errors, config, 'MaxConsecutiveDupeChars', 1, 24);
  checkValidNumberValue(errors, config, 'MinimumPasswordAge', 1, 60);
  checkValidNumberValue(errors, config, 'MaximumPasswordAge', 1, 365);
  checkValidNumberValue(errors, config, 'LimitPasswordReuse', 0, 24);
  checkValidNumberValue(errors, config, 'AllowedFailedAttempts', 1, 10);
  checkValidNumberValue(errors, config, 'LockoutDuration', 10, 1440);
  return errors;
};

const isValidSecurityProfile = (securityProfile: Partial<SecurityProfile2>) => {
  // ensure there is a name and there is either no password config or there is one with
  // and ExpireInDays that is a valid number greater than 0 or falsy ("")
  return (
    !!securityProfile.Name &&
    Object.keys(isValidPasswordConfig(securityProfile.PasswordConfig))
      .length === 0
  );
};

function SecurityProfiles() {
  return (
    <PrettyResourceNavigation
      headerComponent={SecurityProfileTableHeaderRow}
      tableRowComponent={SecurityProfileTableRow}
      isValidResource={isValidSecurityProfile}
      editRole={'SecurityProfileAdmin'}
      renderEditComponent={(
        hasEditAccess,
        updatedResource,
        resources,
        handleChangeEvent,
        handleChangeValue,
        handleChangeObject
      ) => (
        <EditSecurityProfile
          hasEditAccess={hasEditAccess}
          securityProfileUpdated={updatedResource}
          handleChangeEvent={handleChangeEvent}
          handleChangeValue={handleChangeValue}
          handleChangeObject={handleChangeObject}
        />
      )}
      resourceName="Security Profiles"
      emptyResource={newSecurityProfile}
      ocService={ocSecurityProfiles}
      numberOfTableColumns={6}
      resourceType={SECURITY_PROFILES}
    />
  );
}

export default SecurityProfiles;
