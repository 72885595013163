import { TableCell } from '@material-ui/core';
import React from 'react';
import { MessageSender } from 'ordercloud-javascript-sdk';
import MessageTypeIndicator from './MessageTypeIndicator';
import { orderCloudMandrillIntegrationURL } from './constants';
import { maroon } from '../../../../../themes/ocPalette.constants';

export function MessageSenderTableHeaderRow() {
  return (
    <React.Fragment>
      <TableCell align="left">Message Type</TableCell>
      <TableCell align="left">Custom URL</TableCell>
      <TableCell align="left"># Messages Sent</TableCell>
    </React.Fragment>
  );
}

function MessageSenderTableRow(messageSender: MessageSender) {
  return (
    <React.Fragment>
      <TableCell align="left">
        <MessageTypeIndicator messageSender={messageSender} />{' '}
      </TableCell>
      <TableCell align="left">
        <MessageSenderURL URL={messageSender.URL || ''} />
      </TableCell>
      <TableCell align="left">{messageSender.MessageTypes.length}</TableCell>
    </React.Fragment>
  );
}

const urlStyle = {
  fontFamily: 'monospace',
  color: maroon[500],
};
interface MessageSenderURLProps {
  URL: string;
}
const MessageSenderURL = (props: MessageSenderURLProps) => {
  if (props.URL === orderCloudMandrillIntegrationURL) {
    return null;
  } else {
    return <span style={urlStyle}>{props.URL}</span>;
  }
};

export default MessageSenderTableRow;
