import {
  AppBar,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  makeStyles,
  Paper,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CreateNewIcon from '@material-ui/icons/SaveAlt';
import cloneDeep from 'lodash/cloneDeep';
import { ApiClientAssignment, ApiClients } from 'ordercloud-javascript-sdk';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppReducerState } from '../../../../../redux';
import AuthService from '../../../../../services/auth.service';
import { RedButton } from '../../../../Styled/Button';
import { API_CLIENTS, PrettyResourceType } from './constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '78%',
      position: 'relative',
    },
    topBar: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      minHeight: `48px !important`,
      [theme.breakpoints.up('sm')]: {
        minHeight: '64px !important',
      },
    },
    bottomBar: {
      borderTop: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      bottom: 0,
    },
    grow: {
      flexGrow: 1,
    },
    buttonWithIcon: {
      paddingLeft: theme.spacing(1.5),
    },
    buttonIcon: {
      marginRight: theme.spacing(0.5),
      fontSize: theme.typography.h4.fontSize,
      // fontSize: 'inherit',
    },
    toolbarButton: {
      marginLeft: theme.spacing(1),
    },
    deleteConfirm: {
      width: '40%',
      marginLeft: theme.spacing(2),
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    editContainer: {
      padding: theme.spacing(1, 1.5),
    },
    abandonDelete: {
      width: '40%',
    },
    noChanges: {
      marginLeft: theme.spacing(4),
    },
    buttonContainer: {
      alignSelf: 'flex-end',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    createNewResourceButton: {
      width: '30%',
    },
    deleteActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dialogContainer: {
      width: '30%',
    },
  })
);

interface EditResourceProps {
  resource: any;
  resources: any[];
  resourceName: string;
  resourceType: PrettyResourceType;
  saveResource: (resourceID: string, resourceUpdated: any) => void;
  deleteResource: (resourceID: string) => Promise<any>;
  updateListItems: () => void;
  createNewResource: (resource: any) => Promise<string>;
  abandonCreateNew: (isCreatingNew: boolean) => void;
  isValidResource: (resource: any) => boolean;
  renderEditComponent: (
    hasEditAccess: boolean,
    updatedResource: any,
    resources: any[],
    handleChangeEvent: any,
    handleChangeValue: any,
    handleChangeObject: any,
    isCreatingNew: boolean,
    apiClientAssignmentsUpdated: ApiClientAssignment[],
    updatedApiClientAssignments: (
      apiClientAssignments: ApiClientAssignment[]
    ) => void
  ) => any;
  isCreatingNew: boolean;
  currentSellerToken: string;
  classes?: any;
  hasEditAccess: boolean;
}

function EditResource(props: EditResourceProps) {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [recentActionStatus, setRecentActionStatus] = useState('');
  const [resourceUpdated, setResourceUpdated] = useState(
    cloneDeep(props.resource)
  );
  const [apiClientAssignments, setApiClientAssignments] = useState<
    ApiClientAssignment[]
  >([]);
  const [
    apiClientAssignmentsUpdated,
    setApiClientAssignmentsUpdated,
  ] = useState<ApiClientAssignment[]>([]);
  const {
    isCreatingNew,
    createNewResource,
    saveResource,
    deleteResource,
    updateListItems,
    isValidResource,
    resourceName,
    resources,
    resourceType,
    resource,
    renderEditComponent,
    currentSellerToken,
    hasEditAccess,
  } = props;

  const classes = useStyles(props);
  const handleChangeEvent = (field: string) => event => {
    setResourceUpdated({
      ...resourceUpdated,
      [field]: event.target.value === '' ? null : event.target.value,
    });
  };

  const handleChangeValue = (field: string) => newValue => {
    setResourceUpdated({ ...resourceUpdated, [field]: newValue });
  };

  const handleChangeObject = newValue => {
    setResourceUpdated(newValue);
  };

  const discardChanges = () => {
    setResourceUpdated(cloneDeep(props.resource));
    setApiClientAssignmentsUpdated(cloneDeep(apiClientAssignments));
    brieflySetRecentActionStatus('Changes Discarded');
  };

  const brieflySetRecentActionStatus = (status: string) => {
    setRecentActionStatus(status);
    setTimeout(() => {
      setRecentActionStatus('');
    }, 2300);
  };

  const handleSaveResource = async () => {
    if (isCreatingNew) {
      const newResourceID = await createNewResource(resourceUpdated);
      await updateAssignments(true, newResourceID);
      updateListItems();
    } else {
      await saveResource(props.resource.ID, resourceUpdated);
      await updateAssignments(false, resource.ID);
      updateListItems();
      brieflySetRecentActionStatus('Changes Saved');
    }
  };

  const updateAssignments = async (
    isCreatingNew: boolean,
    apiClientID: string = ''
  ) => {
    if (resourceType === API_CLIENTS) {
      await updateApiClientAssignments(isCreatingNew, apiClientID);
    }
  };

  const updateApiClientAssignments = async (
    isCreatingNew: boolean,
    apiClientID: string = ''
  ) => {
    const [
      assignmentsToDelete,
      assignmentsToCreate,
    ] = getApiClientAssignmentDiffs(isCreatingNew);
    const requests = [
      ...assignmentsToCreate.map(a =>
        ApiClients.SaveAssignment(
          { ...a, ApiClientID: apiClientID },
          { accessToken: currentSellerToken }
        )
      ),
      ...assignmentsToDelete.map(a =>
        a.BuyerID
          ? ApiClients.DeleteBuyerAssignment(apiClientID, a.BuyerID, {
              accessToken: currentSellerToken,
            })
          : ApiClients.DeleteSupplierAssignment(
              apiClientID,
              a.SupplierID as string,
              {
                accessToken: currentSellerToken,
              }
            )
      ),
    ];
    await Promise.all(requests);
  };

  const getApiClientAssignmentDiffs = (isCreatingNew: boolean) => {
    if (!isCreatingNew) {
      const assignmentsToDelete = apiClientAssignments.filter(
        apiClientAssignmentExisting => {
          return !apiClientAssignmentsUpdated.some(
            apiClientAssignmentUpdated =>
              JSON.stringify(apiClientAssignmentUpdated) ===
              JSON.stringify(apiClientAssignmentExisting)
          );
        }
      );
      const assignmentsToCreate = apiClientAssignmentsUpdated.filter(
        apiClientAssignmentUpdated => {
          return !apiClientAssignments.some(
            apiClientAssignmentExisting =>
              JSON.stringify(apiClientAssignmentUpdated) ===
              JSON.stringify(apiClientAssignmentExisting)
          );
        }
      );
      return [assignmentsToDelete, assignmentsToCreate];
    } else {
      return [[], apiClientAssignmentsUpdated];
    }
  };

  useEffect(() => {
    const getAdditionalInformation = async (resource: any) => {
      // used for getting additional arbitrary information
      // i.e. apiClientAssignments for apiClient editing
      if (resourceType === API_CLIENTS) {
        const apiClientAssigmentResponse = await ApiClients.ListAssignments(
          {
            apiClientID: resource.ID,
            pageSize: 100,
          },
          { accessToken: currentSellerToken }
        );
        setApiClientAssignments(apiClientAssigmentResponse.Items);
        setApiClientAssignmentsUpdated(apiClientAssigmentResponse.Items);
      }
    };
    setResourceUpdated(cloneDeep(props.resource));
    getAdditionalInformation(props.resource);
  }, [currentSellerToken, props.resource, resourceType]);

  useEffect(() => {
    return () => props.abandonCreateNew(props.isCreatingNew);
  }, [props, props.isCreatingNew]);

  const areChanges =
    JSON.stringify(resourceUpdated) !== JSON.stringify(props.resource) ||
    JSON.stringify(apiClientAssignments) !==
      JSON.stringify(apiClientAssignmentsUpdated);

  return (
    <div className={classes.root}>
      <AppBar
        position="sticky"
        color="default"
        elevation={0}
        className={classes.topBar}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h5" component="h3">
            {props.resource.Name || props.resource.AppName}
          </Typography>
          <div className={classes.grow}></div>
          {Boolean(
            !props.isCreatingNew || (props.isCreatingNew && props.resource.ID)
          ) &&
            (hasEditAccess ? (
              <RedButton
                variant="contained"
                color="secondary"
                className={`${classes.toolbarButton} ${classes.buttonWithIcon}`}
                onClick={() => setIsConfirmingDelete(true)}
              >
                <DeleteIcon className={classes.buttonIcon} />
                Delete
              </RedButton>
            ) : (
              <Typography variant="caption">
                You do not have the appropriate data access to edit this
                resource.
              </Typography>
            ))}
        </Toolbar>
      </AppBar>
      <Paper elevation={0} square>
        <List disablePadding>
          {renderEditComponent(
            hasEditAccess,
            resourceUpdated,
            resources,
            handleChangeEvent,
            handleChangeValue,
            handleChangeObject,
            isCreatingNew,
            apiClientAssignmentsUpdated,
            setApiClientAssignmentsUpdated
          )}
        </List>
      </Paper>
      {hasEditAccess && (
        <AppBar
          position="sticky"
          color="default"
          elevation={0}
          className={classes.bottomBar}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.grow}></div>
            {!props.isCreatingNew ||
            (props.isCreatingNew && props.resource.ID) ? (
              <React.Fragment>
                {areChanges ? (
                  <React.Fragment>
                    <Button
                      className={classes.toolbarButton}
                      color="default"
                      variant="contained"
                      onClick={() => discardChanges()}
                    >
                      Discard
                    </Button>
                    <Button
                      className={classes.toolbarButton}
                      color="primary"
                      variant="contained"
                      disabled={!isValidResource(resourceUpdated)}
                      onClick={() => handleSaveResource()}
                    >
                      Save Changes
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    className={classes.toolbarButton}
                  >
                    {recentActionStatus || 'No Changes'}
                  </Button>
                )}
              </React.Fragment>
            ) : (
              <Button
                className={classes.buttonWithIcon}
                color="secondary"
                variant="contained"
                disabled={!isValidResource(resourceUpdated)}
                onClick={() => handleSaveResource()}
              >
                <CreateNewIcon className={classes.buttonIcon} />
                Create New {pluralize.singular(resourceName)}
              </Button>
            )}
          </Toolbar>
        </AppBar>
      )}
      <Dialog
        open={isConfirmingDelete}
        onClose={() => setIsConfirmingDelete(false)}
        aria-labelledby="alert-dialog-title"
        maxWidth="md"
        aria-describedby="alert-dialog-description"
        classes={{ paperScrollPaper: classes.dialogContainer }}
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Deletion of {pluralize.singular(resourceName)}{' '}
          {resource.Name || resource.AppName}
        </DialogTitle>
        <DialogActions classes={{ root: classes.deleteActionContainer }}>
          <Button
            onClick={() => setIsConfirmingDelete(false)}
            variant="contained"
            autoFocus
          >
            Do Not Delete
          </Button>
          <RedButton
            className={classes.buttonWithIcon}
            onClick={() => deleteResource(resource.ID)}
            variant="contained"
          >
            <DeleteIcon className={classes.buttonIcon} />
            Delete {resource.className}
          </RedButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state: AppReducerState) {
  return {
    currentSellerToken: AuthService.getCurrentSellerOrgTokenFromState(state),
  };
}

export default connect(mapStateToProps)(EditResource);
