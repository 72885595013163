import {
  SetSellerOrgs,
  SetAPIClients,
  SetUsers,
  ContextResource,
} from '../contextOptions/contextOptions.types';
import {
  SET_SELLER_ORGS,
  SET_API_CLIENTS,
  SET_USERS,
} from '../contextOptions/contextOptions.constants';
import {
  SetSellerOrgImpersonationToken,
  ClearSellerOrgImpersonationTokens,
  SetContextChoice,
  SetSearchText,
  SetContextSelectorOpen,
  CancelContextSelection,
  CopyTabContext,
  DestroyContextOption,
  DestroyContextSearch,
} from './contextSelections.types';
import {
  SET_SELLER_ORG_IMPERSONATION_TOKEN,
  CONTEXT_TYPE,
  SET_USER_CHOICE,
  SET_SEARCH_TEXT,
  SET_CONTEXT_SELECTOR_OPEN,
  CANCEL_CONTEXT_SELECTION,
  COPY_TAB_CONTEXT,
  CLEAR_SELLER_ORG_IMPERSONATION_TOKENS,
  DESTROY_CONTEXT_OPTION,
  DESTROY_CONTEXT_SEARCH,
} from './contextSelections.constants';
import {
  ListPage,
  Organization,
  RequiredDeep,
  ApiClient,
  OrderCloudUser,
} from '@ordercloud/portal-javascript-sdk';

// OPTIONS
export function setSellerOrgs(
  sellerOrgsList: RequiredDeep<ListPage<Organization>>,
  tabID: string,
  isSearchResult: boolean
): SetSellerOrgs {
  return {
    type: SET_SELLER_ORGS,
    payload: {
      sellerOrgsList,
      tabID,
      isSearchResult,
    },
  };
}
export function setAPIClients(
  sellerOrgID: string,
  apiClientsList: RequiredDeep<ListPage<ApiClient>>,
  tabID: string,
  isSearchResult: boolean
): SetAPIClients {
  return {
    type: SET_API_CLIENTS,
    payload: {
      sellerOrgID,
      apiClientsList,
      tabID,
      isSearchResult,
    },
  };
}
export function setUsers(
  userList: RequiredDeep<ListPage<OrderCloudUser>>,
  tabID: string
): SetUsers {
  return {
    type: SET_USERS,
    payload: {
      userList,
      tabID,
    },
  };
}

export function setSellerOrgImpersonationToken(
  sellerOrgID: string,
  token: string
): SetSellerOrgImpersonationToken {
  return {
    type: SET_SELLER_ORG_IMPERSONATION_TOKEN,
    payload: {
      // unique user ID is a combination of the clientID and the username
      sellerOrgID,
      token,
    },
  };
}

export function clearSellerOrgImpersonationTokens(): ClearSellerOrgImpersonationTokens {
  return {
    type: CLEAR_SELLER_ORG_IMPERSONATION_TOKENS,
  };
}

export function setContextChoice(
  contextResource: ContextResource,
  contextType: CONTEXT_TYPE,
  tabID: string,
  isViewingPrettyUI?: boolean
): SetContextChoice {
  return {
    type: SET_USER_CHOICE,
    payload: {
      contextResource,
      contextType,
      tabID,
      isViewingPrettyUI,
    },
  };
}

export function setSearchText(
  ContextType: CONTEXT_TYPE,
  TabID: string,
  SearchText: string
): SetSearchText {
  return {
    type: SET_SEARCH_TEXT,
    payload: {
      ContextType,
      TabID,
      SearchText,
    },
  };
}

export function setContextSelectorOpen(tabID: string): SetContextSelectorOpen {
  return {
    type: SET_CONTEXT_SELECTOR_OPEN,
    payload: {
      tabID,
    },
  };
}

export function destroyContextOption(tabID: string): DestroyContextOption {
  return {
    type: DESTROY_CONTEXT_OPTION,
    payload: {
      tabID,
    },
  };
}

export function destroyContextSearch(tabID: string): DestroyContextSearch {
  return {
    type: DESTROY_CONTEXT_SEARCH,
    payload: {
      tabID,
    },
  };
}

export function cancelContextSelection(tabID: string): CancelContextSelection {
  return {
    type: CANCEL_CONTEXT_SELECTION,
    payload: {
      tabID,
    },
  };
}

export function copyTabContext(
  newTabID: string,
  copiedTabID: string
): CopyTabContext {
  return {
    type: COPY_TAB_CONTEXT,
    payload: {
      newTabID,
      copiedTabID,
    },
  };
}
