import { ContextChoice, SearchText } from './contextSelections.types';
import {
  OrderCloudUser,
  RequiredDeep,
  ApiClient,
  Organization,
  OrgEnvironment,
} from '@ordercloud/portal-javascript-sdk';

export const SET_SELLER_ORG_CHOICE = 'SET_SELLER_ORG_CHOICE';
export type SET_SELLER_ORG_CHOICE = typeof SET_SELLER_ORG_CHOICE;

export const SET_API_CLIENT_CHOICE = 'SET_API_CLIENT_CHOICE';
export type SET_API_CLIENT_CHOICE = typeof SET_API_CLIENT_CHOICE;

export const SET_USER_CHOICE = 'SET_USER_CHOICE';
export type SET_USER_CHOICE = typeof SET_USER_CHOICE;

export const COPY_TAB_CONTEXT = 'COPY_TAB_CONTEXT';
export type COPY_TAB_CONTEXT = typeof COPY_TAB_CONTEXT;

export const SET_CONTEXT_SELECTOR_OPEN = 'SET_CONTEXT_SELECTOR_OPEN';
export type SET_CONTEXT_SELECTOR_OPEN = typeof SET_CONTEXT_SELECTOR_OPEN;

export const DESTROY_CONTEXT_OPTION = 'DESTROY_CONTEXT_OPTION';
export type DESTROY_CONTEXT_OPTION = typeof DESTROY_CONTEXT_OPTION;

export const CANCEL_CONTEXT_SELECTION = 'CANCEL_CONTEXT_SELECTION';
export type CANCEL_CONTEXT_SELECTION = typeof CANCEL_CONTEXT_SELECTION;

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export type SET_SEARCH_TEXT = typeof SET_SEARCH_TEXT;

export const DESTROY_CONTEXT_SEARCH = 'DESTROY_CONTEXT_SEARCH';
export type DESTROY_CONTEXT_SEARCH = typeof DESTROY_CONTEXT_SEARCH;

export const SET_SELLER_ORG_IMPERSONATION_TOKEN =
  'SET_SELLER_ORG_IMPERSONATION_TOKEN';
export type SET_SELLER_ORG_IMPERSONATION_TOKEN = typeof SET_SELLER_ORG_IMPERSONATION_TOKEN;

export const CLEAR_SELLER_ORG_IMPERSONATION_TOKENS =
  'CLEAR_SELLER_ORG_IMPERSONATION_TOKENS';
export type CLEAR_SELLER_ORG_IMPERSONATION_TOKENS = typeof CLEAR_SELLER_ORG_IMPERSONATION_TOKENS;

export const SET_USER_IMPERSONATION_TOKEN = 'SET_USER_IMPERSONATION_TOKEN';
export type SET_USER_IMPERSONATION_TOKEN = typeof SET_USER_IMPERSONATION_TOKEN;

export const SELLER_ORG = 'SELLER_ORG';
export type SELLER_ORG = typeof SELLER_ORG;

export const API_CLIENT = 'API_CLIENT';
export type API_CLIENT = typeof API_CLIENT;

export const USER = 'USER';
export type USER = typeof USER;

export type CONTEXT_TYPE = SELLER_ORG | API_CLIENT | USER;

export const emptySellerOrg: RequiredDeep<Organization> = {
  Id: '',
  Name: '',
  Environment: 'Sandbox' as OrgEnvironment,
  CoreApiUrl: '',
  SCOrgId: '',
  SCSpaceId: '',
  Region: {
    Id: '',
    Name: '',
    AzureRegion: '',
  },
  Owner: {
    Username: '',
    Name: '',
    Email: '',
    TermsAccepted: '',
    Country: '',
    Region: '',
    SCAdmin: false,
    Verified: true,
  },
};

export const emptyApiClient: RequiredDeep<ApiClient> = {
  Id: '',
  ClientSecret: '',
  Active: true,
  AppName: '',
  AllowAnyBuyer: true,
  AllowAnySupplier: true,
  AllowSeller: true,
  IsAnonBuyer: true,
  AssignedBuyerCount: 0,
  AssignedSupplierCount: 0,
  AssignedWebhookCount: 0,
};

export const emptyUser: RequiredDeep<OrderCloudUser> = {
  Username: '',
  UserId: '',
  FirstName: '',
  LastName: '',
  Email: '',
  CommerceRole: '',
  CompanyId: '',
  CompanyName: '',
  UserActive: true,
};

export const emptyContext: ContextChoice = {
  SELLER_ORG: emptySellerOrg,
  API_CLIENT: emptyApiClient,
  USER: emptyUser,
};

export const emptySearchTexts: SearchText = {
  SELLER_ORG: '',
  API_CLIENT: '',
  USER: '',
};

export const searchInputPlaceholders: SearchText = {
  SELLER_ORG: 'Filter marketplaces...',
  API_CLIENT: 'Filter API clients...',
  USER: 'Search users...',
};

export const contextTypeLabels: SearchText = {
  SELLER_ORG: 'Marketplace',
  API_CLIENT: 'API Client',
  USER: 'User Context',
};
