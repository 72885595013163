import { Reducer } from 'redux';
import {
  DevCenterUserAction,
  SET_DEVCENTER_USER,
  REMOVE_DEVCENTER_USER,
} from './auth.types';
import { User } from '@ordercloud/portal-javascript-sdk';

const devCenterUser: Reducer<User | any, DevCenterUserAction> = (
  state: User | any = {},
  action: DevCenterUserAction
) => {
  switch (action.type) {
    case SET_DEVCENTER_USER:
      return action.payload.devCenterUser;
    case REMOVE_DEVCENTER_USER:
      return {};
    default:
      return state;
  }
};

export default devCenterUser;
