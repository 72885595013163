import { Reducer } from 'react';
import {
  ConsoleResponse,
  ConsoleRequestsAction,
  ADD_REQUEST,
} from './consoleRequest.types';

const requests: Reducer<
  ConsoleResponse[] | undefined,
  ConsoleRequestsAction
> = (state: ConsoleResponse[] = [], action: ConsoleRequestsAction) => {
  switch (action.type) {
    case ADD_REQUEST:
      return [...state, action.payload];
    default:
      return state;
  }
};

export default requests;
