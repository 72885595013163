import { Box, Button, TextField, Typography } from '@material-ui/core';
import { PortalUsers, User } from '@ordercloud/portal-javascript-sdk';
import Axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppReducerState } from '../../redux';
import { updateDevcenterUser } from '../../redux/auth/authThunk.actions';
import { isValidInput } from '../../services/utilites.helper';
import ConfirmEmailDialog from '../Shared/ConfirmEmailDialog';
import useDebounce from '../Shared/hooks/useDebounce';

interface OwnProps {
  loginAttempts: number;
  setLoginAttempts: any;
  onLogout: () => void;
}

interface StateProps {
  user: User;
}

interface DispatchProps {
  updateUser: (devcenterUser: User) => void;
}

type ProfileSettingsProps = OwnProps & StateProps & DispatchProps;

const ProfileSettings = (props: ProfileSettingsProps) => {
  const { user, updateUser, onLogout, loginAttempts, setLoginAttempts } = props;
  const [fields, setFields] = useState(user);
  const debouncedUsername = useDebounce(fields.Username, 300);
  const [availability, setAvailability] = useState(true);
  const [checking, setChecking] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setFields(user);
  }, [user]);

  useEffect(() => {
    document.title = 'Sitecore OrderCloud | Profile Settings';
  }, []);

  const usernameIsInvalid = useMemo(() => {
    return !availability || !isValidInput(fields.Username);
  }, [availability, fields.Username]);

  const nameIsInvalid = useMemo(() => {
    return !isValidInput(fields.Name, true);
  }, [fields.Name]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    if (
      debouncedUsername &&
      debouncedUsername !== user.Username &&
      isValidInput(debouncedUsername)
    ) {
      setChecking(true);
      PortalUsers.Get(debouncedUsername, {
        cancelToken: source.token,
        requestType: 'quiet',
      })
        .then(response => {
          setAvailability(false);
        })
        .catch(ex => {
          setAvailability(true);
          return Promise.resolve();
        })
        .finally(() => {
          setChecking(false);
          setChecked(true);
        });
    } else {
      setChecking(false);
      setChecked(false);
      setAvailability(true);
    }
    return () => {
      setChecking(false);
      setChecked(false);
      source.cancel();
    };
  }, [debouncedUsername, user.Username, usernameIsInvalid]);

  const handleInputChange = (field: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setFields(f => ({ ...f, [field]: value }));
  };

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      updateUser(fields);
    },
    [fields, updateUser]
  );

  const handleReset = useCallback(
    (e: React.MouseEvent) => {
      setFields(user);
    },
    [user]
  );

  const changes = useMemo(() => {
    const hasChanges = JSON.stringify(fields) !== JSON.stringify(user);
    const isValid = fields.Email && fields.Name && fields.Username;
    return {
      exist: hasChanges,
      isValid,
    };
  }, [fields, user]);

  return (
    <Box padding={3}>
      <form name="ProfileForm" onSubmit={handleSubmit}>
        <Typography variant="h4">Profile Settings</Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Username"
          value={fields.Username || ''}
          error={usernameIsInvalid}
          helperText={
            checking
              ? 'Checking Username availability...'
              : usernameIsInvalid
              ? availability
                ? `Username can only contain characters Aa-Zz 0-9 - _`
                : `This username is already taken.`
              : checked
              ? `This username is available.`
              : `This is what you will use to login via password authentication, it is publicly available.`
          }
          variant="outlined"
          onChange={handleInputChange('Username')}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Full Name"
          value={fields.Name || ''}
          variant="outlined"
          error={nameIsInvalid}
          helperText={
            nameIsInvalid
              ? 'Name contains invalid special characters'
              : 'First and last name'
          }
          onChange={handleInputChange('Name')}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={
            checking ||
            usernameIsInvalid ||
            nameIsInvalid ||
            !changes.exist ||
            (changes.exist && !changes.isValid)
          }
        >
          Save Changes
        </Button>
        <Button
          style={{ marginLeft: 8 }}
          type="button"
          variant="text"
          onClick={handleReset}
          disabled={!changes.exist}
        >
          Cancel
        </Button>
      </form>
      <Box style={{ marginTop: 20 }}>
        <Typography variant="h4">Email Settings</Typography>
        <Box>
          <TextField
            fullWidth
            margin="normal"
            value={user.Email}
            variant="outlined"
            disabled
          />
          <Button variant="outlined" onClick={() => setOpen(true)}>
            Change Email
          </Button>
        </Box>
        <ConfirmEmailDialog
          loginAttempts={loginAttempts}
          setLoginAttempts={setLoginAttempts}
          onLogout={onLogout}
          profileUpdate={true}
          open={open}
          onClose={() => setOpen(false)}
        />
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (devcenterUser: User) => {
      return dispatch(updateDevcenterUser(devcenterUser));
    },
  };
}

function mapStateToProps(state: AppReducerState) {
  return { user: state.devCenterUser };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
