import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  createStyles,
  Divider,
  Drawer,
  Grow,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Paper,
  Popper,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Launch as LaunchIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';
import React from 'react';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import SwaggerParser from 'swagger-parser';
import appConstants from '../../config/app.constants';
import { clearSellerOrgImpersonationTokens } from '../../redux/contextSelections/contextObjectConstructor.actions';
import { clearTabTokens } from '../../redux/tab/tab.actions';
import AuthService from '../../services/auth.service';
import { withPrefix } from '../../services/link.helper';
import { sherpablue } from '../../themes/ocPalette.constants';
import ORDERCLOUD_THEME from '../../themes/theme.constants';
import { menuItems as MenuItems } from '../Shared/MenuItems';

export const navHeight = ORDERCLOUD_THEME.spacing(6) + 2;
export const navHeightMobile = ORDERCLOUD_THEME.spacing(5) + 1;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    brand: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginRight: 'auto',
      },
    },
    logo: {
      width: 160,
      display: 'flex',
      placeItems: 'center',
      '& > img': {
        width: '100%',
      },
    },
    root: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: '100vw',
      left: 0,
      top: 0,
      zIndex: theme.zIndex.appBar + 200,
    },
    toolbar: {
      padding: theme.spacing(0, 1.5),
      height: navHeightMobile,
      [theme.breakpoints.up('md')]: {
        height: navHeight,
      },
    },
    tabsContainer: {
      height: '100%',
    },
    tabs: {
      alignSelf: 'stretch',
      flexGrow: 1,
    },
    tab: {
      textTransform: 'none',
      minWidth: 0,
      textDecoration: 'none',
      opacity: 1,
      '&.Mui-selected': {
        color: theme.palette.primary.main,
      },
    },
    tabsIndicator: {
      backgroundColor: theme.palette.primary.main,
      zIndex: -2,
    },
    navbarRight: {
      marginRight: theme.spacing(1),
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },
    iconButton: {
      padding: 0,
    },
    iconButtonAvatar: {
      width: 30,
      height: 30,
      backgroundColor: 'transparent',
      fontSize: '.8rem',
    },
    icon: {
      color: theme.palette.common.white,
    },
    logoContainer: {
      boxSizing: 'content-box',
    },
    menuItem__profile: {
      padding: '10px',
    },
    search: {
      alignItems: 'flex-start',
    },
    'ais-Hits': {
      maxHeight: theme.spacing(25),
      overflowY: 'scroll',
      overflowX: 'auto',
    },
    dropdownHeader: {
      padding: theme.spacing(1, 2),
    },
    // start mobile drawer
    drawerRoot: {
      zIndex: `${theme.zIndex.modal + 5} !important` as any,
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary.main,
      width: '100vw',
      height: '100%',
      color: theme.palette.common.white,
      fontSize: '1.3rem',
    },
    mobileMenuSubheader: {
      color: sherpablue[100],
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    mobileMenuList: {
      marginBottom: 'auto',
    },
    mobileMenuLogo: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: theme.spacing(30),
    },
    mobileMenuListItem: {
      color: theme.palette.common.white,
    },
    mobileSearchBox: {
      marginRight: -theme.spacing(1),
    },
    mobileSearchInput: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: 80,
    },
    mediumSearchInput: {
      width: 100,
    },
    mobileMenuSettings: {
      marginTop: 'auto',
    },
    mobileIconButon: {
      padding: 0,
    },
  })
);

interface HeaderState {
  anchorEl: null | HTMLElement;
  mobileOpen: boolean;
  showResults: boolean;
  currentApiVersion: string;
}

const INITIAL_STATE: HeaderState = {
  anchorEl: null,
  mobileOpen: false,
  showResults: false,
  currentApiVersion: '',
};

const Header = props => {
  const { portalUser, dispatchClearTokens, onLogout } = props;
  const history = useHistory();
  const [state, setState] = React.useState(INITIAL_STATE);
  const classes = useStyles();

  const onInit = async () => {
    const spec = await SwaggerParser.dereference(
      `${appConstants.ocApiUrl}/v1/openapi/v3`
    );
    const version = spec.info.version.split('.').slice(0, 3);
    setState(s => ({
      ...s,
      currentApiVersion: version.join('.'),
    }));
  };

  React.useEffect(() => {
    if (!state.currentApiVersion) {
      //use timeouts to avoid memory leak warning
      const timer = setTimeout(() => {
        onInit();
      }, 100);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [state.currentApiVersion]);

  const handleTabClick = React.useCallback(
    (isPortalLink: boolean) => () => {
      if (isPortalLink) setState(s => ({ ...s, mobileOpen: false }));
    },
    []
  );

  const toggleNav = React.useCallback(() => {
    setState(s => ({ ...s, mobileOpen: !s.mobileOpen }));
  }, []);

  const handleMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setState(s => ({ ...s, anchorEl: event.currentTarget }));
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setState(s => ({ ...s, anchorEl: null }));
  }, []);

  const navigatePortalLink = React.useCallback(
    (path: string) => (event: React.MouseEvent) => {
      setState(s => ({ ...s, mobileOpen: false, anchorEl: null }));
      history.push(path);
    },
    [history]
  );

  const handleLogout = React.useCallback((): void => {
    onLogout();
    dispatchClearTokens();
  }, [onLogout, dispatchClearTokens]);

  const activeTab = React.useMemo(() => {
    return portalUser && portalUser.Email ? 'portal' : 'learn';
  }, [portalUser]);

  return (
    <React.Fragment>
      <AppBar color="default" className={classes.root} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Hidden mdUp>
            <IconButton color="inherit" edge="start" onClick={toggleNav}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.brand} onClick={AuthService.backToDocs('/')}>
            <div className={classes.logo}>
              <img
                src={
                  'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/logo-ordercloud'
                }
                alt="Sitecore OrderCloud"
              />
            </div>
          </div>
          <Hidden smDown>
            <Tabs
              value={activeTab}
              textColor="inherit"
              className={classes.tabs}
              classes={{
                flexContainer: classes.tabsContainer,
                indicator: classes.tabsIndicator,
              }}
            >
              {MenuItems.MainNavigation.filter(
                ({ mobileMenu }) => !mobileMenu
              ).map((item, index) => {
                const { authRequired, value, label, to, isPortalLink } = item;
                return (
                  (!authRequired || (portalUser && portalUser.Email)) && (
                    <Tab
                      value={value}
                      label={label}
                      classes={{
                        root: classes.tab,
                      }}
                      component="a"
                      target={isPortalLink ? undefined : '_blank'}
                      href={isPortalLink ? to : withPrefix(to)}
                      onClick={handleTabClick(isPortalLink)}
                      style={{ textDecoration: 'none' }}
                      key={index}
                    ></Tab>
                  )
                );
              })}
            </Tabs>
          </Hidden>
          <Hidden smDown>
            <Box
              display="flex"
              alignItems="center"
              className={classes.navbarRight}
            >
              {state.currentApiVersion && (
                <Chip
                  label={`v${state.currentApiVersion}`}
                  // landing page of release notes will redirect to most recent one
                  onClick={AuthService.backToDocs(`/release-notes`)}
                ></Chip>
              )}
              {portalUser && portalUser.Email ? (
                <React.Fragment>
                  <IconButton
                    color="inherit"
                    onClick={handleMenu}
                    className={classes.iconButton}
                  >
                    <Avatar
                      alt={portalUser.Username}
                      className={classes.iconButtonAvatar}
                    >
                      <Gravatar
                        alt="settings"
                        size={30}
                        email={portalUser && portalUser.Email}
                      />
                    </Avatar>
                  </IconButton>
                  <Popper
                    placement="bottom-end"
                    open={Boolean(state.anchorEl)}
                    anchorEl={state.anchorEl}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-end'
                              ? 'right top'
                              : 'right bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <div>
                              <List>
                                <ListSubheader
                                  className={classes.dropdownHeader}
                                  component="div"
                                >
                                  <Typography
                                    variant="h5"
                                    style={{ margin: 0 }}
                                  >
                                    {`Welcome, ${portalUser.Name}`}
                                    <Typography variant="body1">
                                      Signed in as
                                      <strong>{` ${portalUser.Username}`}</strong>
                                    </Typography>
                                  </Typography>
                                </ListSubheader>
                                {MenuItems.DropdownControls.map(
                                  (item, index) => (
                                    <ListItem
                                      button
                                      key={index}
                                      onClick={navigatePortalLink(item.to)}
                                    >
                                      {item.label}
                                    </ListItem>
                                  )
                                )}
                              </List>
                              <Divider />
                              <List>
                                <ListItem button onClick={handleLogout}>
                                  Sign Out
                                </ListItem>
                              </List>
                            </div>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    onClick={navigatePortalLink('/login')}
                    variant="text"
                    color="inherit"
                    size="small"
                    style={{ marginRight: 5 }}
                  >
                    Login
                  </Button>
                  <Button
                    onClick={navigatePortalLink('/login')}
                    variant="outlined"
                    color="inherit"
                    size="small"
                  >
                    Sign-Up
                  </Button>
                </React.Fragment>
              )}
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer
        open={state.mobileOpen}
        onClose={toggleNav}
        classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
        anchor="left"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingX="8px"
          paddingTop="8px"
        >
          <IconButton
            className={classes.mobileIconButon}
            aria-label="close"
            color="inherit"
            onClick={toggleNav}
          >
            <CloseIcon fontSize="large" color="inherit" />
          </IconButton>
          {!Boolean(portalUser && portalUser.Email) && (
            <Box padding="1rem 0rem">
              <Button
                onClick={navigatePortalLink('/login')}
                variant="text"
                color="inherit"
              >
                Login
              </Button>
              <Button
                onClick={navigatePortalLink('/login')}
                variant="outlined"
                color="inherit"
              >
                Sign-Up
              </Button>
            </Box>
          )}
        </Box>
        <List className={classes.mobileMenuList}>
          {MenuItems.MainNavigation.map((item, index) => {
            const { authRequired, to, label, isPortalLink } = item;
            if (
              (authRequired && portalUser && portalUser.Email) ||
              (!authRequired && isPortalLink)
            ) {
              return (
                <ListItem key={to} onClick={navigatePortalLink(to)}>
                  {label}
                </ListItem>
              );
            }
            if (!isPortalLink) {
              return (
                <ListItem
                  key={to}
                  onClick={AuthService.backToDocs(
                    to === '/release-notes/'
                      ? `${to}v${state.currentApiVersion}`
                      : to
                  )}
                >
                  {label}
                </ListItem>
              );
            }
            return null;
          })}
          <ListItem>
            <Link
              style={{ color: 'white' }}
              align="center"
              href="https://support.sitecore.com/status"
              rel="noopener noreferrer"
              target="_blank"
            >
              Status Page
              <LaunchIcon fontSize="small" style={{ marginLeft: 4 }} />
            </Link>
          </ListItem>
        </List>
        <Divider />
        {portalUser && portalUser.Email && (
          <List>
            <ListSubheader
              component="div"
              className={classes.mobileMenuSubheader}
            >
              <Avatar
                alt={portalUser.Email}
                className={classes.iconButtonAvatar}
              >
                <Gravatar alt="User Image" size={40} email={portalUser.Email} />
              </Avatar>
              <span style={{ marginLeft: 10 }}>
                Signed in as
                <strong>{` ${portalUser.Username}`}</strong>
              </span>
            </ListSubheader>
            {MenuItems.DropdownControls.map((item, index) => (
              <ListItem key={index} onClick={navigatePortalLink(item.to)}>
                {item.label}
              </ListItem>
            ))}
            <ListItem onClick={handleLogout}>Sign Out</ListItem>
          </List>
        )}
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  portalUser: state.devCenterUser,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatchClearTokens: () => {
      dispatch(clearSellerOrgImpersonationTokens());
      dispatch(clearTabTokens());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
