import {
  Checkbox,
  createStyles,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { AppReducerState } from '../../../../../redux';
import { getCurrentApiClients } from '../../../../../redux/contextSelections/contextSelections.helpers';
import { maroon } from '../../../../../themes/ocPalette.constants';
import ApiClientAccess from '../../../ContextSelector/ApiClientAccess';
import FieldSection from '../FieldSection';
import { ApiClient, RequiredDeep } from '@ordercloud/portal-javascript-sdk';

interface StateProps {
  allApiClientsForOrg: RequiredDeep<ApiClient>[];
}

interface OwnProps {
  readOnly?: boolean;
  apiClientAssignments: string[];
  handleChange: any;
}

type ApiClientAssignmentsProps = StateProps & OwnProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    secondaryText: {
      fontFamily: 'monospace',
      color: maroon[500],
    },
    apiClientContainer: {
      marginLeft: theme.spacing(3),
    },
  })
);

function ApiClientAssignments(props: ApiClientAssignmentsProps) {
  const classes = useStyles();

  const toggleAssignment = (apiClientID: string): void => {
    if (props.readOnly) return;
    if (!props.apiClientAssignments.includes(apiClientID.toLowerCase())) {
      props.handleChange([
        ...props.apiClientAssignments,
        apiClientID.toLowerCase(),
      ]);
    } else {
      props.handleChange(
        props.apiClientAssignments.filter(a => a !== apiClientID.toLowerCase())
      );
    }
  };

  return (
    <FieldSection
      headerText="API Client Assignments"
      infoText="Webhooks will only fire when API routes are hit from the context of an
      API client assigned to the webhhook. Make these assignments below."
    >
      <Scrollbars
        renderTrackHorizontal={props => (
          <div
            {...props}
            style={{ display: 'none' }}
            className="track-horizontal"
          />
        )}
        style={{ width: '100%', height: '275px' }}
      >
        <List dense className={classes.root}>
          {props.allApiClientsForOrg.map((apiClient, index) => {
            return (
              <ListItem
                key={index}
                onClick={() => toggleAssignment(apiClient.Id)}
              >
                <span style={{ width: '20%' }}>
                  <ApiClientAccess {...apiClient} />
                </span>
                <ListItemText
                  id={apiClient.AppName}
                  primary={apiClient.AppName}
                  className={classes.apiClientContainer}
                  secondary={
                    <Typography
                      noWrap
                      variant="body2"
                      className={classes.secondaryText}
                    >
                      {apiClient.Id}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    disabled={props.readOnly}
                    onClick={() => toggleAssignment(apiClient.Id)}
                    checked={props.apiClientAssignments.includes(
                      apiClient.Id.toLowerCase()
                    )}
                    inputProps={{ 'aria-labelledby': apiClient.AppName }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Scrollbars>
    </FieldSection>
  );
}

const mapStateToProps = (state: AppReducerState): StateProps => {
  const allApiClientsForOrg = getCurrentApiClients(state);
  return { allApiClientsForOrg };
};

export default connect<StateProps, {}, OwnProps, AppReducerState>(
  mapStateToProps
)(ApiClientAssignments);
