import React from 'react';
import PrettyResourceNavigation from '../PrettyResourceNavigation';
import { WebhookTableHeaderRow, WebhookTableRow } from './WebhookTableRows';
import EditWebhook from './EditWebhook';
import { Webhook, Webhooks as ocWebhooks } from 'ordercloud-javascript-sdk';
import { WEBHOOKS } from '../PrettyResourceNavigation/constants';

const newWebhook: Webhook = {
  ID: '',
  Name: 'New Webhook',
  Description: '',
  Url: '',
  HashKey: '',
  ElevatedRoles: [],
  ConfigData: [],
  BeforeProcessRequest: false,
  ApiClientIDs: [],
  WebhookRoutes: [],
};

const isValidWebhook = (webhook: Webhook) => {
  return Boolean(
    webhook.Name &&
      webhook.Url &&
      webhook.HashKey &&
      webhook.ConfigData !== 'ConfigDataInvalid'
  );
};

function Webhooks() {
  return (
    <PrettyResourceNavigation
      editRole="WebhookAdmin"
      headerComponent={WebhookTableHeaderRow}
      tableRowComponent={WebhookTableRow}
      isValidResource={isValidWebhook}
      renderEditComponent={(
        hasEditAccess,
        updatedResource,
        resources,
        handleChangeEvent,
        handleChangeValue,
        handleChangeObject
      ) => (
        <EditWebhook
          hasEditAccess={hasEditAccess}
          webhooks={resources}
          webhookUpdated={updatedResource}
          handleChangeEvent={handleChangeEvent}
          handleChangeValue={handleChangeValue}
          handleChangeObject={handleChangeObject}
        />
      )}
      resourceName="Webhooks"
      emptyResource={newWebhook}
      ocService={ocWebhooks}
      numberOfTableColumns={7}
      resourceType={WEBHOOKS}
    />
  );
}

export default Webhooks;
