import { SET_DEVCENTER_USER, REMOVE_DEVCENTER_USER } from './auth.types';
import { User } from '@ordercloud/portal-javascript-sdk';

export function setDevCenterUser(devCenterUser: User) {
  return {
    type: SET_DEVCENTER_USER,
    payload: { devCenterUser },
  };
}

export function removeDevCenterUser() {
  return {
    type: REMOVE_DEVCENTER_USER,
  };
}
