import {
  createStyles,
  Divider,
  ListItem,
  ListItemText,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';
import { Fragment } from 'react';

interface FieldSectionProps {
  headerText: string;
  infoText?: string | ReactNode;
  children: any;
  vertical?: boolean;
  plain?: boolean;
}

interface FieldSectionStyleProps {
  vertical?: boolean;
  plain?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles<any, FieldSectionStyleProps>({
    subHeaderText: {
      marginBottom: theme.spacing(2),
    },
    listItem: (props: any) => ({
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      padding: props.plain ? 0 : theme.spacing(3, 3, 4),
      '&:last-of-type + .MuiDivider-root': {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        flexFlow: props.vertical ? undefined : 'row nowrap',
      },
    }),
    text: props => ({
      [theme.breakpoints.up('md')]: {
        flex: props.vertical ? undefined : '0 1 25%',
      },
    }),
    textPrimary: (props => ({
      ...(props.plain ? theme.typography.body1 : theme.typography.h5),
    })) as any,
    textSecondary: (props => ({
      ...(props.plain ? theme.typography.subtitle2 : theme.typography.body1),
    })) as any,
    content: (props => ({
      width: '100%',
      [theme.breakpoints.up('md')]: props.vertical
        ? undefined
        : {
            flex: '0 1 75%',
            width: 'auto',
            paddingLeft: theme.spacing(4),
          },
    })) as any,
  })
);

function FieldSection(props: FieldSectionProps) {
  const { vertical, plain } = props;
  const classes = useStyles({ vertical, plain });
  return (
    <Fragment>
      <ListItem className={classes.listItem}>
        <ListItemText
          classes={{
            primary: classes.textPrimary,
            secondary: classes.textSecondary,
          }}
          primary={props.headerText}
          secondary={props.infoText}
          className={classes.text}
        />
        <div className={classes.content}>{props.children}</div>
      </ListItem>
      <Divider />
    </Fragment>
  );
}

export default FieldSection;
