import {
  createStyles,
  lighten,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const redStyles = ({ palette }: Theme) =>
  createStyles({
    root: {
      color: palette.error.main,
      '&:hover': {
        background: lighten(palette.error.main, 0.8),
      },
    },
    contained: {
      background: palette.error.main,
      color: palette.getContrastText(palette.error.main),
      '&:hover': {
        background: palette.error.dark,
      },
    },
    outlined: {
      background: 'transparent',
      color: palette.error.main,
      border: `1px solid ${palette.error.main}`,
      '&:hover': {
        background: lighten(palette.error.main, 0.8),
      },
    },
  });

const greenStyles = ({ palette }: Theme) =>
  createStyles({
    root: {
      color: palette.success.main,
      '&:hover': {
        background: lighten(palette.success.main, 0.8),
      },
    },
    contained: {
      background: palette.success.main,
      color: palette.getContrastText(palette.success.main),
      '&:hover': {
        background: palette.success.dark,
      },
    },
    outlined: {
      background: 'transparent',
      color: palette.success.main,
      border: `1px solid ${palette.success.main}`,
      '&:hover': {
        background: lighten(palette.success.main, 0.8),
      },
    },
  });

export const RedButton = withStyles(redStyles)(Button);

export const GreenButton = withStyles(greenStyles)(Button);
