import { TextField } from '@material-ui/core';
import React from 'react';
import FieldSection from '../FieldSection';
import RolesSelector from '../RolesSelector';
import EditPasswordConfig from './EditPasswordConfig';
import { SecurityProfile2 } from './index';

interface EditSecurityProfileProps {
  hasEditAccess: boolean;
  securityProfileUpdated: Partial<SecurityProfile2>;
  handleChangeEvent: (field: string) => () => void;
  handleChangeValue: (field: string) => (newValue: any) => void;
  handleChangeObject: (field: string) => (newValue: any) => void;
}

function EditSecurityProfile(props: EditSecurityProfileProps) {
  const {
    hasEditAccess,
    handleChangeEvent,
    handleChangeValue,
    handleChangeObject,
    securityProfileUpdated,
  } = props;
  return (
    <React.Fragment>
      <FieldSection headerText="General Information">
        <TextField
          fullWidth
          label="Name *"
          disabled={!hasEditAccess}
          InputProps={{
            readOnly: !hasEditAccess,
          }}
          value={(securityProfileUpdated && securityProfileUpdated.Name) || ''}
          margin="normal"
          error={!securityProfileUpdated.Name}
          helperText={!securityProfileUpdated.Name ? 'Must provide Name' : ' '}
          onChange={handleChangeEvent('Name')}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="ID"
          disabled={!hasEditAccess}
          InputProps={{
            readOnly: !hasEditAccess,
          }}
          value={(securityProfileUpdated && securityProfileUpdated.ID) || ''}
          margin="normal"
          onChange={handleChangeEvent('ID')}
          variant="outlined"
        />
      </FieldSection>
      <RolesSelector
        headerText="Roles"
        readOnly={!hasEditAccess}
        descriptionElement={() => {
          return (
            <React.Fragment>
              Users assigned this security profile will have access to the roles
              selected here.
            </React.Fragment>
          );
        }}
        elevatedRoles={
          (securityProfileUpdated && securityProfileUpdated.Roles) || []
        }
        customRoles={
          (securityProfileUpdated && securityProfileUpdated.CustomRoles) || []
        }
        resourceUpdated={securityProfileUpdated}
        handleChangeValue={handleChangeValue}
        handleChangeObject={handleChangeObject}
        rolesFieldName="Roles"
        showCustomRoles={true}
      />
      <EditPasswordConfig
        handleChangeValue={handleChangeValue}
        // handleChangeObject={handleChangeObject}
        readOnly={!hasEditAccess}
        data={securityProfileUpdated.PasswordConfig}
      />
    </React.Fragment>
  );
}

export default EditSecurityProfile;
