import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { CurrentUser, AuthMethod } from '@ordercloud/portal-javascript-sdk';
import {
  Typography,
  Box,
  Button,
  Divider,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { RedButton } from '../Styled/Button';
import { SitecoreCircularProgress } from '../Styled/CircularProgress';
import usePortalAuth from '../Shared/hooks/usePortalAuth';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import FormPasswordField from '../Shared/FormPasswordField';
import FormSubmitButton from '../Shared/FormSubmitButton';
import sharedStyles from '../Shared/SharedStyles';
import { Alert } from '../App/AlertContainer';
import { User } from 'ordercloud-javascript-sdk';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import appConstants from '../../config/app.constants';

const AUTHMETHOD_GITHUB = 'Github';
const AUTHMETHOD_GOOGLE = 'Google';
const AUTHMETHOD_PASSWORD = 'Password';

type AUTHMETHOD_GITHUB = typeof AUTHMETHOD_GITHUB;
type AUTHMETHOD_GOOGLE = typeof AUTHMETHOD_GOOGLE;
type AUTHMETHOD_PASSWORD = typeof AUTHMETHOD_PASSWORD;

type AUTHMETHOD =
  | AUTHMETHOD_GITHUB
  | AUTHMETHOD_GOOGLE
  | AUTHMETHOD_PASSWORD
  | undefined;

interface AuthenticationProps {
  user: User;
  loginAttempts: number;
  onLogout: () => void;
  setLoginAttempts: (attempts: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    mw50: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 400,
      },
    },
  })
);

const AuthenticationSettings = (props: AuthenticationProps) => {
  const [accounts, setAccounts] = useState<AUTHMETHOD[]>([]);
  const { login, changePassword } = usePortalAuth();
  const [loading, setIsLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const recaptchaKey = String(appConstants.recaptchaClientKey);
  const classes = useStyles();
  const { user, loginAttempts, setLoginAttempts, onLogout } = props;

  useEffect(() => {
    document.title = 'Sitecore OrderCloud | Auth Settings';
    setIsLoading(true);
    CurrentUser.ListAccounts().then(response => {
      setAccounts((response as AuthMethod[]).map(m => m.Name));
      setIsLoading(false);
    });
  }, []);

  const linkAccount = useCallback(
    (name: string) => (e: React.MouseEvent) => {
      return CurrentUser.LinkAccount(name).then(response => {
        window.open(response.RedirectUrl, '_self');
      });
    },
    []
  );

  const unlinkAccount = useCallback(
    (name: string) => (e: React.MouseEvent) => {
      return CurrentUser.RemoveAccount(name).then(response =>
        setAccounts(accts => accts.filter(a => a !== name))
      );
    },
    []
  );

  const oneRemaining = useMemo(() => {
    return accounts && accounts.length <= 1;
  }, [accounts]);

  const validationShape = useMemo(() => {
    const shape = {
      currentPassword: yup.string(),
      newPassword: yup
        .string()
        .required(
          'Password must be at least eight characters long and include at least one letter and one number.'
        )
        .matches(
          /(?=.*?[a-zA-z])(?=.*?[0-9]).{8,}$/,
          'Password must be at least eight characters long and include at least one letter and one number.'
        ),
      confirmPassword: yup
        .string()
        .required('Confirm Password must match Password')
        .equalTo(
          yup.ref('newPassword'),
          'Confirm Password must match Password'
        ),
    };
    if (accounts.includes(AUTHMETHOD_PASSWORD)) {
      shape.currentPassword = yup
        .string()
        .required('Current password is required');
    }
    return shape;
  }, [accounts]);

  return !loading ? (
    <div>
      <Box padding={3}>
        <Typography variant="h4">{`${
          accounts.includes(AUTHMETHOD_PASSWORD) ? 'Change' : 'Set'
        } Password`}</Typography>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={yup.object().shape(validationShape)}
          onSubmit={async (
            { currentPassword, newPassword },
            { setSubmitting, resetForm }
          ) => {
            await changePassword(currentPassword, newPassword, recaptchaToken)
              .then(async () => {
                await login(user.Username, newPassword);
                if (!accounts.includes(AUTHMETHOD_PASSWORD)) {
                  setAccounts(a => [...a, 'Password']);
                }
                Alert.success(
                  `Password was ${
                    accounts.includes(AUTHMETHOD_PASSWORD) ? 'updated' : 'set'
                  } successfully!`
                );
                resetForm();
              })
              .catch((e: any) => {
                if (e?.errors?.Errors[0].ErrorCode === 'loginfailed') {
                  Alert.error('Incorrect credentials');
                  setLoginAttempts(loginAttempts + 1);
                  onLogout();
                } else if (
                  e?.errors?.Errors[0].ErrorCode === 'RecaptchaError'
                ) {
                  Alert.error('Recaptcha validation failed');
                } else {
                  throw e;
                }
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          <Form className={classes.mw50}>
            {accounts.includes(AUTHMETHOD_PASSWORD) && (
              <FormPasswordField
                name="currentPassword"
                label="Current Password"
                className={classes.textFields}
              />
            )}
            <FormPasswordField
              name="newPassword"
              label={
                accounts.includes(AUTHMETHOD_PASSWORD)
                  ? 'New Password'
                  : 'Password'
              }
              className={classes.textFields}
            />
            <FormPasswordField
              name="confirmPassword"
              label="Confirm Password"
              className={classes.textFields}
            />
            {recaptchaKey && (
              <ReCAPTCHA sitekey={recaptchaKey} onChange={setRecaptchaToken} />
            )}
            <Box style={{ marginTop: 16 }}>
              <FormSubmitButton variant="contained" color="primary">
                {accounts.includes(AUTHMETHOD_PASSWORD) ? 'Update ' : 'Save '}
                Password
              </FormSubmitButton>
              <RedButton
                style={{ marginLeft: 8 }}
                variant="outlined"
                disabled={oneRemaining}
                onClick={unlinkAccount(AUTHMETHOD_PASSWORD)}
              >
                Remove
              </RedButton>
            </Box>
          </Form>
        </Formik>
      </Box>
      <Divider />
      <Box padding={3}>
        <Typography variant="h4">Github</Typography>
        {accounts.includes(AUTHMETHOD_GITHUB) ? (
          <div>
            <Typography variant="subtitle2" color="textSecondary" paragraph>
              Your Portal account is currently linked to GitHub
            </Typography>
            <RedButton
              variant="outlined"
              disabled={oneRemaining}
              onClick={unlinkAccount(AUTHMETHOD_GITHUB)}
            >
              Unlink
            </RedButton>
          </div>
        ) : (
          <div>
            <Typography variant="subtitle2" color="textSecondary" paragraph>
              Linking your account to GitHub allows you to sign in by clicking
              the GitHub button instead of using your password.
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={linkAccount(AUTHMETHOD_GITHUB)}
            >
              Link
            </Button>
          </div>
        )}
      </Box>
      <Divider />
      <Box padding={3}>
        <Typography variant="h4">Google</Typography>

        {accounts.includes(AUTHMETHOD_GOOGLE) ? (
          <div>
            <Typography variant="subtitle2" color="textSecondary" paragraph>
              Your Portal account is currently linked to Google
            </Typography>
            <RedButton
              variant="outlined"
              disabled={oneRemaining}
              onClick={unlinkAccount(AUTHMETHOD_GOOGLE)}
            >
              Unlink
            </RedButton>
          </div>
        ) : (
          <div>
            <Typography variant="subtitle2" color="textSecondary" paragraph>
              Linking your account to Google allows you to sign in by clicking
              the Google button instead of using your password.
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={linkAccount(AUTHMETHOD_GOOGLE)}
            >
              Link
            </Button>
          </div>
        )}
      </Box>
      {/* <Divider />
      <Box padding={3}>
        <Typography variant="h4">Sitecore Cloud</Typography>

        {accounts.includes(AUTHMETHOD_SITECORE) ? (
          <div>
            <Typography variant="subtitle2" color="textSecondary" paragraph>
              Your Portal account is currently linked to Sitecore Cloud
            </Typography>
            <RedButton
              variant="outlined"
              disabled={oneRemaining}
              onClick={unlinkAccount(AUTHMETHOD_SITECORE)}
            >
              Unlink
            </RedButton>
          </div>
        ) : (
          <div>
            <Typography variant="subtitle2" color="textSecondary" paragraph>
              Linking your account to Sitecore Cloud allows you to sign in by
              clicking the Sitecore button instead of using your password.
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={linkAccount(AUTHMETHOD_SITECORE)}
            >
              Link
            </Button>
          </div>
        )}
      </Box> */}
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 112px)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SitecoreCircularProgress variant="indeterminate" />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.devCenterUser,
  };
};

export default connect(mapStateToProps)(AuthenticationSettings);
