import { Typography, createStyles, Theme } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { ChevronRight } from '@material-ui/icons';
import { ConsoleTab } from '../../redux/tab/tab.types';
import { ContextChoice } from '../../redux/contextSelections/contextSelections.types';

const styles = (theme: Theme) =>
  createStyles({
    tabContext: {
      color: theme.palette.primary[100],
      fontSize: 10,
      display: 'flex',
      alignItems: 'center',
      maxHeight: 10,
    },
    tabContextChevron: {
      fontSize: 12,
    },
    tabContextUsername: {
      maxWidth: '8ch',
    },
    tabContextCompany: {},
  });

interface TabContextSnapShotProps {
  tabContext?: ContextChoice;
  classes: any;
  tab: ConsoleTab;
  variant?: any;
}

function TabContextSnapShot(props: TabContextSnapShotProps) {
  const { classes, tabContext, tab, variant } = props;
  return (
    <Typography
      className={variant ? undefined : classes.tabContext}
      variant={variant || 'caption'}
    >
      {!tabContext && 'No Context'}
      {tabContext && (
        <React.Fragment>
          <span className={classes.tabContextCompany}>
            {tabContext.SELLER_ORG.Name}
          </span>
          {tabContext.USER.Username && !tab.isViewingPrettyUI && (
            <React.Fragment>
              <ChevronRight className={classes.tabContextChevron} />
              <Typography
                title={tabContext.USER.Username}
                variant="inherit"
                noWrap
                className={
                  tabContext.SELLER_ORG.Name.length > 20
                    ? classes.tabContextUsername
                    : ''
                }
              >
                {tabContext.USER.Username}
              </Typography>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Typography>
  );
}

export default withStyles(styles)(TabContextSnapShot);
