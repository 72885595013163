import React, { useCallback, useEffect, useState } from 'react';
import { Link } from '@material-ui/core';
import { Link as ReactRouterLink } from 'react-router-dom';
import AuthContainer from './AuthContainer';
import { parse as parseQueryString } from 'query-string';
import { RouteComponentProps } from 'react-router';
import { Accounts } from '@ordercloud/portal-javascript-sdk';

interface UnlockAccountProps extends RouteComponentProps {}
const UnlockAccount = (props: UnlockAccountProps) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const unlockAccount = useCallback(async () => {
    try {
      const q = parseQueryString(props.location.search);
      const code = q.code as string;
      const email = q.email as string;
      await Accounts.Unlock({
        Code: code,
        Email: email,
      });
      setSuccess(true);
    } catch (e) {
      setError(true);
    }
  }, [props.location.search]);

  useEffect(() => {
    unlockAccount();
  }, [unlockAccount]);

  if (success) {
    return (
      <AuthContainer>
        <h2>Success!</h2>
        <p>
          Your account has been unlocked.{' '}
          <Link component={ReactRouterLink} to="/login">
            Try logging in again
          </Link>{' '}
        </p>
      </AuthContainer>
    );
  }
  return (
    <AuthContainer>
      {error && (
        <p>
          We were unable to unlock your account.
          <Link component={ReactRouterLink} to="/login">
            {' '}
            Back to Login
          </Link>
        </p>
      )}
    </AuthContainer>
  );
};

export default UnlockAccount;
