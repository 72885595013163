/* eslint-disable no-redeclare */
import { combineReducers, Reducer } from 'redux';
import { pickBy, keys, Dictionary } from 'lodash';
import {
  ConsoleTabsAction,
  ConsoleTabs,
  ConsoleTab,
  ConsoleTabsMeta,
  ConsoleTabsMetaAction,
} from './tab.types';
import {
  DEFAULT_TABS,
  SET_TAB_NAME,
  SET_TAB_ENTRY_TYPE,
  SET_TAB_TOKEN,
  CLEAR_TAB_TOKENS,
  SET_TAB_REQUEST,
  CREATE_TAB,
  DESTROY_TABS,
  SET_TAB_OPERATION,
  DEFAULT_TAB_ID,
  DEFAULT_TAB_IDS,
  SET_TAB,
  MOVE_TAB,
  SET_IS_VIEWING_PRETTY_UI,
  COPY_TAB,
} from './tab.constants';
import { dedupeHistory } from './tab.helpers';
import { COPY_TAB_CONTEXT } from '../contextSelections/contextSelections.constants';
import { CopyTabContext } from '../contextSelections/contextSelections.types';
import { determinePrettyUIView } from '../../components/ApiConsole/RequestTab/PrettyUI';

const tabs: Reducer<ConsoleTabs, ConsoleTabsAction> = (
  state: ConsoleTabs = DEFAULT_TABS,
  action: ConsoleTabsAction | CopyTabContext
) => {
  const pickTabs = (
    tabDetails: Dictionary<ConsoleTab>,
    tabIdsToDelete: string[]
  ) => {
    const newTabs = pickBy(
      tabDetails,
      (tab, tabId) => !tabIdsToDelete.includes(tabId)
    );
    return keys(newTabs).length ? newTabs : DEFAULT_TABS;
  };
  let newTab;
  let stateCopy: ConsoleTabs;
  switch (action.type) {
    case SET_TAB_NAME:
      stateCopy = Object.assign({}, state);
      stateCopy[action.payload.tabId].name = action.payload.name;
      stateCopy[action.payload.tabId].wasNameEdited = true;
      return stateCopy;
    case SET_TAB_ENTRY_TYPE:
      stateCopy = Object.assign({}, state);
      stateCopy[action.payload.tabId].entryType = action.payload.entryType;
      return stateCopy;
    case SET_TAB_TOKEN:
      stateCopy = Object.assign({}, state);
      stateCopy[action.payload.tabId].userToken = action.payload.userToken;

      return stateCopy;
    case CLEAR_TAB_TOKENS:
      stateCopy = Object.assign({}, state);
      Object.keys(stateCopy).forEach(tabId => {
        stateCopy[tabId].userToken = undefined;
      });
      return stateCopy;
    case SET_TAB_REQUEST:
      stateCopy = Object.assign({}, state);
      stateCopy[action.payload.tabId].requestId = action.payload.requestId;
      return stateCopy;
    case CREATE_TAB:
      return { ...state, [action.payload.tab.id]: action.payload.tab };
    case DESTROY_TABS:
      return pickTabs(state, action.payload.tabIdsToDelete);
    case COPY_TAB:
      var { newTabID, copiedTabID } = action.payload;
      var additions: Partial<ConsoleTab> = {
        operationId: state[copiedTabID].operationId,
        name: state[copiedTabID].name,
      };
      newTab = {
        ...state[newTabID],
        ...additions,
      };
      return { ...state, [newTabID]: newTab };
    case SET_TAB_OPERATION:
      var tabId = action.payload.tabId;
      var additions: Partial<ConsoleTab> = {
        operationId: action.payload.operation.operationId,
        isViewingPrettyUI: determinePrettyUIView(
          action.payload.operation.operationId,
          state[tabId].operationId,
          state[tabId].isViewingPrettyUI
        ),
      };
      if (!state[tabId].wasNameEdited) {
        // only set generated name if user hasn't already edited tab name
        const newName = action.payload.operation.summary.split('.')[0];
        additions = { ...additions, name: newName };
      }
      newTab = {
        ...state[tabId],
        ...additions,
      };
      return { ...state, [tabId]: newTab };
    case COPY_TAB_CONTEXT:
      var { copiedTabID, newTabID } = action.payload;
      const updatedTab = {
        ...state[newTabID],
        userToken: state[copiedTabID].userToken || null,
      };
      return {
        ...state,
        [newTabID]: updatedTab,
      };
    case SET_IS_VIEWING_PRETTY_UI:
      return {
        ...state,
        [action.payload.tabId]: {
          ...state[action.payload.tabId],
          isViewingPrettyUI: action.payload.isViewingPrettyUI,
        },
      };
    default:
      return state;
  }
};

const tabsMeta: Reducer<ConsoleTabsMeta, ConsoleTabsMetaAction> = (
  state: ConsoleTabsMeta = {
    allIds: [DEFAULT_TAB_ID],
    activeId: DEFAULT_TAB_ID,
    moveHistory: [DEFAULT_TAB_ID],
  },
  action: ConsoleTabsMetaAction
) => {
  switch (action.type) {
    case CREATE_TAB:
      return {
        ...state,
        ...{
          allIds: [...state.allIds, action.payload.tab.id],
          activeId: action.payload.tab.id,
          moveHistory: [...state.moveHistory, action.payload.tab.id],
        },
      };
    case DESTROY_TABS:
      const { tabIdsToDelete } = action.payload;
      const newIds = state.allIds.filter(i => !tabIdsToDelete.includes(i));
      const newActiveTabId = newIds.length
        ? newIds[newIds.length - 1]
        : DEFAULT_TAB_ID;

      return {
        allIds: newIds.length ? newIds : DEFAULT_TAB_IDS,
        activeId: newActiveTabId,
        moveHistory: dedupeHistory([
          ...state.moveHistory.filter(t => !tabIdsToDelete.includes(t)),
          newActiveTabId,
        ]),
      };
    case SET_TAB:
      return {
        ...state,
        ...{
          activeId: action.payload.tabId,
          moveHistory: [...state.moveHistory, action.payload.tabId],
        },
      };
    case MOVE_TAB:
      const oldIndex = state.allIds.indexOf(action.payload.tabId);
      const id = state.allIds.splice(oldIndex, 1)[0];
      state.allIds.splice(action.payload.newIndex, 0, id);
      return { ...state };
    default:
      return state;
  }
};

export default combineReducers({
  tabsMeta,
  tabs,
});
