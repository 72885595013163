import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'
import { AppReducerState } from '../redux'
import { setSellerOrgImpersonationToken } from '../redux/contextSelections/contextObjectConstructor.actions'
import { goToExternal } from './link.helper'
import devcenterMiddleware from './devcenterMiddleware'
import { AUTH_TOKEN_KEY } from '../components/App/PortalContext'
import { ApiClients } from '@ordercloud/portal-javascript-sdk'

const AUTH_EXPIRE_BUFFER_IN_SECONDS = 3 * 60

class AuthenticationService {
  private readonly cookies = new Cookies()
  private readonly _loginAttemptsCookieName: string = 'DevCenter.login_attempts'

  public backToDocs = (route: string) => (event: React.MouseEvent) => {
    goToExternal(route)
  }

  public loggedIn(): boolean {
    const token = this.cookies.get(AUTH_TOKEN_KEY)
    return !this.isTokenExpired(token)
  }

  public getDevCenterToken(): string {
    return this.cookies.get(AUTH_TOKEN_KEY)
  }

  public isTokenExpired(token?: string): boolean {
    if (!token) {
      return true
    }
    const parsedToken = jwtDecode(token)
    const currentSeconds = Date.now() / 1000
    const currentSecondsWithBuffer = currentSeconds + AUTH_EXPIRE_BUFFER_IN_SECONDS
    return parsedToken.exp < currentSecondsWithBuffer
  }

  public getTimeToExpire(token: string): number {
    const parsedToken = jwtDecode(token)
    const currentSeconds = Date.now() / 1000
    const currentSecondsWithBuffer = currentSeconds + AUTH_EXPIRE_BUFFER_IN_SECONDS
    return (parsedToken.exp - currentSecondsWithBuffer) * 1000
  }

  public getRoles(token): string[] {
    const parsedToken = jwtDecode(token)
    return parsedToken.role || []
  }

  public getCurrentSellerOrgTokenFromState(state: AppReducerState) {
    try {
      const activeId = state.tabs.tabsMeta.activeId
      const currentSellerOrgID =
        state.contextSelections.contextChoices[activeId].saved.SELLER_ORG.Id
      const currentSellerToken = state.contextSelections.sellerOrgTokens[currentSellerOrgID]
      return currentSellerToken
    } catch (e) {
      // return empty string when there is no current seller org selected
      return ''
    }
  }

  public getCurrentSellerOrgIDFromState(state: AppReducerState) {
    try {
      return state.contextSelections.contextChoices[state.tabs.tabsMeta.activeId].saved.SELLER_ORG
        .Id
    } catch (e) {
      // return empty string when there is no current seller org selected
      return ''
    }
  }

  public getCurrentEnvironmentFromState(state: AppReducerState) {
    try {
      return state.contextSelections.contextChoices[state.tabs.tabsMeta.activeId].saved.SELLER_ORG
        .Environment
    } catch (e) {
      // return empty string when there is no current seller org selected
      return undefined
    }
  }

  public getUpdatedSellerOrgTokenAndSetInRedux(sellerOrgID: string, currentSellerToken?: string) {
    // returns
    // 1) current token if it is valid
    // 2) nothing if there is no sellerOrgID selected
    // 3) the updated org token if there is an invalid token and a seller org selected
    return async dispatch => {
      if (!this.isTokenExpired(currentSellerToken)) {
        return currentSellerToken
      } else if (!sellerOrgID) {
        return ''
      } else {
        const tokenResponse = await ApiClients.GetToken(sellerOrgID)
        const newSellerOrgImpersonationToken = tokenResponse.access_token
        dispatch(setSellerOrgImpersonationToken(sellerOrgID, newSellerOrgImpersonationToken))
        return newSellerOrgImpersonationToken
      }
    }
  }

  public getLoginAttempts(): number {
    const attempts = this.cookies.get(this._loginAttemptsCookieName)
    return attempts ? parseInt(attempts, 10) : 0
  }

  public setLoginAttempts(attempts: number) {
    this.cookies.set(this._loginAttemptsCookieName, attempts)
  }

  public async verifyRecaptcha(token: string): Promise<void> {
    if (!token) {
      throw new Error('RecaptchaError: Failed validation')
    }
    return await devcenterMiddleware.Post('/recaptcha/verify', {
      Token: token,
    })
  }
}

export default new AuthenticationService()
