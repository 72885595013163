import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Theme,
  createStyles,
  Grid,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import {
  ApiClient,
  ApiClientAssignment,
  Buyers as ocBuyers,
  Suppliers as ocSuppliers,
} from 'ordercloud-javascript-sdk';
import Checkbox from '@material-ui/core/Checkbox';
import AssignmentSearch from './AssignmentSearch';
import FieldSection from '../FieldSection';

interface ClientAccessConfigurationProps {
  readOnly?: boolean;
  apiClientUpdated: Partial<ApiClient>;
  apiClientID: string;
  onChange: (field: string) => (event: any) => void;
  apiClientAssignmentsUpdated: ApiClientAssignment[];
  setApiClientAssignmentsUpdated: (
    apiClientAssignments: ApiClientAssignment[]
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tokenField: {
      width: '49%',
      margin: 0,
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    marginTop: {
      marginTop: theme.spacing(1),
    },
  })
);

function ClientAccessConfiguration(props: ClientAccessConfigurationProps) {
  const classes = useStyles();

  const {
    apiClientUpdated,
    onChange,
    setApiClientAssignmentsUpdated,
    apiClientAssignmentsUpdated,
    apiClientID,
    readOnly,
  } = props;

  const toggleSellerAccess = () => {
    onChange('AllowSeller')(!apiClientUpdated.AllowSeller);
  };

  const setAccess = userType => (event: any) => {
    switch (event.target.value) {
      case 'AllowAny':
        onChange(`AllowAny${userType}`)(true);
        setApiClientAssignmentsUpdated(filterOutAssignmentsOfType(userType));
        setIsAssigningSpecific({ ...isAssigningSpecific, [userType]: false });
        break;
      case 'NoAccess':
        onChange(`AllowAny${userType}`)(false);
        setApiClientAssignmentsUpdated(filterOutAssignmentsOfType(userType));
        setIsAssigningSpecific({ ...isAssigningSpecific, [userType]: false });
        break;
      case 'AssignSpecific':
        onChange(`AllowAny${userType}`)(false);
        setApiClientAssignmentsUpdated(filterOutAssignmentsOfType(userType));
        setIsAssigningSpecific({ ...isAssigningSpecific, [userType]: true });
        break;
    }
  };

  const filterOutAssignmentsOfType = (
    userType: 'Buyer' | 'Supplier'
  ): ApiClientAssignment[] => {
    return apiClientAssignmentsUpdated.filter(a => !a[`${userType}ID`]);
  };

  const toggleUser = (userType: 'Buyer' | 'Supplier') => (userID: string) => {
    if (!apiClientAssignmentsUpdated.some(a => a[`${userType}ID`] === userID)) {
      const newApiClientAssignment: ApiClientAssignment = {
        ApiClientID: apiClientUpdated.ID || '',
        BuyerID: '',
        SupplierID: '',
        [`${userType}ID`]: userID,
      };
      setApiClientAssignmentsUpdated([
        ...apiClientAssignmentsUpdated,
        newApiClientAssignment,
      ]);
    } else {
      setApiClientAssignmentsUpdated(
        apiClientAssignmentsUpdated.filter(a => a[`${userType}ID`] !== userID)
      );
    }
  };

  // this state is used for managing the UI when a buyer or supplier
  // might not yet be selected and the user wants to assign them
  const [isAssigningSpecific, setIsAssigningSpecific] = useState({});

  useEffect(() => {
    const setIsAssigningInitialState = () => {
      const initialState = {
        Buyer: apiClientUpdated.AssignedBuyerCount,
        Supplier: apiClientUpdated.AssignedSupplierCount,
      };
      setIsAssigningSpecific(initialState);
    };
    setIsAssigningInitialState();
  }, [
    apiClientID,
    apiClientUpdated.AssignedBuyerCount,
    apiClientUpdated.AssignedSupplierCount,
  ]);

  const parseAccesss = (
    apiClient: Partial<ApiClient>,
    userType: 'Buyer' | 'Supplier'
  ): string => {
    if (apiClient[`AllowAny${userType}`]) {
      return `AllowAny`;
    } else if (isAssigningSpecific[userType]) {
      return `AssignSpecific`;
    } else {
      return `NoAccess`;
    }
  };

  return (
    <FieldSection
      headerText="Client Access Configuration"
      infoText="Set access for specific users and/or specific types of users for this API client. Any API client can be used for any combination of supplier, seller, and buyer users."
    >
      <FormLabel className={classes.marginTop} component="legend">
        Seller Access
      </FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            disabled={readOnly}
            checked={apiClientUpdated.AllowSeller}
            onChange={toggleSellerAccess}
            value="sellerAccess"
          />
        }
        label="Allow All Sellers"
      />
      <Grid container spacing={5} className={classes.marginTop}>
        <Grid item xs={6}>
          <FormLabel component="legend">Supplier Access</FormLabel>
          <RadioGroup
            aria-label="Supplier Access"
            name="supplierAccess"
            value={parseAccesss(apiClientUpdated, 'Supplier')}
            onChange={setAccess('Supplier')}
          >
            <FormControlLabel
              value="NoAccess"
              control={<Radio color="secondary" disabled={readOnly} />}
              label="No Supplier Access"
            />
            <FormControlLabel
              value="AllowAny"
              control={<Radio color="secondary" disabled={readOnly} />}
              label="Allow All Suppliers"
            />
            <FormControlLabel
              value="AssignSpecific"
              control={<Radio color="secondary" disabled={readOnly} />}
              label="Assign Specific Suppliers"
            />
            {parseAccesss(apiClientUpdated, 'Supplier') ===
              'AssignSpecific' && (
              <AssignmentSearch
                readOnly={readOnly}
                userType={'Supplier'}
                toggleUser={toggleUser('Supplier')}
                apiClientAssignmentsUpdated={apiClientAssignmentsUpdated}
                ocUserService={ocSuppliers}
              />
            )}
          </RadioGroup>
        </Grid>
        <Grid item xs={6}>
          <FormLabel component="legend">Buyer Access</FormLabel>
          <RadioGroup
            aria-label="Buyer Access"
            name="BuyerAccess"
            value={parseAccesss(apiClientUpdated, 'Buyer')}
            onChange={setAccess('Buyer')}
          >
            <FormControlLabel
              value="NoAccess"
              control={<Radio color="secondary" disabled={readOnly} />}
              label="No Buyer Access"
            />
            <FormControlLabel
              value="AllowAny"
              control={<Radio color="secondary" disabled={readOnly} />}
              label="Allow All Buyers"
            />
            <FormControlLabel
              value="AssignSpecific"
              control={<Radio color="secondary" disabled={readOnly} />}
              label="Assign Specific Buyers"
            />
            {parseAccesss(apiClientUpdated, 'Buyer') === 'AssignSpecific' && (
              <AssignmentSearch
                readOnly={readOnly}
                toggleUser={toggleUser('Buyer')}
                userType={'Buyer'}
                ocUserService={ocBuyers}
                apiClientAssignmentsUpdated={apiClientAssignmentsUpdated}
              />
            )}
          </RadioGroup>
        </Grid>
      </Grid>
    </FieldSection>
  );
}

export default ClientAccessConfiguration;
