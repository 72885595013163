import {
  Auth,
  Configuration,
  CookieOptions,
  CurrentUser,
  DecodedToken,
  Tokens,
} from '@ordercloud/portal-javascript-sdk';
import jwtDecode from 'jwt-decode';
import { useCallback, useMemo, useState } from 'react';
import useLocalStorage from './useLocalStorage';

const getCookieDomain = () => {
  const hostname = window.location.hostname;
  if (
    hostname.includes('azurewebsites') ||
    hostname === 'localhost' ||
    hostname === 'a3164c97.ngrok.io'
  ) {
    return;
  }
  return `.${hostname
    .split('.')
    .slice(1)
    .join('.')}`;
};

Configuration.Set({
  baseApiUrl: `${
    window.location.origin.includes('http://localhost')
      ? 'https://portal.ordercloud-qa.com'
      : window.location.origin
  }/api/v1`,
  cookieOptions: {
    path: '/',
    domain: getCookieDomain(),
    prefix: 'portal',
    persist: false,
  },
});

export interface PortalAuth {
  login: (
    username: string,
    password: string,
    persist?: boolean,
    recaptcha?: string
  ) => Promise<void>;
  refresh: (refreshToken: string) => Promise<void>;
  logout: () => void;
  changePassword: (
    currentPassword: string,
    newPassword: string,
    recaptchaToken: string
  ) => Promise<void>;
  setLoginAttempts: (attempts: number) => Promise<void>;
  login_attempts: number;
  decoded_token?: DecodedToken;
  access_token?: string;
  refresh_token?: string;
}

/* 
Potential new hook for basic portal authentication using the portal-javascript-sdk.
Perhaps this will also be able to hold onto login_attempt count so we could eliminate 
the auth service. 

*/
const usePortalAuth = (): PortalAuth => {
  const [loginAttempts, setLoginAttempts] = useLocalStorage(
    'DevCenter.login_attempts',
    0
  );
  const [accessToken, setAccessToken] = useState(Tokens.GetAccessToken());
  const [refreshToken, setRefreshToken] = useState(Tokens.GetRefreshToken());
  const [decodedToken, setDecodedToken] = useState<DecodedToken | undefined>(
    accessToken ? jwtDecode(accessToken) : undefined
  );

  //Updates the SDK access token before changing internal state
  const setAccess = useCallback((token: string | undefined) => {
    if (!token) {
      Tokens.RemoveAccessToken();
      setDecodedToken(undefined);
    } else if (Tokens.GetAccessToken() !== token) {
      Tokens.SetAccessToken(token);
      setDecodedToken(jwtDecode(token));
    }
    setAccessToken(token);
  }, []);

  //Updates the SDK refresh token before changing internal state
  const setRefresh = useCallback((token: string | undefined) => {
    if (!token) {
      Tokens.RemoveRefreshToken();
    } else if (Tokens.GetRefreshToken() !== token) {
      Tokens.SetRefreshToken(token);
    }
    setRefreshToken(token);
  }, []);

  /*
  login method
  1. Configure the SDK cookie persits configuration based on the optional persist arg (remember me)
  2. Calls the API to get new access/refresh tokens
  3. Update the state values to update the return value & sdk Tokens simultaneously
  4. Errors are captured using setError
  */
  const login = useCallback(
    async (
      username: string,
      password: string,
      persist?: boolean,
      recaptchaToken?: string
    ) => {
      const cookieOptions = Configuration.Get().cookieOptions as CookieOptions;
      Configuration.Set({
        cookieOptions: { ...cookieOptions, persist: !!persist },
      });
      console.log(`reCAPTCHA token value: ${recaptchaToken}`);
      try {
        const { refresh_token, access_token } = await Auth.Login(
          username,
          password,
          recaptchaToken
        );
        setAccess(access_token);
        setRefresh(refresh_token);
        setLoginAttempts(0);
      } catch (ex) {
        setLoginAttempts(a => a + 1);
        if (typeof ex === 'string') throw Error(ex);
      }
    },
    [setAccess, setLoginAttempts, setRefresh]
  );

  /*
  logout method
  1. Using the refresh_token, calls Auth.Logout which invalidates the refresh_token in the backend
  2. Update the state values to update the return value & sdk Tokens simultaneously
  3. Errors are captured using setError

  TODO: think about if we should still update the accessToken state value to undefined even if there's no refreshToken
  */
  const logout = useCallback(() => {
    if (refreshToken) {
      Auth.Logout(refreshToken);
    }
    setAccess(undefined);
    setRefresh(undefined);
    localStorage.removeItem('OC:PORTAL:AUTH0');
  }, [refreshToken, setAccess, setRefresh]);

  /*
  refresh method
  1. This does not configure the SDK for persistance since this isn't meant for login form
  2. Accepts a refresh_token (what we get back when logging in with Github/Google) and gets a new set of tokens
  3. Update the state values to update the return value & sdk Tokens simultaneously
  4. Errors are captured using setError
  */
  const refresh = useCallback(
    async (token: string) => {
      try {
        const { access_token, refresh_token } = await Auth.RefreshToken(token, {
          requestType: 'quiet',
        });
        setAccess(access_token);
        setRefresh(refresh_token);
      } catch (ex) {
        setRefresh(undefined);
        setAccess(undefined);
      }
    },
    [setAccess, setRefresh]
  );

  /*
  changePassword method
  1. Accepts a password and refresh token
  2. Updates the user's password and invalidates the current session
  3. Errors are captured using setError
  */
  const changePassword = useCallback(
    async (
      currentPassword: string,
      newPassword: string,
      recaptchaToken: string
    ) => {
      const body = {
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
        RefreshToken: refreshToken,
        Recaptcha: { Token: recaptchaToken },
      };
      await CurrentUser.ChangePassword(body, {
        requestType: 'quiet',
      });
    },
    [refreshToken]
  );

  /*
  Return value is in useMemo so that it will trigger updates in our Application 
  component (or wherever this is used). This is why everything is wrapped in a useCallback
  */
  const value = useMemo(() => {
    return {
      login,
      refresh,
      logout,
      changePassword,
      setLoginAttempts,
      login_attempts: loginAttempts,
      decoded_token: decodedToken,
      access_token: accessToken,
      refresh_token: refreshToken,
    };
  }, [
    login,
    refresh,
    logout,
    changePassword,
    setLoginAttempts,
    loginAttempts,
    decodedToken,
    accessToken,
    refreshToken,
  ]);

  return value;
};

export default usePortalAuth;
