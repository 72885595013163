// STORES
export interface InvitationsReducerState {
  Marketplace: MarketplaceReducerState;
  MarketplaceTeam: MarketplaceTeamReducerState;
  Team: TeamReducerState;
}
export type MarketplaceReducerState = Marketplace[];
export type MarketplaceTeamReducerState = MarketplaceTeamInvitation[];
export type TeamReducerState = Team[];

// ACTIONS
export interface SetMarketplaceInvitationsAction {
  type: SET_MARKETPLACE_INVITATIONS;
  payload: { invitations: MarketplaceReducerState };
}

export interface UpdateMarketplaceInvitationsAction {
  type: UPDATE_MARKETPLACE_INVITATIONS;
  payload: { orgId: string };
}

export interface SetMarketplaceTeamInvitationsAction {
  type: SET_MARKETPLACE_TEAM_INVITATIONS;
  payload: { invitations: MarketplaceTeamReducerState };
}

export interface UpdateMarketplaceTeamInvitationsAction {
  type: UPDATE_MARKETPLACE_TEAM_INVITATIONS;
  payload: { orgId: string; teamId: string };
}

export interface SetTeamInvitationsAction {
  type: SET_TEAM_INVITATIONS;
  payload: { invitations: TeamReducerState };
}

export interface UpdateTeamInvitationsAction {
  type: UPDATE_TEAM_INVITATIONS;
  payload: { teamId: string };
}

export type InvitationAction =
  | SetMarketplaceInvitationsAction
  | UpdateMarketplaceInvitationsAction
  | SetMarketplaceTeamInvitationsAction
  | UpdateMarketplaceTeamInvitationsAction
  | SetTeamInvitationsAction
  | UpdateTeamInvitationsAction;

// DATA

export interface MarketplaceInvitationsCombined {
  OrgInvitation: Marketplace[];
  OrgTeamInvitation: MarketplaceTeamInvitation[];
}

export interface MarketplaceTeamInvitation {
  Accepted: boolean;
  ImpersonateBuyer: boolean;
  ImpersonateSeller: boolean;
  ImpersonateSupplier: boolean;
  IsAdmin: boolean;
  Org: Marketplace;
  Roles: string[];
  Team: Team;
}

export interface Marketplace {
  CoreApiUrl: string;
  Environment: string;
  Id: string;
  Name: string;
  Owner: OrgOwner;
  Region: OrgRegion;
}

export interface OrgOwner {
  Country: string;
  Email: string;
  Name: string;
  Region: string;
  TermsAccepted: boolean;
  Username: string;
}

export interface OrgRegion {
  AzureRegion: string;
  Id: string;
  Name: string;
}

export interface Team {
  ExcludeFromSearch: boolean;
  Id: string;
  Name: string;
  Owner: OrgOwner;
}

// CONSTANTS
export const SET_MARKETPLACE_INVITATIONS = 'SET_MARKETPLACE_INVITATIONS';
export type SET_MARKETPLACE_INVITATIONS = typeof SET_MARKETPLACE_INVITATIONS;

export const UPDATE_MARKETPLACE_INVITATIONS = 'UPDATE_MARKETPLACE_INVITATIONS';
export type UPDATE_MARKETPLACE_INVITATIONS = typeof UPDATE_MARKETPLACE_INVITATIONS;

export const SET_MARKETPLACE_TEAM_INVITATIONS =
  'SET_MARKETPLACE_TEAM_INVITATIONS';
export type SET_MARKETPLACE_TEAM_INVITATIONS = typeof SET_MARKETPLACE_TEAM_INVITATIONS;

export const UPDATE_MARKETPLACE_TEAM_INVITATIONS =
  'UPDATE_MARKETPLACE_TEAM_INVITATIONS';
export type UPDATE_MARKETPLACE_TEAM_INVITATIONS = typeof UPDATE_MARKETPLACE_TEAM_INVITATIONS;

export const SET_TEAM_INVITATIONS = 'SET_TEAM_INVITATIONS';
export type SET_TEAM_INVITATIONS = typeof SET_TEAM_INVITATIONS;

export const UPDATE_TEAM_INVITATIONS = 'UPDATE_TEAM_INVITATIONS';
export type UPDATE_TEAM_INVITATIONS = typeof UPDATE_TEAM_INVITATIONS;
