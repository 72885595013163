import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import AuthService from './auth.service';
import appConstants from '../config/app.constants';

class DevcenterMiddleware {
  private session: AxiosInstance;
  constructor() {
    this.session = axios.create({
      baseURL: appConstants.middlewareUrl,
    });

    // append auth token to all outgoing requests
    this.session.interceptors.request.use(config => {
      const token = AuthService.getDevCenterToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  public Get(url: string, config?: AxiosRequestConfig) {
    return this.session
      .get(url, config)
      .then(r => r.data)
      .catch(e => {
        throw e.response;
      });
  }

  public Post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.session
      .post(url, data, config)
      .then(r => r.data)
      .catch(e => {
        throw e.response;
      });
  }
  public Put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.session
      .put(url, data, config)
      .then(r => r.data)
      .catch(e => {
        throw e.response;
      });
  }

  public Patch(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.session
      .patch(url, data, config)
      .then(r => r.data)
      .catch(e => {
        throw e.response;
      });
  }

  public Delete(url: string, config?: AxiosRequestConfig) {
    return this.session
      .delete(url, config)
      .then(r => r.data)
      .catch(e => {
        throw e.response;
      });
  }
}

export default new DevcenterMiddleware();
