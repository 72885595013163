import React from 'react';
import { ApiClient } from '@ordercloud/portal-javascript-sdk';
import { Chip } from '@material-ui/core';

export default class ApiClientAccess extends React.Component<ApiClient> {
  public render() {
    const {
      AllowAnyBuyer,
      AllowAnySupplier,
      AllowSeller,
      AssignedBuyerCount,
      AssignedSupplierCount,
    } = this.props;
    let label = '';

    if (AllowSeller && AllowAnyBuyer && AllowAnySupplier) {
      label = 'All Users';
    } else {
      if (AllowSeller) {
        label +=
          'Sellers' +
          (AllowAnyBuyer ||
          AssignedBuyerCount ||
          AllowAnySupplier ||
          AssignedSupplierCount
            ? ', '
            : '');
      }

      if (AllowAnyBuyer) {
        label += 'Buyers' + (AllowAnySupplier ? ', ' : '');
      } else if (AssignedBuyerCount) {
        label += `${AssignedBuyerCount} Buyer${
          AssignedBuyerCount > 1 ? 's' : ''
        } ${AllowAnySupplier ? ', ' : ''}`;
      }

      if (AllowAnySupplier) {
        label += 'Suppliers';
      } else if (AssignedSupplierCount) {
        label += `${AssignedSupplierCount} Supplier${
          AssignedSupplierCount > 1 ? 's' : ''
        }`;
      }
    }
    return <Chip size="small" color="default" label={label} />;
  }
}
