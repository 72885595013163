import { debounce, pick } from 'lodash';
import { Middleware, MiddlewareAPI } from 'redux';

/**
 * TODO: Think about what other data should be saved to local storage.
 *
 * There are some pieces of data it seems pretty clear should be confined to the session. For example,
 * the request history within a single tab.
 *
 * Other data might be pointless to store because it has to be requested anew anyway. For example,
 * lists of users to impersonate.
 *
 * The follow up question then becomes, what is the best way to specifiy which parts of the store get saved?
 *
 * I suspect these questions will be easier to answer once someone starts working on these other features.
 * In this implementation saving parts of the store to local storage is opt-in based on reducer.
 *
 */

/**
 * SET REDUX VERSION HERE
 * when the schema of the redux store changes or any functionality of the app
 * will break without a current version of the reducer we can update this
 * to automatically clear a users redux from local storage
 *
 * this methodology is to be improved upon if the app data structure becomes more stable
 */
const VERSION: string = '5';
/*
 */

let scopedStore: MiddlewareAPI;

// Instead of saving the entire store, specific reducers can be selected. Options are in ../reducers/index.txs.
const reducersToPersist = [
  'tabs',
  'contextSelections',
  'contextOptions',
  'requestForm',
];

// Only use in index.tsx
export const SaveStateToLocalStorage = (
  storageKey: string
): Middleware => store => next => action => {
  scopedStore = store;
  const result = next(action);
  // By wrapping the save action in a promise, the work is defered until after the UI updates.
  Promise.resolve().then(() => {
    if (window.localStorage) {
      handleSaveState(storageKey);
    }
  });
  return result;
};

// Debounce prevents saving to storage more than once in 0.5 seconds.
const handleSaveState = debounce((storageKey: string) => {
  const state = scopedStore.getState();
  const stateToSave = pick(state, reducersToPersist);
  const data = JSON.stringify(addVersionToState(stateToSave));
  window.localStorage.setItem(storageKey, data);
}, 500);

// Only use in index.tsx
export function GetStateFromLocalStorage(storageKey: string) {
  if (!window.localStorage) {
    return {};
  }
  const str = window.localStorage.getItem(storageKey);
  const reduxObject = JSON.parse(str || '{}');
  return verifyReduxVersion(reduxObject);
}

const verifyReduxVersion = (state: any): any => {
  if (state.version !== VERSION) {
    // clear reducer if the app is on a different version than the local storage
    return {};
  } else {
    // delete the version from the object that will be passed into the redux store
    delete state.version;
    return state;
  }
};

const addVersionToState = (state: any): any => {
  state.version = VERSION;
  return state;
};
