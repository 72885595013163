import {
  AppBar,
  Breadcrumbs,
  createStyles,
  List,
  makeStyles,
  Paper,
  Theme,
  Toolbar,
} from '@material-ui/core';
import Case from 'case';
import React, { useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Link, RouteComponentProps } from 'react-router-dom';
import { navHeight, navHeightMobile } from '../Layout/Header';
import { DEFAULT_PORTAL_NAVIGATION_WIDTH } from '../Layout/PortalNavigation';
import ListItemNavLink from '../Shared/ListItemNavLink';
import AuthenticationSettings from './AuthenticationSettings';
import ProfileSettings from './ProfileSettings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    appBar: {
      top: navHeightMobile,
      [theme.breakpoints.up('md')]: {
        top: navHeight,
      },
    },
    breadcrumbs: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0, 2),
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& a': {
        textDecoration: 'none',
      },
    },
    menu: {
      maxWidth: 300,
    },
    mainViewContainer: {
      flexGrow: 1,
      display: 'flex',
    },
    settingsNav: {
      flex: '0 1 auto',
      borderRight: `1px solid ${theme.palette.divider}`,
      height: `calc(100vh - ${navHeightMobile * 2}px)`,
      [theme.breakpoints.up('md')]: {
        height: `calc(100vh - ${navHeight + 48}px)`,
        width: DEFAULT_PORTAL_NAVIGATION_WIDTH,
      },
    },
    settingsView: {
      flex: '1 1 auto',
    },
    tabsContent: {
      height: `calc(100vh - ${navHeightMobile * 2}px)`,
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        height: `calc(100vh - ${navHeight + 48}px)`,
      },
    },
  })
);

export interface SettingsProps
  extends RouteComponentProps<
    {
      tab?: string;
    },
    any,
    any
  > {
  loginAttempts: any;
  setLoginAttempts: any;
  onLogout: () => void;
}

const Settings = (props: SettingsProps) => {
  const classes = useStyles();
  const { match, onLogout, loginAttempts, setLoginAttempts } = props;

  const activeTab = useMemo(() => {
    return match.params.tab || 'profile';
  }, [match.params]);

  return (
    <div className={classes.root}>
      <AppBar
        position="sticky"
        color="default"
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar variant="dense" className={classes.breadcrumbs}>
          <Breadcrumbs>
            <Link to="/">Dashboard</Link>
            {activeTab && activeTab !== 'profile' && (
              <Link to="/settings/profile">Settings</Link>
            )}
            <span>
              {activeTab && activeTab === 'profile'
                ? 'Settings'
                : Case.title(activeTab)}
            </span>
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <div className={classes.mainViewContainer}>
        <div className={classes.settingsNav}>
          <List disablePadding>
            <ListItemNavLink to="/settings/profile" primary="Profile" />
            <ListItemNavLink
              to="/settings/authentication"
              primary="Authentication"
            />
          </List>
        </div>
        <div className={classes.settingsView}>
          <Paper elevation={0} square className={classes.tabsContent}>
            <Scrollbars autoHide>
              {activeTab === 'profile' && (
                <ProfileSettings
                  onLogout={onLogout}
                  setLoginAttempts={setLoginAttempts}
                  loginAttempts={loginAttempts}
                />
              )}
              {activeTab === 'authentication' && (
                <AuthenticationSettings
                  loginAttempts={loginAttempts}
                  setLoginAttempts={setLoginAttempts}
                  onLogout={onLogout}
                />
              )}
            </Scrollbars>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default Settings;
