import {
  createStyles,
  Theme,
  makeStyles,
  Button,
  Typography,
  Box,
  SvgIcon,
} from '@material-ui/core';
import React, { useState } from 'react';
import sharedStyles from '../Shared/SharedStyles';
import AuthContainer from './AuthContainer';
import { Alert } from '../App/AlertContainer';
import FormTextField from '../Shared/FormTextField';
import FormPasswordField from '../Shared/FormPasswordField';
import FormSubmitButton from '../Shared/FormSubmitButton';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Redirect } from 'react-router';
import AuthService from '../../services/auth.service';
import { Link } from '@material-ui/core';
import { Link as ReactRouterLink } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import appConstants from '../../config/app.constants';
import { Accounts } from '@ordercloud/portal-javascript-sdk';

/*****************************
 * Step 1: Verification Code *
 * ***************************
 */
const useVerificationCodeStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    accountButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1),
      margin: theme.spacing(1, 0, 2.5, 0),
    },
    accountButton: {
      '&:hover': {
        color: 'white',
        '& path': {
          fill: 'white',
        },
      },
    },
  })
);

interface GetVerificationCodeProps {
  nextStep: () => void;
  setEmail: (email: string) => void;
}
const GetVerificationCode = (props: GetVerificationCodeProps) => {
  const classes = useVerificationCodeStyles(props);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const recaptchaKey = String(appConstants.recaptchaClientKey);
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={yup.object({
        email: yup
          .string()
          .email('Invalid email address')
          .required('Email is required')
          .max(100, 'Email cannot exceed 100 characters.'),
      })}
      onSubmit={async ({ email }, { setSubmitting }) => {
        try {
          if (recaptchaKey && !recaptchaToken) {
            return Alert.error('Please verify that you are a human');
          }
          props.setEmail(email);
          await Accounts.Register({
            Email: email,
            Recaptcha: { Token: recaptchaToken },
          });
          Alert.success(
            'A verification code has been sent to your email. Please check your inbox and enter code below.'
          );
          props.nextStep();
        } catch (e) {
          if (
            (e as any) &&
            (e as any).response &&
            (e as any).response.status &&
            (e as any).response.status === 409
          ) {
            Alert.error(
              'A user with that email already exists. If you can\'t remember your password try using the "Forgot Password" feature to reset it'
            );
          } else if (
            e &&
            (e as any).data &&
            (e as any).data.Errors &&
            (e as any).data.Errors.length &&
            (e as any).data.Errors[0].ErrorCode === 'RecaptchaError'
          ) {
            Alert.error('Recaptcha validation failed');
          } else {
            throw e;
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form>
        <FormTextField
          name="email"
          label="Email"
          className={classes.textFields}
          autoFocus={true}
        />
        {recaptchaKey && (
          <ReCAPTCHA sitekey={recaptchaKey} onChange={setRecaptchaToken} />
        )}
        <FormSubmitButton
          className={classes.formButtons}
          variant="contained"
          color="primary"
          size="large"
        >
          Register
        </FormSubmitButton>
        <div className={classes.accountButtons}>
          <Button
            className={classes.accountButton}
            size="small"
            variant="outlined"
            href={`${appConstants.middlewareUrl}/oidc/Github/login`}
            startIcon={
              <SvgIcon viewBox="0 0 512 513">
                {/* Inline SVG in order to change hover color */}
                <path
                  d="M256.274 0.49752C114.919 0.49752 0.273926 118.019 0.273926 262.969C0.273926 378.938 73.6179 477.321 175.357 512.033C188.135 514.461 192.274 506.324 192.274 499.413V450.549C121.063 466.429 106.237 419.577 106.237 419.577C94.5886 389.24 77.7993 381.169 77.7993 381.169C54.5673 364.874 79.5699 365.224 79.5699 365.224C105.277 367.061 118.802 392.28 118.802 392.28C141.629 432.395 178.685 420.802 193.298 414.087C195.581 397.136 202.215 385.544 209.554 379.004C152.701 372.333 92.9246 349.826 92.9246 249.277C92.9246 220.602 102.93 197.198 119.293 178.825C116.647 172.198 107.879 145.491 121.789 109.358C121.789 109.358 143.293 102.315 192.21 136.261C212.626 130.443 234.514 127.534 256.274 127.424C278.034 127.534 299.943 130.443 320.402 136.261C369.277 102.315 390.738 109.358 390.738 109.358C404.669 145.513 395.901 172.22 393.255 178.825C409.682 197.198 419.602 220.624 419.602 249.277C419.602 350.088 359.719 372.289 302.717 378.785C311.89 386.922 320.274 402.889 320.274 427.386V499.413C320.274 506.39 324.37 514.592 337.362 512.011C439.015 477.256 512.274 378.894 512.274 262.969C512.274 118.019 397.65 0.49752 256.274 0.49752Z"
                  fill="black"
                />
              </SvgIcon>
            }
          >
            GitHub
          </Button>
          <Button
            className={classes.accountButton}
            size="small"
            variant="outlined"
            href={`${appConstants.middlewareUrl}/oidc/Google/login`}
            startIcon={
              <SvgIcon viewBox="0 0 502 513">
                <path
                  fill="#4285F4"
                  d="M501.609 262.258c0-16.854-1.494-32.854-4.054-48.427H256.489v96.213h138.026c-6.186 31.574-24.32 58.24-51.2 76.374v64h82.347c48.213-44.587 75.947-110.294 75.947-188.16Z"
                />
                <path
                  fill="#34A853"
                  d="M256.489 512.498c69.12 0 126.933-23.04 169.173-62.08l-82.347-64c-23.04 15.36-52.266 24.746-86.826 24.746-66.774 0-123.307-45.013-143.574-105.813H28.0085v65.92C70.0352 454.898 156.435 512.498 256.489 512.498Z"
                />
                <path
                  fill="#FBBC05"
                  d="M112.915 305.351c-5.333-15.36-8.106-31.787-8.106-48.853 0-17.067 2.986-33.494 8.106-48.854v-65.92H28.0086C10.5152 176.284.488586 215.111.488586 256.498c0 41.386 10.026614 80.213 27.520014 114.773l84.9064-65.92Z"
                />
                <path
                  fill="#EA4335"
                  d="M256.489 101.831c37.76 0 71.466 13.013 98.133 38.4l72.96-72.9601C383.422 25.8842 325.609.49752 256.489.49752 156.435.49752 70.0352 58.0976 28.0085 141.724l84.9065 65.92c20.267-60.8 76.8-105.813 143.574-105.813Z"
                />
              </SvgIcon>
            }
          >
            Google
          </Button>
        </div>
        <Link component={ReactRouterLink} color="secondary" to="/login">
          Return to Login
        </Link>
      </Form>
    </Formik>
  );
};

/********************
 * Step 2: Sign Up: *
 * ******************
 */
const useSignupStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
  })
);
interface SignupProps {
  nextStep: () => void;
  email: string;
}
const Signup = (props: SignupProps) => {
  const classes = useSignupStyles(props);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const recaptchaKey = String(appConstants.recaptchaClientKey);

  return (
    <Formik
      initialValues={{
        Code: '',
        Name: '',
        Username: '',
        Password: '',
        ConfirmPassword: '',
      }}
      validationSchema={yup.object({
        Code: yup.string().required('Verification Code is Required'),
        Name: yup
          .string()
          .required('Please enter your full name')
          .matches(
            /^[^~`@!#$%^&*+=[\];,/{}|\\":<>?]+$/,
            'Name contains invalid special characters'
          )
          .max(100, 'Name cannot exceed 100 characters.'),
        Username: yup
          .string()
          .required('Please choose a Username')
          .matches(
            /^[a-zA-Z0-9-_{}]+$/,
            'Username can only contain characters Aa-Zz 0-9 - _'
          )
          .max(40, 'Username cannot exceed 40 characters.'),
        Password: yup
          .string()
          .required(
            'Password must be at least eight characters long and include at least one letter and one number.'
          )
          .matches(
            /(?=.*?[a-zA-z])(?=.*?[0-9]).{8,}$/,
            'Password must be at least eight characters long and include at least one letter and one number.'
          ),
        ConfirmPassword: yup
          .string()
          .required('Confirm Password must match Password')
          .equalTo(yup.ref('Password'), 'Confirm Password must match Password'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (recaptchaKey && !recaptchaToken) {
          return Alert.error('Please verify that you are a human');
        }
        try {
          const verifyRequest = {
            Code: values.Code,
            Email: props.email,
            Name: values.Name,
            Username: values.Username,
            Password: values.Password,
            Recaptcha: { Token: recaptchaToken },
          };
          await Accounts.Verify(verifyRequest);
          props.nextStep();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form>
        <FormTextField
          name="Code"
          label="Verification Code"
          className={classes.textFields}
          autoFocus={true}
        />
        <FormTextField
          name="Name"
          label="Full Name"
          className={classes.textFields}
        />
        <FormTextField
          name="Username"
          label="Username"
          className={classes.textFields}
        />
        <FormPasswordField
          name="Password"
          label="Password"
          className={classes.textFields}
        />
        <FormPasswordField
          name="ConfirmPassword"
          label="Confirm Password"
          className={classes.textFields}
        />
        {recaptchaKey && (
          <ReCAPTCHA sitekey={recaptchaKey} onChange={setRecaptchaToken} />
        )}
        <FormSubmitButton
          className={classes.formButtons}
          variant="contained"
          color="secondary"
          size="large"
        >
          Register
        </FormSubmitButton>
      </Form>
    </Formik>
  );
};

/**************************
 * Step 3: Success Screen *
 * ************************
 */
const useSuccessStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
  })
);
interface SuccessProps {
  setToLogin: (toLogin: boolean) => void;
}
const Success = (props: SuccessProps) => {
  const { setToLogin } = props;
  const classes = useSuccessStyles(props);
  return (
    <Box
      className={classes.successWrapper}
      display="flex"
      flexDirection="column"
    >
      <Typography style={{ paddingTop: 0 }} variant="h2">
        Thanks for registering!
      </Typography>
      <div className={classes.successBtnGroup}>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setToLogin(true)}
        >
          Return to Login
        </Button>
        <Button
          variant="text"
          size="small"
          color="secondary"
          onClick={AuthService.backToDocs('/slack')}
        >
          Join us on Slack
        </Button>
      </div>
    </Box>
  );
};

const Register = () => {
  const [step, setStep] = useState(1);
  const [toLogin, setToLogin] = useState(false);
  const [email, setEmail] = useState('');

  const nextStep = () => {
    setStep(prevStep => prevStep + 1);
  };

  if (toLogin) {
    return <Redirect to="/login" />;
  }
  return (
    <AuthContainer>
      {step === 1 && (
        <GetVerificationCode nextStep={nextStep} setEmail={setEmail} />
      )}
      {step === 2 && <Signup nextStep={nextStep} email={email} />}
      {step === 3 && <Success setToLogin={setToLogin} />}
    </AuthContainer>
  );
};

export default Register;
