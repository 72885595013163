/* eslint-disable no-redeclare */
import { Reducer } from 'redux';
import {
  FormFieldsDictionary,
  RequestFormActions,
  SET_FIELD_VALUE,
  LOCK_PARAM,
  UNLOCK_PARAM,
  SET_BODY_VALUE,
  DEFAULT_REQUEST_FORM,
} from './requestForm.types';
import {
  SET_TAB_OPERATION,
  DEFAULT_TAB_ID,
  CREATE_TAB,
  DESTROY_TABS,
  COPY_TAB,
} from '../tab/tab.constants';
import { pickBy as _pickBy, isEmpty as _isEmpty } from 'lodash';

const form: Reducer<FormFieldsDictionary, any> = (
  state: FormFieldsDictionary = {
    [DEFAULT_TAB_ID]: DEFAULT_REQUEST_FORM,
  },
  action: RequestFormActions
) => {
  switch (action.type) {
    case SET_FIELD_VALUE:
      var tabId = action.payload.tabId;
      const { location, fieldName, value } = action.payload;
      return {
        ...state,
        [tabId]: {
          ...state[tabId],
          [location]: {
            ...state[tabId][location],
            [fieldName]: value,
          },
        },
      };
    case SET_BODY_VALUE:
      var tabId = action.payload.tabId;
      return {
        ...state,
        [tabId]: {
          ...state[tabId],
          body: action.payload.value,
        },
      };
    case DESTROY_TABS:
      var stateCopy = Object.assign({}, state);
      action.payload.tabIdsToDelete.forEach(tid => {
        delete stateCopy[tid];
      });
      if (_isEmpty(stateCopy)) {
        return {
          [DEFAULT_TAB_ID]: DEFAULT_REQUEST_FORM,
        };
      }
      return stateCopy;
    case CREATE_TAB:
      return {
        ...state,
        [action.payload.tab.id]: DEFAULT_REQUEST_FORM,
      };
    case COPY_TAB:
      var { newTabID, copiedTabID } = action.payload;
      return {
        ...state,
        [newTabID]: {
          ...state[copiedTabID],
        },
      };
    case SET_TAB_OPERATION:
      var tabId = action.payload.tabId;
      const op = action.payload.operation;
      const opParams = op.parameters ? op.parameters.map(p => p.name) : [];
      const paramInOp = (val, key) => opParams.includes(key);
      const lockedParams = _pickBy(state[tabId].locked, paramInOp);
      return {
        ...state,
        [tabId]: {
          params: { ...lockedParams },
          body: {},
          locked: { ...state[tabId].locked },
        },
      };
    case LOCK_PARAM:
      var tabId = action.payload.tabId;
      return {
        ...state,
        [tabId]: {
          ...state[tabId],
          locked: {
            ...state[tabId].locked,
            [action.payload.paramName]: action.payload.value,
          },
        },
      };
    case UNLOCK_PARAM:
      var tabId = action.payload.tabId;
      delete state[tabId].locked[action.payload.paramName];
      return {
        ...state,
        [tabId]: {
          ...state[tabId],
          locked: {
            ...state[tabId].locked,
          },
        },
      };
    default:
      return state;
  }
};

export default form;
