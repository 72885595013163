export const REQUEST_START = 'REQUEST_START';
export type REQUEST_START = typeof REQUEST_START;

export const REQUEST_END = 'REQUEST_END';
export type REQUEST_END = typeof REQUEST_END;

export const REQUEST_FAILURE = 'REQUEST_FAILURE';
export type REQUEST_FAILURE = typeof REQUEST_FAILURE;

export const REQUEST_CLEAR_ERROR = 'REQUEST_CLEAR_ERROR';
export type REQUEST_CLEAR_ERROR = typeof REQUEST_CLEAR_ERROR;
