import React, { useState } from 'react';
import { MessageSender } from 'ordercloud-javascript-sdk';
import { TextField } from '@material-ui/core';
import MessageTypeSelector from './MessageTypeSelector';
import MessageTemplateSelector from './MessageTemplateSelector';
import {
  determineMessageSenderType,
  CUSTOM_URL,
  CUSTOM_MANDRILL_ACCOUNT,
  MessageSenderTypeAttributes,
} from './constants';
import FieldSection from '../FieldSection';

interface EditMessageSenderProps {
  hasEditAccess: boolean;
  messageSenderUpdated: Partial<MessageSender>;
  handleChangeEvent: (field: string) => (event: any) => void;
  handleChangeValue: (field: string) => (newValue: any) => void;
  handleChangeObject: (newValue: any) => void;
  isCreatingNew: boolean;
}

function EditMessageSender(props: EditMessageSenderProps) {
  const {
    hasEditAccess,
    handleChangeEvent,
    handleChangeValue,
    handleChangeObject,
    messageSenderUpdated,
  } = props;

  const [dirtyFields, setDirtyFields] = useState({ URL: false, apiKey: false });

  const messageType = determineMessageSenderType(messageSenderUpdated);

  return (
    <React.Fragment>
      <MessageTypeSelector
        readOnly={!hasEditAccess}
        messageSenderUpdated={messageSenderUpdated}
        handleChangeObject={handleChangeObject}
      />
      <FieldSection
        headerText="General Information"
        infoText={MessageSenderTypeAttributes[messageType].InfoText}
      >
        <TextField
          fullWidth
          label="Message Name *"
          disabled={!hasEditAccess}
          InputProps={{
            readOnly: !hasEditAccess,
          }}
          value={(messageSenderUpdated && messageSenderUpdated.Name) || ''}
          margin="normal"
          onChange={handleChangeEvent('Name')}
          variant="outlined"
          error={!messageSenderUpdated.Name}
          helperText={!messageSenderUpdated.Name ? 'Must provide Name' : ' '}
        />
        {messageType === CUSTOM_URL && (
          <TextField
            fullWidth
            label="URL *"
            disabled={!hasEditAccess}
            InputProps={{
              readOnly: !hasEditAccess,
            }}
            value={(messageSenderUpdated && messageSenderUpdated.URL) || ''}
            margin="normal"
            onChange={e => {
              setDirtyFields({ ...dirtyFields, URL: true });
              handleChangeEvent('URL')(e);
            }}
            error={dirtyFields.URL && !messageSenderUpdated.URL}
            helperText={
              dirtyFields.URL && !messageSenderUpdated.URL
                ? 'Must provide URL'
                : ' '
            }
            variant="outlined"
          />
        )}
        {messageType === CUSTOM_MANDRILL_ACCOUNT && (
          <TextField
            fullWidth
            label="ApiKey *"
            disabled={!hasEditAccess}
            InputProps={{
              readOnly: !hasEditAccess,
            }}
            value={
              (messageSenderUpdated &&
                messageSenderUpdated.xp &&
                messageSenderUpdated.xp.ApiKey) ||
              ''
            }
            margin="normal"
            onChange={e => {
              setDirtyFields({ ...dirtyFields, apiKey: true });
              handleChangeValue('xp')({
                ...messageSenderUpdated.xp,
                ApiKey: e.target.value,
              });
            }}
            error={dirtyFields.apiKey && !messageSenderUpdated.xp.ApiKey}
            helperText={
              dirtyFields.apiKey && !messageSenderUpdated.xp.ApiKey
                ? 'Must provide API Key'
                : ' '
            }
            variant="outlined"
          />
        )}
      </FieldSection>
      <MessageTemplateSelector
        readOnly={!hasEditAccess}
        messageSenderUpdated={messageSenderUpdated}
        handleChangeObject={handleChangeObject}
      />
    </React.Fragment>
  );
}

export default EditMessageSender;
