export const REQUEST_NOT_MADE = 'REQUEST_NOT_MADE';
export type REQUEST_NOT_MADE = typeof REQUEST_NOT_MADE;

export const REQUEST_IN_PROGRESS = 'REQUEST_IN_PROGRESS';
export type REQUEST_IN_PROGRESS = typeof REQUEST_IN_PROGRESS;

export const REQUEST_SUCCESSFUL = 'REQUEST_SUCCESSFUL';
export type REQUEST_SUCCESSFUL = typeof REQUEST_SUCCESSFUL;

export const REQUEST_AUTH_FAILURE = 'REQUEST_AUTH_FAILURE';
export type REQUEST_AUTH_FAILURE = typeof REQUEST_AUTH_FAILURE;

export const REQUEST_FAILURE = 'REQUEST_FAILURE';
export type REQUEST_FAILURE = typeof REQUEST_FAILURE;

export const NO_SELLER_ORG_SELECTED = 'NO_SELLER_ORG_SELECTED';
export type NO_SELLER_ORG_SELECTED = typeof NO_SELLER_ORG_SELECTED;

export interface RoleComparison {
  AssignedRoles: string[];
  RequiredRoles: string[];
}

export type ResourceRequestStatus =
  | REQUEST_NOT_MADE
  | REQUEST_IN_PROGRESS
  | REQUEST_SUCCESSFUL
  | NO_SELLER_ORG_SELECTED
  | REQUEST_FAILURE
  | REQUEST_AUTH_FAILURE;

export interface SafelyGetResourceArgs {
  ocService: any;
  currentSellerOrgID: string;
  currentSellerToken: string;
  setRequestStatus: any;
  setAuthFailureData: any;
  page: number;
}

export async function SafelyGetResource(
  sellerOrgToken: string,
  args: SafelyGetResourceArgs
) {
  args.setRequestStatus(REQUEST_IN_PROGRESS);
  if (sellerOrgToken) {
    try {
      const resourceResponse = await args.ocService.List(
        { pageSize: 100, page: args?.page },
        { accessToken: sellerOrgToken }
      );
      args.setRequestStatus(REQUEST_SUCCESSFUL);
      return resourceResponse;
    } catch (ex) {
      if (
        (ex as any)?.response?.data?.Errors[0].ErrorCode ===
        'Auth.InsufficientRoles'
      ) {
        args.setRequestStatus(REQUEST_AUTH_FAILURE);
        args.setAuthFailureData((ex as any)?.response?.data.Errors[0].Data);
      } else {
        args.setRequestStatus(REQUEST_FAILURE);
      }
      return [];
    }
  } else {
    args.setRequestStatus(NO_SELLER_ORG_SELECTED);
    return [];
  }
}
