import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { forwardRef, Ref } from 'react';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trackVertical: {
      position: 'absolute',
      width: `${theme.spacing(1.2)}px !important`,
      right: 2,
      bottom: 2,
      top: 2,
    },
    thumbVertical: (props: any) => ({
      backgroundColor: props.thumbColor || 'rgba(0,0,0,0.5)',
      borderRadius: theme.spacing(0.6),
    }),
  })
);

interface CustomScrollbarsProps extends ScrollbarProps {
  thumbColor?: string;
  scrollbarRef?: (s: Scrollbars) => void;
}

const CustomScrollbars = forwardRef(
  (props: CustomScrollbarsProps, ref: Ref<Scrollbars>) => {
    const { thumbColor, scrollbarRef, ...rest } = props;
    const classes = useStyles({ thumbColor });
    const DEFAULT_SCROLLBAR_PROPS = {
      ref,
      height: '100%',
      renderThumbVertical: props => (
        <div {...props} className={classes.thumbVertical} />
      ),
      renderTrackVertical: props => (
        <div {...props} className={classes.trackVertical} />
      ),
    };
    return <Scrollbars {...DEFAULT_SCROLLBAR_PROPS} {...rest} />;
  }
);

export default CustomScrollbars;
