import {
  SetTabOperation,
  CreateTab,
  DestroyTabs,
  CopyTab,
} from '../tab/tab.types';

/**
 * * * * * * * *
 *    ACTIONS  *
 * * * * * * * *
 */
export interface SetFieldValue {
  type: SET_FIELD_VALUE;
  payload: {
    tabId: string;
    location: 'body' | 'params';
    fieldName: string;
    value: any;
  };
}

export interface SetBodyValue {
  type: SET_BODY_VALUE;
  payload: {
    tabId: string;
    value: any;
  };
}

export interface LockParam {
  type: LOCK_PARAM;
  payload: {
    tabId: string;
    paramName: string;
    value: string | number;
  };
}

export interface UnlockParam {
  type: UNLOCK_PARAM;
  payload: {
    tabId: string;
    paramName: string;
  };
}

export type RequestFormActions =
  | SetFieldValue
  | SetBodyValue
  | SetTabOperation
  | CopyTab
  | DestroyTabs
  | CreateTab
  | LockParam
  | UnlockParam;

/**
 * * * * * * * *
 *     DATA    *
 * * * * * * * *
 */
export interface FieldValues {
  [fieldName: string]: any;
}

export interface RequestForm {
  body: FieldValues;
  params: FieldValues;
  locked: FieldValues; // Not part of the request. Only route params can be locked.
}

export interface FormFieldsDictionary {
  [tabID: string]: RequestForm;
}

/**
 * * * * * * * *
 *  CONSTANTS  *
 * * * * * * * *
 */
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';
export type SET_FIELD_VALUE = typeof SET_FIELD_VALUE;

export const SET_BODY_VALUE = 'SET_BODY_VALUE';
export type SET_BODY_VALUE = typeof SET_BODY_VALUE;

export const LOCK_PARAM = 'LOCK_PARAM';
export type LOCK_PARAM = typeof LOCK_PARAM;

export const UNLOCK_PARAM = 'UNLOCK_PARAM';
export type UNLOCK_PARAM = typeof UNLOCK_PARAM;

export const DEFAULT_REQUEST_FORM = { body: {}, params: {}, locked: {} };
