import { CurrentUser } from '@ordercloud/portal-javascript-sdk';
import React, { useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import appConstants from '../../config/app.constants';
import { setDevCenterUser } from '../../redux/auth/auth.actions';
import rootReducer from '../../redux/index';
import {
  GetStateFromLocalStorage,
  SaveStateToLocalStorage,
} from '../../services/localStorage.helpers';

export const AUTH_TOKEN_KEY = `DevCenter.${appConstants.environment}.token`;
export const AUTH_EMAIL_KEY = `DevCenter.${appConstants.environment}.email`;

interface PortalContextProps {
  username: string;
  children: any;
}

const PortalContext = (props: PortalContextProps) => {
  const { username, children } = props;

  const store = useMemo(() => {
    const storageKey = `DevCenter.${appConstants.environment}.${username}.reduxStore`;
    const middlewares: any[] = [thunk, SaveStateToLocalStorage(storageKey)];
    const state = GetStateFromLocalStorage(storageKey);

    if (process.env.NODE_ENV === 'development') {
      const logger = createLogger({});
      middlewares.push(logger);
    }

    return createStore(rootReducer, state, applyMiddleware(...middlewares));
  }, [username]);

  const [storeUsername, setStoreUsername] = useState<string | undefined>(
    store.getState().devCenterUser.Username
  );

  useEffect(() => {
    setStoreUsername(store.getState().devCenterUser.Username);
  }, [store]);

  useEffect(() => {
    if (storeUsername !== username) {
      CurrentUser.Get().then(user => {
        setStoreUsername(user.Username);
        store.dispatch(setDevCenterUser(user));
      });
    }
  }, [store, storeUsername, username]);

  return <Provider store={store}>{children}</Provider>;
};

export default PortalContext;
