import { TableCell } from '@material-ui/core';
import React from 'react';
import { Webhook } from 'ordercloud-javascript-sdk';

export function WebhookTableHeaderRow() {
  return (
    <React.Fragment>
      <TableCell align="left">Target Url</TableCell>
      <TableCell align="left"># Client ID assignements</TableCell>
      <TableCell align="left"># Triggers</TableCell>
      <TableCell align="left"># Elevated Roles</TableCell>
    </React.Fragment>
  );
}

export function WebhookTableRow(webhook: Webhook) {
  return (
    <React.Fragment>
      <TableCell align="left">{webhook.Url}</TableCell>
      <TableCell align="left">
        {(webhook.ApiClientIDs && webhook.ApiClientIDs.length) || 0}
      </TableCell>
      <TableCell align="left">
        {(webhook.WebhookRoutes && webhook.WebhookRoutes.length) || 0}
      </TableCell>
      <TableCell align="left">
        {(webhook.ElevatedRoles && webhook.ElevatedRoles.length) || 0}
      </TableCell>
    </React.Fragment>
  );
}
