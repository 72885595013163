import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import {
  Accounts,
  AuthMethod,
  CurrentUser,
  User,
} from '@ordercloud/portal-javascript-sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FC } from 'react';
import { connect } from 'react-redux';
import { AppReducerState } from '../../redux';
import { updateUserEmail } from '../../redux/auth/authThunk.actions';
import ReCAPTCHA from 'react-google-recaptcha';
import appConstants from '../../config/app.constants';
import PasswordField from './PasswordField';
import { Alert } from '../App/AlertContainer';

interface StateProps {
  user: User;
}

interface DispatchProps {
  updateUserEmail: () => void;
}

interface ConfirmEmailProps {
  profileUpdate: boolean;
  open: boolean;
  loginAttempts: number;
  onLogout: () => void;
  onClose: () => void;
  setLoginAttempts: (attempts: number) => void;
}

type ConfirmEmailDialogProps = StateProps & DispatchProps & ConfirmEmailProps;

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(5px)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logout: {
    fontWeight: 'normal',
    marginRight: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  skipSendCode: {
    fontWeight: 'normal',
    alignSelf: 'center',
  },
}));

const MAX_ATTEMPTS = 2;
const AUTHMETHOD_GITHUB = 'Github';
const AUTHMETHOD_GOOGLE = 'Google';
const AUTHMETHOD_PASSWORD = 'Password';

type AUTHMETHOD_GITHUB = typeof AUTHMETHOD_GITHUB;
type AUTHMETHOD_GOOGLE = typeof AUTHMETHOD_GOOGLE;
type AUTHMETHOD_PASSWORD = typeof AUTHMETHOD_PASSWORD;
type AUTHMETHOD =
  | AUTHMETHOD_GITHUB
  | AUTHMETHOD_GOOGLE
  | AUTHMETHOD_PASSWORD
  | undefined;

const ConfirmEmailDialog: FC<ConfirmEmailDialogProps> = props => {
  const {
    user,
    profileUpdate,
    open,
    loginAttempts,
    updateUserEmail,
    onLogout,
    onClose,
    setLoginAttempts,
  } = props;
  const classes = useStyles();
  const [code, setCode] = useState<string>('');
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [change, setChange] = useState(profileUpdate ? true : false);
  const [fields, setFields] = useState({ email: '', password: '' });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [verified, setVerified] = useState<boolean | undefined>();
  const [attempts, setAttempts] = useState(0);
  const [accounts, setAccounts] = useState<AUTHMETHOD[]>([]);
  const recaptchaKey = String(appConstants.recaptchaClientKey);

  const listAccounts = useCallback(() => {
    CurrentUser.ListAccounts().then(response => {
      setAccounts((response as AuthMethod[]).map(m => m.Name));
    });
  }, []);

  useEffect(() => {
    if (!profileUpdate) {
      // if used at app level, determine show/hide based on user's email verification status
      setVerified(!!user && user.Verified);
      if (verified === false && !accounts.length) {
        listAccounts();
      }
    } else {
      if (!accounts.length) {
        listAccounts();
      }
    }
  }, [accounts.length, listAccounts, profileUpdate, user, verified]);

  const hasPwAuthMethod: boolean = useMemo(() => {
    return accounts.includes(AUTHMETHOD_PASSWORD);
  }, [accounts]);

  useEffect(() => {
    if (attempts === MAX_ATTEMPTS) {
      onLogout();
    }
  }, [attempts, onLogout]);

  const changes = useMemo(() => {
    const hasChanges = fields.email !== user.Email;
    const isValid = !!fields.email && (!!fields.password || !hasPwAuthMethod);
    return {
      exist: hasChanges,
      isValid,
    };
  }, [fields.email, fields.password, hasPwAuthMethod, user.Email]);

  const handleInputChange = (field: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setFields(f => ({ ...f, [field]: value }));
  };

  const handleClose = useCallback(() => {
    setCode('');
    setCodeSent(false);
    setChange(profileUpdate ? true : false);
    setFields({ email: '', password: '' });
    onClose();
  }, [onClose, profileUpdate]);

  const handleSubmitVerification = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      await Accounts.Confirm(
        { Code: code },
        {
          requestType: 'quiet',
        }
      )
        .then(() => {
          updateUserEmail();
          Alert.success('Your email was verified successfully');
          if (profileUpdate) {
            handleClose();
          }
        })
        .catch((e: any) => {
          if (e?.errors?.Errors[0].ErrorCode === 'Conflict') {
            Alert.error('Email is already in use');
          } else if (
            e?.errors?.Errors[0].ErrorCode === 'ConfirmationExpired' ||
            e?.errors?.Errors[0].ErrorCode === 'ConfirmationNotFound'
          ) {
            setAttempts(attempts + 1);
            Alert.error(e?.errors?.Errors[0].Message);
          } else {
            throw e;
          }
        });
    },
    [attempts, code, handleClose, profileUpdate, updateUserEmail]
  );

  const verifyCurrentEmail = useCallback(async () => {
    await CurrentUser.VerifyEmail({ Token: recaptchaToken }).then(() => {
      setCodeSent(true);
    });
  }, [recaptchaToken]);

  const handleSubmitEmailChange = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const body = {
        Password: fields.password,
        Email: fields.email,
        Recaptcha: { Token: recaptchaToken },
      };
      await CurrentUser.ChangeEmail(body, {
        requestType: 'quiet',
      })
        .then(() => {
          setCodeSent(true);
          setChange(false);
        })
        .catch((e: any) => {
          if (e?.errors?.Errors[0].ErrorCode === 'loginfailed') {
            // immediately log user out and end session
            setLoginAttempts(loginAttempts + 1);
            Alert.error('Incorrect credentials');
            onLogout();
          } else if (e?.errors?.Errors[0].ErrorCode === 'RecaptchaError') {
            Alert.error('Recaptcha validation failed');
          } else {
            throw e;
          }
        });
    },
    [
      fields.email,
      fields.password,
      loginAttempts,
      onLogout,
      recaptchaToken,
      setLoginAttempts,
    ]
  );

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      open={profileUpdate ? open : verified === false}
      onClose={profileUpdate ? handleClose : onClose}
      disableEscapeKeyDown
      aria-labelledby="confirm-email-dialog"
    >
      <Box className={classes.header}>
        <DialogTitle id="confirm-email-dialog">
          {profileUpdate ? 'Change Your Email' : 'Verify Your Email'}
        </DialogTitle>
        <Button
          variant="text"
          size="small"
          type="button"
          onClick={onLogout}
          className={classes.logout}
        >
          Sign Out
        </Button>
      </Box>
      <DialogContent>
        <Collapse in={!codeSent && !change && !profileUpdate}>
          <DialogContentText>
            OrderCloud Portal needs to re-verify your email address{' '}
            <span style={{ fontWeight: 'bold' }}>{user.Email}</span>. If this is
            not your preferred email, you have the option to update your email
            address below.
          </DialogContentText>
          {recaptchaKey && (
            <ReCAPTCHA sitekey={recaptchaKey} onChange={setRecaptchaToken} />
          )}
          <DialogActions>
            <Button
              size="small"
              variant="text"
              type="button"
              className={classes.skipSendCode}
              onClick={() => setCodeSent(true)}
            >
              I already have a code
            </Button>
            <div className={classes.spacer} />
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={verifyCurrentEmail}
            >
              Send Confirmation
            </Button>
            <Button
              type="button"
              onClick={() => setChange(true)}
              variant="outlined"
              color="primary"
            >
              Change My Email
            </Button>
          </DialogActions>
        </Collapse>

        <Collapse in={!codeSent && (change || profileUpdate)}>
          <DialogContentText style={{ marginTop: 16 }}>
            A verification code will be sent to the new email to confirm your
            identity.
          </DialogContentText>
          <form onSubmit={handleSubmitEmailChange}>
            {hasPwAuthMethod && (
              <PasswordField
                fullWidth
                name="password"
                label="Current Password"
                margin="normal"
                variant="outlined"
                value={fields.password || ''}
                onChange={handleInputChange('password')}
                required
              ></PasswordField>
            )}
            <TextField
              fullWidth
              type="email"
              margin="normal"
              label="Email Address"
              value={fields.email || ''}
              variant="outlined"
              onChange={handleInputChange('email')}
              error={fields.email === user.Email}
              helperText={
                fields.email === user.Email
                  ? 'This is your current email address.'
                  : 'The provided email cannot already be in use in the OrderCloud Portal.'
              }
              required
            />
            {recaptchaKey && (
              <ReCAPTCHA sitekey={recaptchaKey} onChange={setRecaptchaToken} />
            )}
            <DialogActions>
              <Button
                size="small"
                variant="text"
                type="button"
                className={classes.skipSendCode}
                onClick={() => setCodeSent(true)}
              >
                I already have a code
              </Button>
              <div className={classes.spacer} />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!changes.exist || (changes.exist && !changes.isValid)}
              >
                Send Confirmation
              </Button>
              <Button
                style={{ marginLeft: 8 }}
                type="button"
                variant="outlined"
                onClick={() =>
                  profileUpdate ? handleClose() : setChange(false)
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Collapse>
        <Collapse in={codeSent}>
          <DialogContentText style={{ marginTop: 16 }}>
            Enter the code that was sent to your email{' '}
            <span style={{ fontWeight: 'bold' }}>
              {!!fields?.email ? fields.email : !change ? user.Email : ''}
            </span>{' '}
            below:
          </DialogContentText>
          <Box>
            <form onSubmit={handleSubmitVerification}>
              <TextField
                autoFocus
                id="code"
                label="Confirmation Code"
                type="text"
                fullWidth
                variant="outlined"
                value={code}
                onChange={e => setCode(e.target.value)}
                required
              />
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!code}
                >
                  Confirm
                </Button>
                <Button
                  style={{ marginLeft: 8 }}
                  type="button"
                  variant="outlined"
                  onClick={() =>
                    profileUpdate ? handleClose() : setCodeSent(false)
                  }
                >
                  Cancel
                </Button>
              </DialogActions>
            </form>
          </Box>
        </Collapse>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: AppReducerState) => {
  return {
    user: state.devCenterUser,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateUserEmail: () => {
      return dispatch(updateUserEmail());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailDialog);
