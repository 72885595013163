import { combineReducers, Reducer } from 'redux';
import {
  InvitationAction,
  MarketplaceReducerState,
  TeamReducerState,
  MarketplaceTeamReducerState,
  SET_MARKETPLACE_INVITATIONS,
  SET_MARKETPLACE_TEAM_INVITATIONS,
  SET_TEAM_INVITATIONS,
  UPDATE_MARKETPLACE_INVITATIONS,
  UPDATE_MARKETPLACE_TEAM_INVITATIONS,
  UPDATE_TEAM_INVITATIONS,
} from './invitations.types';

const marketplaceInvitations: Reducer<
  MarketplaceReducerState,
  InvitationAction
> = (state: MarketplaceReducerState = [], action: InvitationAction) => {
  switch (action.type) {
    case SET_MARKETPLACE_INVITATIONS:
      return action.payload.invitations;
    case UPDATE_MARKETPLACE_INVITATIONS:
      return state.filter(item => item.Id !== action.payload.orgId);
    default:
      return state;
  }
};

const teamInvitations: Reducer<TeamReducerState, InvitationAction> = (
  state: TeamReducerState = [],
  action: InvitationAction
) => {
  switch (action.type) {
    case SET_TEAM_INVITATIONS:
      return action.payload.invitations;
    case UPDATE_TEAM_INVITATIONS:
      return state.filter(item => item.Id !== action.payload.teamId);
    default:
      return state;
  }
};

const marketplaceTeamInvitations: Reducer<
  MarketplaceTeamReducerState,
  InvitationAction
> = (state: MarketplaceTeamReducerState = [], action: InvitationAction) => {
  switch (action.type) {
    case SET_MARKETPLACE_TEAM_INVITATIONS:
      return action.payload.invitations;
    case UPDATE_MARKETPLACE_TEAM_INVITATIONS:
      return state.filter(
        item =>
          item.Org.Id !== action.payload.orgId &&
          item.Team.Id !== action.payload.teamId
      );
    default:
      return state;
  }
};

export default combineReducers({
  marketplaceInvitations,
  marketplaceTeamInvitations,
  teamInvitations,
});
