import { RequestForm } from '../requestForm/requestForm.types';
import { AxiosResponse } from 'axios';

export interface ConsoleResponse extends AxiosResponse {
  id: string;
  tabId: string;
  token: string;
  form: RequestForm;
  duration: number;
  operation: any;
}
export const ADD_REQUEST = 'ADD_REQUEST';
export type ADD_REQUEST = typeof ADD_REQUEST;

export interface MakeConsoleRequest {
  type: ADD_REQUEST;
  payload: ConsoleResponse;
}

export type ConsoleRequestsAction = MakeConsoleRequest;
