import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import sharedStyles from '../Shared/SharedStyles';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormSubmitButton from '../Shared/FormSubmitButton';
import { Link } from '@material-ui/core';
import { Link as ReactRouterLink } from 'react-router-dom';
import AuthContainer from './AuthContainer';
import FormPasswordField from '../Shared/FormPasswordField';
import { parse as parseQueryString } from 'query-string';
import { RouteComponentProps } from 'react-router';
import { Accounts } from '@ordercloud/portal-javascript-sdk';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
  })
);

interface ResetPasswordProps extends RouteComponentProps {}
const ResetPassword = (props: ResetPasswordProps) => {
  const classes = useStyles(props);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<boolean>(false);

  if (success) {
    return (
      <AuthContainer>
        <h2>Success!</h2>
        <p>
          <Link component={ReactRouterLink} to="/login">
            Log in
          </Link>{' '}
          with your new password.
        </p>
      </AuthContainer>
    );
  }
  return (
    <AuthContainer>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={yup.object({
          password: yup
            .string()
            .required(
              'Password must be at least eight characters long and include at least one letter and one number.'
            )
            .matches(
              /(?=.*?[a-zA-z])(?=.*?[0-9]).{8,}$/,
              'Password must be at least eight characters long and include at least one letter and one number.'
            ),
          confirmPassword: yup
            .string()
            .required('Confirm Password must match Password')
            .equalTo(
              yup.ref('password'),
              'Confirm Password must match password'
            ),
        })}
        onSubmit={async ({ password }, { setSubmitting }) => {
          try {
            const q = parseQueryString(props.location.search);
            const code = q.code as string;
            const email = q.email as string;
            await Accounts.ResetPassword({
              Code: code,
              Email: email,
              Password: password,
            });
            setSubmitting(false);
            setSuccess(true);
          } catch (e) {
            setError(true);
            setSubmitting(false);
          }
        }}
      >
        <Form>
          <p>Please reset your password</p>
          <FormPasswordField
            name="password"
            label="Password"
            className={classes.textFields}
            autoFocus={true}
          />
          <FormPasswordField
            name="confirmPassword"
            label="Confirm Password"
            className={classes.textFields}
          />
          <FormSubmitButton
            className={classes.formButtons}
            variant="contained"
            color="secondary"
            size="large"
          >
            Set New Password
          </FormSubmitButton>
        </Form>
      </Formik>
      {error && (
        <Link component={ReactRouterLink} to="/forgot-password">
          Resend Verification Code
        </Link>
      )}
    </AuthContainer>
  );
};

export default ResetPassword;
