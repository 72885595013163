import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { AppReducerState } from '../../../../../redux';
import { Typography, ListItemText, ListItem, List } from '@material-ui/core';
import { maroon } from '../../../../../themes/ocPalette.constants';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { teal } from '@material-ui/core/colors';
import { emptyUser } from '../../../../../redux/contextSelections/contextSelections.constants';
import UserAccess from '../../../ContextSelector/UserAccess.';
import { ApiClient } from 'ordercloud-javascript-sdk';
import FieldSection from '../FieldSection';
import {
  OrderCloudUser,
  RequiredDeep,
  ApiClients,
} from '@ordercloud/portal-javascript-sdk';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemRoot: {
      padding: theme.spacing(1, 2),
      color: 'rgba(0, 0, 0, 0.87)',
    },
    listItemRootActive: {
      padding: theme.spacing(1, 2),
      color: 'rgba(0, 0, 0, 0.87)',
      border: `2px ${teal[200]} dashed`,
      borderRadius: '4px',
    },
    selectedUser: {
      padding: theme.spacing(1, 2),
      color: 'rgba(0, 0, 0, 0.87)',
      border: `2px ${teal[200]} solid`,
      borderRadius: '4px',
      marginBottom: theme.spacing(4),
    },
    container: {
      position: 'relative',
    },
    secondaryText: {
      fontFamily: 'monospace',
      color: maroon[500],
    },
    chip: {
      marginRight: '2px',
      marginTop: '2px',
    },
    chipContainer: {
      height: '120px !important',
      border: `2px dotted ${teal[200]}`,
      borderRadius: theme.spacing(2),
      padding: '3px',
    },
    noUserSelected: {
      marginTop: theme.spacing(2),
      color: theme.palette.error.light,
    },
  })
);

interface DefaultContextUserProps {
  readOnly?: boolean;
  handleChangeDefaultContextUser: (newValue: string) => void;
  currentDefaultContextUsername?: string;
  apiClientUpdated: Partial<ApiClient>;
  isCreatingNew: boolean;
  sellerOrgID: string;
}

function DefaultContextUser(props: DefaultContextUserProps) {
  const classes = useStyles();
  const {
    sellerOrgID,
    apiClientUpdated,
    handleChangeDefaultContextUser,
    currentDefaultContextUsername,
    isCreatingNew,
    readOnly,
  } = props;
  const [usersWithAccess, setUsersWithAccess] = useState([emptyUser]);
  const [currentUserSelection, setCurrentUserSelection] = useState<
    RequiredDeep<OrderCloudUser>
  >(emptyUser);

  const getUsers = useCallback(
    async (event?: any) => {
      if (!apiClientUpdated.ID) return;
      const userResponse = await ApiClients.ListUsers(
        sellerOrgID,
        apiClientUpdated.ID,
        {
          search: (event && event.target.value) || '',
          page: 1,
        }
      );
      setUsersWithAccess(userResponse.Items);
    },
    [sellerOrgID, apiClientUpdated]
  );

  const updateDefaultContextUser = (user: RequiredDeep<OrderCloudUser>) => {
    setCurrentUserSelection(user);
    handleChangeDefaultContextUser(user.Username);
  };

  useEffect(() => {
    if (!isCreatingNew) {
      getUsers();
    }
  }, [apiClientUpdated.ID, getUsers, isCreatingNew]);

  useEffect(() => {
    if (!apiClientUpdated.ID) return;
    const getCurrentUser = async () => {
      if (!apiClientUpdated.ID) return;
      const userList = await ApiClients.ListUsers(
        sellerOrgID,
        apiClientUpdated.ID,
        {
          search: apiClientUpdated.DefaultContextUserName,
        }
      );
      const user =
        userList && userList.Meta && userList.Meta.TotalCount > 0
          ? userList.Items[0]
          : null;
      if (user && apiClientUpdated.DefaultContextUserName === user.Username) {
        setCurrentUserSelection(user);
      }
    };
    if (!apiClientUpdated.DefaultContextUserName) {
      setCurrentUserSelection(emptyUser);
    } else {
      getCurrentUser();
    }
  }, [
    apiClientUpdated.DefaultContextUserName,
    apiClientUpdated.ID,
    sellerOrgID,
  ]);

  // ensures that the wrong user is never momentarily show in the UI
  const showSelectedUser =
    apiClientUpdated.DefaultContextUserName &&
    currentUserSelection.Username === apiClientUpdated.DefaultContextUserName;
  return (
    <FieldSection
      headerText="Default Context User"
      infoText="Optionally set a user that will be used when authenticating with a
    Client Credentials grant type flow. This grant type is often used for
    anonymous browsing on buyer applications and authentication on server
    integration layers."
    >
      {isCreatingNew ? (
        <List disablePadding>
          <ListItem
            button
            key={'currentSelected'}
            className={classes.selectedUser}
          >
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  title="You will be able to select a default context user after the API client
              has been created and the list of potential users is populated."
                >
                  You will be able to select a default context user after the
                  API client has been created and the list of potential users is
                  populated.
                </Typography>
              }
            />
          </ListItem>
        </List>
      ) : (
        <List disablePadding>
          <ListItem key={'currentSelected'} className={classes.selectedUser}>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  noWrap
                  title={
                    showSelectedUser
                      ? currentUserSelection.Username
                      : 'No default context user set'
                  }
                >
                  {showSelectedUser
                    ? currentUserSelection.Username
                    : 'No default context user set'}
                </Typography>
              }
              secondary={
                <Typography
                  noWrap
                  variant="body2"
                  className={classes.secondaryText}
                >
                  {showSelectedUser
                    ? currentUserSelection.UserId
                    : readOnly
                    ? ''
                    : 'Select a user below'}
                </Typography>
              }
            />
            {showSelectedUser && <UserAccess {...currentUserSelection} />}
          </ListItem>
          {!readOnly && (
            <React.Fragment>
              <TextField
                fullWidth
                label={`Search for users with access to this API client`}
                onChange={e => getUsers(e)}
                margin="normal"
                variant="outlined"
              />
              <Scrollbars
                renderTrackHorizontal={props => (
                  <div
                    {...props}
                    style={{ display: 'none' }}
                    className="track-horizontal"
                  />
                )}
                style={{ height: 275 }}
              >
                <List>
                  {usersWithAccess.map((user, index) => (
                    <ListItem
                      button
                      key={index}
                      className={
                        currentDefaultContextUsername === user.Username
                          ? classes.listItemRootActive
                          : classes.listItemRoot
                      }
                      onClick={() => {
                        updateDefaultContextUser(user);
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            noWrap
                            title={user.Username}
                          >
                            {user.Username}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            noWrap
                            variant="body2"
                            className={classes.secondaryText}
                          >
                            {user.UserId}
                          </Typography>
                        }
                      />
                      <UserAccess {...user} />
                    </ListItem>
                  ))}
                </List>
              </Scrollbars>
            </React.Fragment>
          )}
        </List>
      )}
    </FieldSection>
  );
}

function mapStateToProps(state: AppReducerState) {
  const sellerOrgID =
    state.contextSelections.contextChoices[state.tabs.tabsMeta.activeId].saved
      .SELLER_ORG.Id;
  return {
    sellerOrgID,
  };
}

export default connect(mapStateToProps)(DefaultContextUser);
