import React from 'react';
import PrettyResourceNavigation from '../PrettyResourceNavigation';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  MessageSender,
  MessageSenders as ocMessageSenders,
} from 'ordercloud-javascript-sdk';
import MessageSenderTableRow, {
  MessageSenderTableHeaderRow,
} from './MessageSenderTableRows';
import EditMessageSender from './EditMessageSender';
import { MESSAGE_SENDERS } from '../PrettyResourceNavigation/constants';
import {
  newMessageSender,
  determineMessageSenderType,
  CUSTOM_URL,
  CUSTOM_MANDRILL_ACCOUNT,
  DEFAULT,
} from './constants';

const isValidMessageSender = (MessageSender: Partial<MessageSender>) => {
  const messageType = determineMessageSenderType(MessageSender);
  if (MessageSender) {
    switch (messageType) {
      case CUSTOM_MANDRILL_ACCOUNT:
        return Boolean(
          MessageSender.Name &&
            MessageSender.xp &&
            MessageSender.xp.ApiKey &&
            MessageSender.MessageTypes &&
            MessageSender.MessageTypes.length >= 1
        );
      case CUSTOM_URL:
        return Boolean(
          MessageSender.Name &&
            MessageSender.URL &&
            MessageSender.MessageTypes &&
            MessageSender.MessageTypes.length >= 1
        );
      case DEFAULT:
        return Boolean(
          MessageSender.Name &&
            MessageSender.MessageTypes &&
            MessageSender.MessageTypes.length >= 1
        );
      default:
        return false;
    }
  }
  return false;
};

function MessageSenders() {
  return (
    <PrettyResourceNavigation
      editRole="MessageSenderAdmin"
      headerComponent={MessageSenderTableHeaderRow}
      tableRowComponent={MessageSenderTableRow}
      isValidResource={isValidMessageSender}
      renderEditComponent={(
        hasEditAccess,
        updatedResource,
        resources,
        handleChangeEvent,
        handleChangeValue,
        handleChangeObject,
        isCreatingNew
      ) => (
        <EditMessageSender
          hasEditAccess={hasEditAccess}
          handleChangeEvent={handleChangeEvent}
          messageSenderUpdated={updatedResource}
          handleChangeObject={handleChangeObject}
          handleChangeValue={handleChangeValue}
          isCreatingNew={isCreatingNew}
        />
      )}
      resourceName="Message Senders"
      emptyResource={newMessageSender}
      ocService={ocMessageSenders}
      numberOfTableColumns={6}
      resourceType={MESSAGE_SENDERS}
    />
  );
}

export default MessageSenders;
