import {
  Button,
  TextField,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import sharedStyles from '../Shared/SharedStyles';
import AuthContainer from './AuthContainer';
import ProgressLoader from '../Shared/ProgressLoader';
import { UnauthenticatedComponentProps } from '../App/Routing/UnauthenticatedRoutes';
import { CurrentUser, Tokens } from '@ordercloud/portal-javascript-sdk';
import { sc_teal } from '../../themes/sitecorePalette.constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    forgotPassword: {
      display: 'block',
      textAlign: 'right',
      fontSize: '.8rem',
      marginBottom: theme.spacing(3),
    },
    alertTeal: {
      padding: theme.spacing(1),
      textAlign: 'left',
      backgroundColor: sc_teal[100],
      color: sc_teal[800],
      marginBottom: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      '& .MuiAlert-icon': {
        color: sc_teal[500],
      },
    },
  })
);

const Setup = (props: UnauthenticatedComponentProps) => {
  const { location, history, onAccountLogin } = props;
  const classes = useStyles(props);
  const [fields, setFields] = useState({
    Email: '',
    Username: '',
    Name: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const token = params.get('t');
      const newuser = params.get('newuser');
      const errorCode = params.get('errorcode');
      const message = params.get('message');
      if (errorCode) {
        history.push({ pathname: '/login', state: { errorCode, message } });
      } else if (newuser && newuser === 'True') {
        history.push({ pathname: '/setup', state: { token } });
      } else if (token) {
        console.log('hit newuser = false path');
        onAccountLogin(token);
        history.push({ pathname: '/' });
      } else {
        history.push({ pathname: '/login' });
      }
    }
    // } else {
    //   Initialize('Production')
    //     .then(() => Initialize('Staging'))
    //     .then(() => Initialize('Sandbox'));
    // }
  }, [location.search, history, onAccountLogin]);

  useEffect(() => {
    if (location.state && location.state.token) {
      Tokens.SetRefreshToken(location.state.token);
      CurrentUser.Get().then(currentUser => {
        setFields({
          Email: currentUser.Email,
          Username: currentUser.Username,
          Name: currentUser.Name,
        });
      });
    }
  }, [location.state]);

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    CurrentUser.Save(fields)
      .then(updated => {
        setLoading(false);
        const refreshToken = Tokens.GetRefreshToken();
        if (refreshToken) {
          onAccountLogin(refreshToken);
        }
        history.push('/');
      })
      .catch(ex => {
        setLoading(false);
        console.log(ex);
      });
  };

  const handleInputChange = (field: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setFields(fields => ({ ...fields, [field]: value }));
  };

  return (
    <AuthContainer>
      <form onSubmit={handleSubmit}>
        <Alert severity="info" className={classes.alertTeal}>
          <AlertTitle>Finish setting up your account</AlertTitle>
          We recommend choosing a new Username as this is publicly visible to
          all Portal Users.
        </Alert>
        <TextField
          className={classes.textFields}
          type="text"
          label="Name"
          value={fields.Name}
          required={true}
          onChange={handleInputChange('Name')}
        />
        <TextField
          className={classes.textFields}
          type="email"
          label="Email"
          value={fields.Email}
          required={true}
          autoFocus={true}
          onChange={handleInputChange('Email')}
        />
        <TextField
          className={classes.textFields}
          type="text"
          label="Username"
          value={fields.Username}
          required={true}
          onChange={handleInputChange('Username')}
        />
        <ProgressLoader loading={loading}>
          <Button
            className={classes.formButtons}
            variant="outlined"
            color="secondary"
            type="submit"
            size="large"
          >
            Continue
          </Button>
        </ProgressLoader>
      </form>
    </AuthContainer>
  );
};

export default Setup;
