import { User } from '@ordercloud/portal-javascript-sdk';

// STORES
export type DevCenterUserReducerState = User;

// ACTIONS
export interface SetDevCenterUserAction {
  type: SET_DEVCENTER_USER;
  payload: { devCenterUser: User };
}

export interface UpdateDevcenterUserAction {
  type: UPDATE_DEVCENTER_USER;
  payload: {
    devCenterUser: User;
  };
}

export interface UpdateDevcenterEmailAction {
  type: SET_DEVCENTER_USER_EMAIL;
  payload: null;
}

export interface RemoveDevCenterUserAction {
  type: REMOVE_DEVCENTER_USER;
  payload: null;
}

export type DevCenterUserAction =
  | SetDevCenterUserAction
  | UpdateDevcenterEmailAction
  | RemoveDevCenterUserAction;

// DATA

export type DevCenterToken = string;

export interface DevCenterAuthResponse {
  access_token: DevCenterToken;
  expires_in: number;
  token_type: string;
}
export interface DevCenterUser {
  CompanyName?: string;
  Email?: string;
  FirstName?: string;
  ID?: number;
  LastName?: string;
  MongoDBHash?: string;
  Password?: string;
  SlackLastRead?: string;
  StripeCustomerID?: string;
  Username?: string;
  SandboxMarketplaceLimit?: number;
}

export interface PasswordResetRequest {
  Password: string;
}

// CONSTANTS
export const SET_DEVCENTER_USER = 'SET_DEVCENTER_USER';
export type SET_DEVCENTER_USER = typeof SET_DEVCENTER_USER;

export const UPDATE_DEVCENTER_USER = 'UPDATE_DEVCENTER_USER';
export type UPDATE_DEVCENTER_USER = typeof UPDATE_DEVCENTER_USER;

export const SET_DEVCENTER_USER_EMAIL = 'SET_DEVCENTER_USER_EMAIL';
export type SET_DEVCENTER_USER_EMAIL = typeof SET_DEVCENTER_USER_EMAIL;

export const REMOVE_DEVCENTER_USER = 'REMOVE_DEVCENTER_USER';
export type REMOVE_DEVCENTER_USER = typeof REMOVE_DEVCENTER_USER;

export const ACCEPT_TERMS_AND_CONDITIONS = 'ACCEPT_TERMS_AND_CONDITIONS';
export type ACCEPT_TERMS_AND_CONDITIONS = typeof ACCEPT_TERMS_AND_CONDITIONS;
