import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  Button,
  TextField,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import pluralize from 'pluralize';
import BaseInput from './BaseInput';
import cloneDeep from 'lodash/cloneDeep';

const styles = (theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    textField: {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    addButton: {
      flex: 0,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    removeButton: {
      marginTop: theme.spacing(1),
    },
  });

export interface KeyValuePair {
  key: string;
  value: string;
}

export const emptyKeyValue = {
  key: '',
  value: '',
};

class KeyValueInput extends BaseInput {
  public addItem = () => {
    if (this.state && this.state.value) {
      this.updateValue([...this.state.value, emptyKeyValue]);
    } else {
      this.updateValue([emptyKeyValue]);
    }
  };

  public removeItem = (index: number) => (event: React.MouseEvent) => {
    const stateCopy = cloneDeep(this.state.value);
    stateCopy.splice(index, 1);
    this.updateValue(stateCopy);
  };

  public handleKeyValueChange = (field: string, index: number) => (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const stateCopy = cloneDeep(this.state.value);
    stateCopy[index][field] = event.target.value;
    this.updateValue(stateCopy);
  };

  public render() {
    const classes = this.props.classes || {};
    return (
      <React.Fragment>
        {this.state &&
          this.state.value &&
          this.state.value.map((keyValuePair: KeyValuePair, index: number) => (
            <div key={index} className={classes.row}>
              <TextField
                autoFocus={Boolean(
                  this.state.value &&
                    index === Object.keys(this.state.value).length - 1
                )}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                label="Filter Key"
                value={keyValuePair.key}
                onChange={this.handleKeyValueChange('key', index)}
              />
              <TextField
                autoFocus={Boolean(
                  this.state.value &&
                    index === Object.keys(this.state.value).length - 1
                )}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                label="Filter Value"
                value={keyValuePair.value}
                onChange={this.handleKeyValueChange('value', index)}
              />

              <IconButton
                className={classes.removeButton}
                onClick={this.removeItem(index)}
              >
                <Close color="error" />
              </IconButton>
            </div>
          ))}
        <Button
          className={classes.addButton}
          fullWidth
          color="primary"
          variant="outlined"
          disabled={
            this.state.value && Object.keys(this.state.value).includes('')
          }
          onClick={this.addItem}
        >
          Add {pluralize.singular(this.getLabel(this.props.name))}
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(KeyValueInput);
