import { useCallback, useEffect, useMemo, useState } from 'react';
import SwaggerParser from 'swagger-parser';
import { OpenAPI, OpenAPIV3 } from 'openapi-types';
import { groupBy, sortBy, keyBy, mapValues, values, flatten } from 'lodash';
import { initializeIndex } from '../../../services/lunr.helper';
import appConstants from '../../../config/app.constants';

const localStoragePrefix = 'OcOpenApi.';

export interface ApiSection extends OpenAPIV3.TagObject {
  'x-id': string;
}

export interface ApiResource extends OpenAPIV3.TagObject {
  'x-section-id': string;
}

export interface IMeSubsections {
  name: string;
  ['x-section-id']: string;
  paths: string[];
}

export interface ICHONEMeSubsections {
  name: string;
  xSectionId: string;
  paths: string;
}

const getMeSubsections = async (): Promise<IMeSubsections[]> => {
  const query = `{
    allMeSubsections(first: 500, orderBy: NAME_ASC) {
      results {
        name
        xSectionId
        paths
      }
    }
  }`;

  const results: {
    data: { allMeSubsections: { results: Partial<ICHONEMeSubsections>[] } };
  } = await (
    await fetch(appConstants.choneDeliveryApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-GQL-Token': appConstants.choneDeliveryApiKey,
      },
      body: JSON.stringify({ query }),
    })
  ).json();
  return results?.data?.allMeSubsections?.results.map(r => ({
    name: r?.name!,
    'x-section-id': r?.xSectionId!,
    paths: JSON.parse(JSON.stringify(r?.paths)),
  }));
};

const buildOperations = (
  spec?: OpenAPI.Document,
  meSubsections?: IMeSubsections[]
) => {
  if (!spec || !meSubsections) return [];
  return flatten(
    values(
      mapValues(spec.paths, (ops, path) => {
        return values(
          mapValues(ops, (o, verb) => {
            const tags =
              o.tags[0] === 'Me'
                ? [GetSubSectionName(path, meSubsections)]
                : o.tags;
            return { ...o, verb, path, tags };
          })
        );
      })
    )
  );
};

const buildResources = (
  spec?: OpenAPI.Document,
  meSubsections?: IMeSubsections[]
): ApiResource[] => {
  if (!spec || !meSubsections) return [];
  return spec.tags
    ? (spec.tags
        .filter(tag => tag['x-section-id'])
        .concat(GetSubsectionsToAdd(meSubsections)) as ApiResource[])
    : [];
};

const groupOperationsByResource = operations => {
  return mapValues(
    groupBy(operations, o => {
      return o.tags[0];
    }),
    group => sortBy(group, o => o.path)
  );
};

const useApiSpec = (baseUrl: string) => {
  const [spec, setSpec] = useState<OpenAPIV3.Document | undefined>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(
        `${localStoragePrefix}${baseUrl}`
      );
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : undefined;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return undefined;
    }
  });

  const [meSubsections, setMeSubsections] = useState<
    IMeSubsections[] | undefined
  >(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem('MeSubsections');
      return item ? JSON.parse(item) : undefined;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return undefined;
    }
  });

  const retrieveSpec = useCallback(async (url: string) => {
    const result = await SwaggerParser.dereference(`${url}/v1/openapi/v3`);
    const v3doc = result as OpenAPIV3.Document;
    if (v3doc.servers) {
      v3doc.servers[0].url = `${url}/v1`;
    }
    // Clear out swagger specs in localStorage to prevent capacity errors
    const keys = Object.keys(window.localStorage);
    keys
      .filter(k => k.includes('OcOpenApi'))
      .map(i => window.localStorage.removeItem(i));
    window.localStorage.setItem(
      `${localStoragePrefix}${url}`,
      JSON.stringify(v3doc)
    );
    setSpec(v3doc);
  }, []);

  const retrieveMeSubsections = useCallback(async () => {
    const result = await getMeSubsections();
    // Clear out swagger specs in localStorage to prevent capacity errors
    const keys = Object.keys(window.localStorage);
    keys
      .filter(k => k.includes('MeSubsections'))
      .map(i => window.localStorage.removeItem(i));
    window.localStorage.setItem(`MeSubsections`, JSON.stringify(result));
    setMeSubsections(result);
  }, []);

  const validateSpecVersion = useCallback(
    async (url: string, version: string) => {
      const result = await fetch(`${url}/env`);
      const resultJson = await result.json();
      if (resultJson.BuildNumber && resultJson.BuildNumber !== version) {
        console.log(
          `Current spec (v${version}) is outdated, updating to ${resultJson.BuildNumber}`
        );
        retrieveSpec(url);
      }
    },
    [retrieveSpec]
  );

  useEffect(() => {
    if (
      spec &&
      spec.info &&
      spec.info.version &&
      spec.info.version.split('.').length === 4 &&
      spec.servers &&
      spec.servers[0].url === `${baseUrl}/v1`
    ) {
      validateSpecVersion(baseUrl, spec.info.version);
    }
  }, [spec, baseUrl, validateSpecVersion]);

  useEffect(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(
        `${localStoragePrefix}${baseUrl}`
      );
      // Parse stored json or if none return initialValue
      item ? setSpec(JSON.parse(item)) : retrieveSpec(baseUrl);
    } catch (error) {
      // If error also return initialValue
      retrieveSpec(baseUrl);
    }
  }, [baseUrl, retrieveSpec]);

  useEffect(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(`MeSubsections`);
      // Parse stored json or if none return initialValue
      item ? setMeSubsections(JSON.parse(item)) : retrieveMeSubsections();
    } catch (error) {
      // If error also return initialValue
      retrieveMeSubsections();
    }
  }, [retrieveMeSubsections]);

  const result = useMemo(() => {
    const operations = buildOperations(spec, meSubsections);
    const resources = buildResources(spec, meSubsections);
    const operationsByResource = groupOperationsByResource(operations);
    const hookableOperationsByResource = groupOperationsByResource(
      operations.filter(o => o.verb !== 'get')
    );
    const hookableResources = resources.filter(r =>
      Object.keys(hookableOperationsByResource).includes(r.name)
    );
    const operationsById = keyBy(operations, 'operationId');
    const filteredResources = resources.filter(
      r => !!operationsByResource[r.name]
    );

    const roles = spec
      ? (spec as any).components.schemas.Webhook.properties.ElevatedRoles.items
          .enum
      : [];

    //transformations
    return {
      operations,
      operationsByResource,
      hookableOperationsByResource,
      sections: spec && spec.tags ? spec.tags.filter(tag => tag['x-id']) : [],
      resources: filteredResources, //filter out empty resources
      hookableResources,
      index: initializeIndex(operations, resources),
      operationsById,
      findResource: (operationId: string): ApiResource | undefined => {
        if (!spec) return;

        const operation = operationsById[operationId];
        if (!operation) return;
        const resourceName = operation.tags[0];
        return filteredResources.find(
          resource => resource.name === resourceName
        );
      },
      findOperation: (operationId: string): any | undefined => {
        const operation = operationsById[operationId];
        if (operation && operation.parameters && operation.parameters.length) {
          operation.parameters.forEach(param => {
            switch (param.schema.type) {
              case 'string':
                param.value = '';
                break;
              case 'integer':
                param.value = param.required ? 0 : undefined;
                break;
              case 'boolean':
                param.value = false;
                break;
              default:
                break;
            }
          });
        }
        return operation;
      },
      availableRoles: roles,
      spec,
    };
  }, [spec, meSubsections]);

  return result;
};

export default useApiSpec;

// This method is used to attach the correct subsection to routes.
const GetSubSectionName = (path: string, meSubsections: IMeSubsections[]) => {
  var sec = meSubsections.filter(s => s.paths.includes(`${path}'`));
  return sec ? sec?.[sec?.length - 1]?.name : null;
};

const GetSubsectionsToAdd = (meSubsections: IMeSubsections[]) => {
  return meSubsections.filter(sec => sec.name !== 'Me'); // There is already a Me subsection
};
