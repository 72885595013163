import React from 'react';
import {
  StyledComponentProps,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Case from 'case';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockClosedIcon from '@material-ui/icons/Lock';
import { Warning, Close } from '@material-ui/icons';

export interface BaseInputProps extends StyledComponentProps {
  required: boolean;
  schema: any;
  value: any;
  name: string;
  debounce?: number;
  isLocked: boolean;
  onChange: (value?: any) => void;
  onToggleLock?: (name: string, value: any) => void;
}

export interface BaseInputState {
  value: any;
}

const PROPERTY_LABEL_MAP = { xp: 'Extended Properties' };

class BaseInput extends React.Component<BaseInputProps, BaseInputState> {
  /**
   * The initial state of value is a little complicated (below). It checks
   * that the value isn't undefined (for boolean values), and then if it is defined
   * it makes sure that the value isn't an empty string (text/number/enum values).
   */
  public state = {
    value:
      typeof this.props.value !== undefined &&
      ((typeof this.props.value === 'string' && this.props.value.length) ||
        typeof this.props.value !== 'string')
        ? this.props.value
        : this.props.schema.enum &&
          this.props.schema.enum.length &&
          this.props.schema.in
        ? this.props.schema.enum[0]
        : undefined,
  };

  public componentDidMount = () => {
    if (this.state.value !== undefined) {
      this.props.onChange(this.state.value);
    }
  };

  public componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.value !== this.props.value &&
      this.state.value !== this.props.value
    ) {
      this.setState({ value: this.props.value });
    }
  };

  /**
   * Returns a well formated input label
   * @param name The name of the field in the swagger spec
   */
  public getLabel(name: string) {
    if (PROPERTY_LABEL_MAP[name]) {
      return PROPERTY_LABEL_MAP[name];
    }
    const keySplit = name.split('ID');
    if (keySplit.length === 1) {
      return Case.title(name);
    }
    return `${Case.title(keySplit[0])} ID`;
  }

  private onToggleLock = () => {
    if (this.props.onToggleLock) {
      this.props.onToggleLock(this.props.name, this.props.value);
    }
  };

  private removeNullValue = () => {
    if (this.state.value === null) {
      this.props.onChange();
    }
  };

  private timeout: NodeJS.Timeout | null = null;

  /**
   * Utilizes the debounce property to reduce the
   * number of updates pushed to the requestForms redux store
   * @param newValue The updated user input value
   */
  public updateValue = (newValue?: any) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.setState({
      value: newValue,
    });

    this.timeout = setTimeout(() => {
      this.props.onChange(newValue);
    }, this.props.debounce || 0);
  };

  public getLockIcon(enabled: boolean) {
    if (!this.props.schema.in) return null; // only params can be locked
    return (
      <IconButton
        edge="end"
        color="secondary"
        aria-label="Directions"
        onClick={this.onToggleLock}
        disabled={!enabled}
      >
        {this.props.isLocked ? <LockClosedIcon /> : <LockOpenIcon />}
      </IconButton>
    );
  }

  public getNullValueWarning(show: boolean) {
    if (show) {
      return (
        <React.Fragment>
          <Tooltip
            title={
              <Typography variant="body2" color="inherit">
                {`${this.getLabel(this.props.name)} will be updated with a `}
                <code>NULL</code> value.
              </Typography>
            }
            placement="left"
          >
            <Warning color="error" />
          </Tooltip>
          <IconButton onClick={this.removeNullValue}>
            <Close />
          </IconButton>
        </React.Fragment>
      );
    }
  }
}

export default BaseInput;
