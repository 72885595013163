import { combineReducers } from 'redux';
import { ConsoleResponse } from './consoleRequests/consoleRequest.types';
import { DevCenterUserReducerState } from './auth/auth.types';
import { ContextOptionsReducerState } from './contextOptions/contextOptions.types';
import { ContextSelectionsReducerState } from './contextSelections/contextSelections.types';
import { TabsReducerState } from './tab/tab.types';
import { StatusReducerState } from './requestStatus/requestStatus.types';
import { InvitationsReducerState } from './invitations/invitations.types';
import { FormFieldsDictionary } from './requestForm/requestForm.types';
import tabs from './tab/tab.reducers';
import devCenterUser from './auth/auth.reducers';
import status from './requestStatus/requestStatus.reducers';
import requestForm from './requestForm/requestForm.reducers';

import contextOptions from './contextOptions/contextOptions.reducers';
import contextSelections from './contextSelections/contextSelections.reducers';
import requests from './consoleRequests/consoleRequests.reducers';
import invitations from './invitations/invitations.reducers';

const rootReducer = combineReducers({
  devCenterUser,
  tabs,
  status,
  requestForm,
  contextOptions,
  contextSelections,
  requests,
  invitations,
});

export default rootReducer;

export interface AppReducerState {
  requests: ConsoleResponse[];
  devCenterUser: DevCenterUserReducerState;
  contextOptions: ContextOptionsReducerState;
  contextSelections: ContextSelectionsReducerState;
  tabs: TabsReducerState;
  status: StatusReducerState;
  requestForm: FormFieldsDictionary;
  invitations: InvitationsReducerState;
}
