import { omit as _omit, Dictionary } from 'lodash';
import {
  THIS_TAB,
  OTHER_TABS,
  TABS_TO_THE_RIGHT,
  TABS_TO_THE_LEFT,
  ALL_TABS,
} from './tab.constants';

/**
 * returns an array without the value
 * returns default value if array has no length
 */
export function without<T = any>(array: T[], without: T, defaultVal: T): T[] {
  const filtered = array.filter(x => x !== without);
  return filtered.length ? filtered : [defaultVal];
}

/**
 * returns a dictionary without the value
 * returns default value if object is empty
 */
export function omit<T = any>(
  object: Dictionary<T>,
  withoutKey: string,
  defaultVal: Dictionary<T>
): Dictionary<T> {
  const filtered = _omit(object, withoutKey);
  return Object.keys(filtered).length ? filtered : defaultVal;
}

/**
 * useful after filtering move history which potentially
 * leaves two positions of the same value next to each other.
 *
 * for example:
 * ['0', '1', '1', '2', '2', '1']  => ['0', '1', '2', '1']
 */
export function dedupeHistory(moveHistory: string[]): string[] {
  return moveHistory.filter((val, index, array) => {
    return array[index] !== array[index + 1];
  });
}

function getTabIDsToRight(
  tabIdSelected: string,
  oldTabIds: string[]
): string[] {
  const selectedTabIndex = oldTabIds.indexOf(tabIdSelected);
  return oldTabIds.slice(selectedTabIndex + 1, oldTabIds.length);
}

function getTabIDsToLeft(tabIdSelected: string, oldTabIds: string[]): string[] {
  const selectedTabIndex = oldTabIds.indexOf(tabIdSelected);
  return oldTabIds.slice(0, selectedTabIndex);
}

function getOtherTabIDs(tabIdSelected: string, oldTabIds: string[]): string[] {
  return oldTabIds.filter(t => t !== tabIdSelected);
}

interface GetTabIDsToDestroyProps {
  closeType: string;
  oldTabIds: string[];
  tabIdSelected: string;
}
export function getTabIDsToDestroy({
  closeType,
  oldTabIds,
  tabIdSelected,
}: GetTabIDsToDestroyProps) {
  switch (closeType) {
    case THIS_TAB:
      return [tabIdSelected];
    case OTHER_TABS:
      return getOtherTabIDs(tabIdSelected, oldTabIds);
    case TABS_TO_THE_RIGHT:
      return getTabIDsToRight(tabIdSelected, oldTabIds);
    case TABS_TO_THE_LEFT:
      return getTabIDsToLeft(tabIdSelected, oldTabIds);
    case ALL_TABS:
      return oldTabIds;
    default:
      throw new Error(
        'getTabIDsToDestroy arguments are invalid, please check this function call'
      );
  }
}
