import Axios from 'axios';
import { Alert } from '../components/App/AlertContainer';

export function ConfigureErrorHandler(logout: () => void): void {
  Axios.interceptors.response.use(
    success => success,
    error => {
      const message = extractMessage(error);
      const objectType = error?.response?.data?.Errors[0]?.Data?.ObjectType;
      if (
        error.message === 'Request failed with status code 401' ||
        message === 'Access token is invalid or expired.'
      ) {
        Alert.warn('Your session has expired. Please log in again.');
        logout();
        return Promise.reject(error);
      }
      if (error && error.config && error.config.requestType === 'quiet') {
        return Promise.reject(error);
      }
      if (
        //OCP-388
        (error.message === 'Object not found.' ||
          message === 'Object not found.') &&
        objectType === 'Org'
      ) {
        Alert.warn('Organization not found.');
        return Promise.reject();
      }
      if (
        (error.message === 'Object not found.' ||
          message === 'Object not found.') &&
        (objectType === 'OrderSyncConfig' ||
          objectType === 'ProductSyncConfig' ||
          objectType === 'EntitySyncConfig')
      ) {
        Alert.warn(
          `No ${objectType} has been configured for this marketplace.`
        );
        return Promise.reject();
      }
      if (
        (error.message === 'Object not found.' ||
          message === 'Object not found.') &&
        !!error?.response?.data?.Errors[0]?.Data?.ObjectType
      ) {
        Alert.warn(
          `${error?.response?.data?.Errors[0]?.Data?.ObjectType} not found.`
        );
        return Promise.reject();
      }
      Alert.error(message);
      return Promise.reject(error);
    }
  );
}

// Continue to add cases as new error objects are encountered
export function extractMessage(error: any): string {
  let message = 'An error has occurred';
  let data = {} as any;
  if (typeof error === 'string') {
    return error;
  }
  if (error && error.response && error.response.data) {
    data = error.response.data;
  }
  if (data.error_description) {
    message = data.error_description;
  } else if (data.Errors && data.Errors.length) {
    message = data.Errors[0].Message;
  } else if (data.error) {
    message = data.error;
  }
  if (typeof message === 'object') {
    message = JSON.stringify(message);
  }
  return message;
}
