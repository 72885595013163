import React, { useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  TextField,
  IconButton,
  Button,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import {
  emptyKeyValue,
  KeyValuePair,
} from '../../../RequestForms/InputTypes/KeyValueInput';
import { Close } from '@material-ui/icons';
import cloneDeep from 'lodash/cloneDeep';
import FieldSection from '../FieldSection';

interface ConfigurationDataProps {
  readOnly?: boolean;
  configData: any;
  handleChange: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    textField: {
      flex: 1,
      marginRight: theme.spacing(2),
    },
    addButton: {
      flex: 0,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    removeButton: {
      marginTop: theme.spacing(1),
    },
  })
);

function ConfigurationData(props: ConfigurationDataProps) {
  const classes = useStyles();
  const { configData, handleChange, readOnly } = props;

  // we will maintain a KeyValuePair local state and only update the
  // config data if there is a value state which means
  // 1) no duplicate keys
  // 2) every key has a value
  const [keyValuePairs, setKeyValuePairs] = useState<KeyValuePair[]>([]);

  useEffect(() => {
    function mapConfigDataToKeyValuePairs() {
      setKeyValuePairs(
        Object.entries(configData).map(([key, value]) => {
          return { key, value: value as string };
        })
      );
    }
    if (configData !== 'ConfigDataInvalid') {
      mapConfigDataToKeyValuePairs();
    }
  }, [configData]);

  const mapKeyValuePairsToConfigData = (
    keyValuePairsUpdated: KeyValuePair[]
  ) => {
    const configDataUpdated = {};
    keyValuePairsUpdated.forEach(({ key, value }) => {
      configDataUpdated[key] = value;
    });
    return configDataUpdated;
  };

  const areAllFieldsComplete = (keyValuePairs: KeyValuePair[]) => {
    return !keyValuePairs.some(({ key, value }) => {
      return !key || !value;
    });
  };

  const areNoDuplicateFields = (keyValuePairs: KeyValuePair[]) => {
    return new Set(keyValuePairs.map(k => k.key)).size === keyValuePairs.length;
  };

  const updateConfigDataIfValid = (stateCopy: KeyValuePair[]) => {
    if (areAllFieldsComplete(stateCopy) && areNoDuplicateFields(stateCopy)) {
      handleChange(mapKeyValuePairsToConfigData(stateCopy));
    } else {
      handleChange('ConfigDataInvalid');
    }
  };

  const addItem = () => {
    if (keyValuePairs) {
      setKeyValuePairs([...keyValuePairs, emptyKeyValue]);
    } else {
      setKeyValuePairs([emptyKeyValue]);
    }
  };

  const removeItem = (index: number) => (event: React.MouseEvent) => {
    const stateCopy = cloneDeep(keyValuePairs);
    stateCopy.splice(index, 1);
    setKeyValuePairs(stateCopy);
    updateConfigDataIfValid(stateCopy);
  };

  const handleKeyValueChange = (field: string, index: number) => (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const stateCopy = cloneDeep(keyValuePairs);
    stateCopy[index][field] = event.target.value;
    setKeyValuePairs(stateCopy);
    updateConfigDataIfValid(stateCopy);
  };

  return (
    <FieldSection
      headerText="Configuration Data"
      infoText="These key value pairs will be included on the webhook payload in JSON."
    >
      {keyValuePairs.map(({ key, value }, index) => {
        const isDuplicateKey =
          keyValuePairs.filter((k: KeyValuePair) => k.key === key).length > 1;
        return (
          <div key={index} className={classes.row}>
            <TextField
              disabled={readOnly}
              InputProps={{
                readOnly,
              }}
              autoFocus={Boolean(index === keyValuePairs.length - 1)}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              label="Key"
              helperText={
                isDuplicateKey ? (
                  <DuplicateKeyError fieldType={'KeyField'} />
                ) : null
              }
              value={key}
              onChange={handleKeyValueChange('key', index)}
            />
            <TextField
              disabled={readOnly}
              InputProps={{
                readOnly,
              }}
              autoFocus={Boolean(index === keyValuePairs.length - 1)}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              label="Value"
              helperText={
                isDuplicateKey ? (
                  <DuplicateKeyError fieldType={'ValueField'} />
                ) : null
              }
              value={value}
              onChange={handleKeyValueChange('value', index)}
            />
            {!readOnly && (
              <IconButton
                className={classes.removeButton}
                onClick={removeItem(index)}
              >
                <Close color="error" />
              </IconButton>
            )}
          </div>
        );
      })}
      {!keyValuePairs.length && (
        <Typography>No configuration data provided.</Typography>
      )}
      {!readOnly && (
        <Button
          className={classes.addButton}
          fullWidth
          color="secondary"
          size="large"
          variant="outlined"
          disabled={
            keyValuePairs &&
            (!areAllFieldsComplete(keyValuePairs) ||
              !areNoDuplicateFields(keyValuePairs))
          }
          onClick={addItem}
        >
          Add Key Value Pair
        </Button>
      )}
    </FieldSection>
  );
}

interface DuplicateKeyErrorProps {
  fieldType: string;
}
const DuplicateKeyError = (props: DuplicateKeyErrorProps) => {
  return (
    <FormHelperText id="component-error-text" error={true}>
      {props.fieldType === 'KeyField'
        ? 'Duplicate key. Only one value may be assigned to a given configuration key.'
        : '  '}
    </FormHelperText>
  );
};

export default ConfigurationData;
