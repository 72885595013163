import {
  Button,
  Checkbox,
  createStyles,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  Popover,
  TextField,
  Theme,
  Typography,
  Chip,
} from '@material-ui/core';
import { grey, teal } from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/styles';
import cloneDeep from 'lodash/cloneDeep';
import {
  MessageSender,
  MessageType,
  RequiredDeep,
} from 'ordercloud-javascript-sdk';
import React, { useEffect, useState } from 'react';
import FieldSection from '../FieldSection';
import {
  DEFAULT,
  DefaultMessageSenderTemplates,
  determineMessageSenderType,
  getTemplateType,
  MessageSenderTemplate,
} from './constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemRoot: {
      padding: theme.spacing(1, 2),
      color: 'rgba(0, 0, 0, 0.87)',
    },
    listItemRootActive: {
      padding: theme.spacing(1, 2),
      color: 'rgba(0, 0, 0, 0.87)',
      border: `2px ${teal[200]} dashed`,
      borderRadius: '4px',
    },
    formLabelHiding: {
      display: 'none',
    },
    templateDescription: {
      color: grey[400],
    },
    revertDefaultButton: {
      width: '30%',
    },
    popoverTypography: {
      padding: theme.spacing(2),
    },
    popoverContainer: {
      maxWidth: `300px`,
    },
  })
);

interface MessageTemplateSelectorProps {
  readOnly?: boolean;
  messageSenderUpdated: Partial<MessageSender>;
  handleChangeObject: (newValue: any) => void;
}

function MessageTemplateSelector(props: MessageTemplateSelectorProps) {
  const classes = useStyles();

  const currentlySelectedMessageType = determineMessageSenderType(
    props.messageSenderUpdated
  );
  const { handleChangeObject, messageSenderUpdated, readOnly } = props;
  const [selectionError, setSelectionError] = useState(false);

  useEffect(() => {
    messageSenderUpdated.MessageTypes &&
    messageSenderUpdated.MessageTypes.length < 1
      ? setSelectionError(true)
      : setSelectionError(false);
  }, [messageSenderUpdated.MessageTypes]);

  const toggleInclusion = (messageType: MessageType) => {
    if (readOnly) return;
    const newMessageSenderUpdated = cloneDeep(messageSenderUpdated);

    let messageTypes = newMessageSenderUpdated.MessageTypes;
    let messageTypesConfig = newMessageSenderUpdated.xp.MessageTypeConfig || [];

    if (!messageTypes) {
      return;
    }
    if (messageTypes.includes(messageType)) {
      // remove the related fields if the message type is already included on the object
      newMessageSenderUpdated.MessageTypes = messageTypes.filter(
        m => m !== messageType
      );
      newMessageSenderUpdated.xp.MessageTypeConfig = messageTypesConfig.filter(
        m => m.MessageType !== messageType
      );
    } else {
      // add the default object if it is not
      newMessageSenderUpdated.MessageTypes = [...messageTypes, messageType];
      newMessageSenderUpdated.xp.MessageTypeConfig = [
        ...messageTypesConfig,
        stripInformationalOnlyFields(
          DefaultMessageSenderTemplates.find(d => d.MessageType === messageType)
        ),
      ];
    }
    // if (newMessageSenderUpdated.MessageTypes.length < 1) {
    //   setSelectionError(true);
    // } else {
    //   setSelectionError(false);
    // }

    handleChangeObject(newMessageSenderUpdated);
  };

  const stripInformationalOnlyFields = (messageTypeConfig: any) => {
    const messageTypeConfigClone = cloneDeep(messageTypeConfig);
    delete messageTypeConfigClone.Description;
    delete messageTypeConfigClone.Name;
    return messageTypeConfigClone;
  };

  const GetMessageTemplateFromObjectXp = (messageType: string) => {
    return (
      messageSenderUpdated.xp.MessageTypeConfig &&
      messageSenderUpdated.xp.MessageTypeConfig.find(
        m => m.MessageType === messageType
      )
    );
  };

  const handleChangeMessageText = (
    fieldName: string,
    currentMessageOnXp: any
  ) => event => {
    // remove the existing value on xp, and replace with one with the updated text for that message type
    const newMessageTypeConfig = messageSenderUpdated.xp.MessageTypeConfig.filter(
      m => m.MessageType !== currentMessageOnXp.MessageType
    );
    const newMessageSenderUpdated = cloneDeep(messageSenderUpdated);
    newMessageSenderUpdated.xp.MessageTypeConfig = [
      ...newMessageTypeConfig,
      { ...currentMessageOnXp, [fieldName]: event.target.value },
    ];
    handleChangeObject(newMessageSenderUpdated);
  };

  const revertMessageTextToDefault = (
    fieldName: string,
    currentMessageOnXp: any
  ) => {
    const defaultObject = DefaultMessageSenderTemplates.find(
      t => t.MessageType === currentMessageOnXp.MessageType
    );
    const newMessageTypeConfig = messageSenderUpdated.xp.MessageTypeConfig.filter(
      m => m.MessageType !== currentMessageOnXp.MessageType
    );
    const newMessageSenderUpdated = cloneDeep(messageSenderUpdated);
    newMessageSenderUpdated.xp.MessageTypeConfig = [
      ...newMessageTypeConfig,
      {
        ...currentMessageOnXp,
        [fieldName]: (defaultObject && defaultObject[fieldName]) || '',
      },
    ];
    handleChangeObject(newMessageSenderUpdated);
  };

  return (
    <FieldSection
      headerText="Templates"
      infoText={
        currentlySelectedMessageType !== DEFAULT
          ? 'Choose which API events to which this message sender will apply. You may customize the subject, from email, and template name.'
          : 'Choose which API events to which this message sender will apply. You may customize the subject and from email.'
      }
    >
      {selectionError && (
        <Typography title="Template error" color="error" variant="body2">
          Must select a Template
        </Typography>
      )}
      <List>
        {DefaultMessageSenderTemplates.map(
          (template: RequiredDeep<MessageSenderTemplate>, index) => {
            const objectAlreadyOnXp = GetMessageTemplateFromObjectXp(
              template.MessageType
            );
            return (
              <ListItem
                key={index}
                className={objectAlreadyOnXp ? classes.listItemRootActive : ''}
                onClick={() => {
                  if (!objectAlreadyOnXp) {
                    toggleInclusion(template.MessageType as MessageType);
                  }
                }}
              >
                <span style={{ width: '15%' }}>
                  <Chip
                    size="small"
                    variant="outlined"
                    color="primary"
                    label={getTemplateType(template)}
                  />
                </span>
                <div style={{ marginRight: 42, width: '85%' }}>
                  <Typography>{template.Name}</Typography>
                  <div style={{ width: '100%' }}>
                    <span className={classes.templateDescription}>
                      {template.Description}
                    </span>
                    {objectAlreadyOnXp && (
                      <div>
                        <TemplateTextField
                          field="Subject"
                          disabled={readOnly}
                          value={objectAlreadyOnXp.Subject}
                          handleChange={handleChangeMessageText(
                            'Subject',
                            objectAlreadyOnXp
                          )}
                          handleRevertToDefault={() =>
                            revertMessageTextToDefault(
                              'Subject',
                              objectAlreadyOnXp
                            )
                          }
                        />
                        <TemplateTextField
                          field="From Email"
                          disabled={readOnly}
                          value={objectAlreadyOnXp.FromEmail}
                          handleChange={handleChangeMessageText(
                            'FromEmail',
                            objectAlreadyOnXp
                          )}
                          handleRevertToDefault={() =>
                            revertMessageTextToDefault(
                              'FromEmail',
                              objectAlreadyOnXp
                            )
                          }
                        />
                        {currentlySelectedMessageType !== DEFAULT && (
                          <TemplateTextField
                            field="Template Name"
                            disabled={readOnly}
                            value={objectAlreadyOnXp.TemplateName}
                            handleChange={handleChangeMessageText(
                              'TemplateName',
                              objectAlreadyOnXp
                            )}
                            handleRevertToDefault={() =>
                              revertMessageTextToDefault(
                                'TemplateName',
                                objectAlreadyOnXp
                              )
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <ListItemSecondaryAction>
                  <Checkbox
                    readOnly={readOnly}
                    disabled={readOnly}
                    edge="end"
                    checked={
                      messageSenderUpdated.MessageTypes &&
                      messageSenderUpdated.MessageTypes.includes(
                        template.MessageType as MessageType
                      )
                    }
                    onClick={() =>
                      toggleInclusion(template.MessageType as MessageType)
                    }
                    inputProps={{ 'aria-labelledby': template.Name }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          }
        )}
      </List>
    </FieldSection>
  );
}

interface TemplateTextFieldProps {
  field: string;
  disabled?: boolean;
  value: MessageSender;
  handleChange: (event: any) => void;
  handleRevertToDefault: () => void;
}
const TemplateTextField = (props: TemplateTextFieldProps) => {
  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={props.handleRevertToDefault}
        >
          Revert
        </Button>
      </InputAdornment>
    ),
  };

  if (props.field === 'From Email') {
    inputProps['startAdornment'] = <MandrillEmailVerificationInfo />;
  }

  return (
    <TextField
      fullWidth
      disabled={props.disabled}
      label={`Message ${props.field} *`}
      value={props.value}
      margin="dense"
      onChange={props.handleChange}
      variant="outlined"
      InputProps={props.disabled ? { readOnly: true } : inputProps}
    />
  );
};

const MandrillEmailVerificationInfo = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const classes = useStyles();
  const isOpen = Boolean(anchorEl);

  return (
    <InputAdornment position="start">
      <IconButton aria-label={`info about Mandrill email verification`}>
        <InfoIcon color="secondary" onClick={handleClick} />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        classes={{ paper: classes.popoverContainer }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.popoverTypography}>
          From Emails in Mandrill require verification to confirm you are the
          owner of that sending domain. Please see{' '}
          <a
            target="blank"
            href="https://mandrill.zendesk.com/hc/en-us/articles/205582247-About-Domain-Verification"
          >
            this page
          </a>{' '}
          for more information about this verification process.
        </Typography>
      </Popover>
    </InputAdornment>
  );
};

export default MessageTemplateSelector;
