import {
  SET_MARKETPLACE_INVITATIONS,
  SET_MARKETPLACE_TEAM_INVITATIONS,
  SET_TEAM_INVITATIONS,
  UPDATE_MARKETPLACE_INVITATIONS,
  UPDATE_MARKETPLACE_TEAM_INVITATIONS,
  UPDATE_TEAM_INVITATIONS,
} from './invitations.types';

export function setMarketplaceInvitations(invitations: any) {
  return {
    type: SET_MARKETPLACE_INVITATIONS,
    payload: { invitations },
  };
}

export function updateMarketplaceInvitations(orgId: string) {
  return {
    type: UPDATE_MARKETPLACE_INVITATIONS,
    payload: { orgId },
  };
}

export function setMarketplaceTeamInvitations(invitations: any) {
  return {
    type: SET_MARKETPLACE_TEAM_INVITATIONS,
    payload: { invitations },
  };
}

export function updateMarketplaceTeamInvitations(
  orgId: string,
  teamId: string
) {
  return {
    type: UPDATE_MARKETPLACE_TEAM_INVITATIONS,
    payload: { orgId, teamId },
  };
}

export function setTeamInvitations(invitations: any) {
  return {
    type: SET_TEAM_INVITATIONS,
    payload: { invitations },
  };
}

export function updateTeamInvitations(teamId: string) {
  return {
    type: UPDATE_TEAM_INVITATIONS,
    payload: { teamId },
  };
}
