import React, { useState } from 'react';
import { Webhook } from 'ordercloud-javascript-sdk';
import { TextField } from '@material-ui/core';
import AutoCompleteUrl from './AutoCompleteUrl';
import PrehookToggle from './PrehookToggle';
import ConfigurationData from './ConfigurationData';
import TriggerEvents from './TriggerEvents';
import RolesSelector from '../RolesSelector';
import ApiClientAssignments from './ApiClientAssignments';
import FieldSection from '../FieldSection';

interface EditWebhookProps {
  hasEditAccess: boolean;
  webhookUpdated: Webhook;
  webhooks: Webhook[];
  handleChangeEvent: (field: string) => (event: any) => void;
  handleChangeValue: (field: string) => (newValue: any) => void;
  handleChangeObject: (field: string) => (newValue: any) => void;
}

function EditWebhook(props: EditWebhookProps) {
  const {
    hasEditAccess,
    webhookUpdated,
    handleChangeEvent,
    handleChangeValue,
    handleChangeObject,
  } = props;

  const [hashKeyDirty, setHashKeyDirty] = useState(false);

  return (
    <React.Fragment>
      <FieldSection headerText="General Information">
        <TextField
          disabled={!hasEditAccess}
          InputProps={{ readOnly: !hasEditAccess }}
          fullWidth
          label="Name *"
          value={(webhookUpdated && webhookUpdated.Name) || ''}
          margin="normal"
          onChange={handleChangeEvent('Name')}
          variant="outlined"
          error={!webhookUpdated.Name}
          helperText={!webhookUpdated.Name ? 'Must provide Name' : ' '}
        />
        <TextField
          disabled={!hasEditAccess}
          InputProps={{ readOnly: !hasEditAccess }}
          fullWidth
          label="Description"
          multiline
          rows="3"
          onChange={handleChangeEvent('Description')}
          value={(webhookUpdated && webhookUpdated.Description) || ''}
          margin="normal"
          variant="outlined"
        />
        <TextField
          disabled={!hasEditAccess}
          InputProps={{ readOnly: !hasEditAccess }}
          fullWidth
          label="Secret *"
          onChange={e => {
            setHashKeyDirty(true);
            handleChangeEvent('HashKey')(e);
          }}
          value={(webhookUpdated && webhookUpdated.HashKey) || ''}
          margin="normal"
          variant="outlined"
          error={!webhookUpdated.HashKey && hashKeyDirty}
          helperText={
            !webhookUpdated.HashKey && hashKeyDirty
              ? 'Must provide Secret'
              : ' '
          }
        />
        <AutoCompleteUrl
          readOnly={!hasEditAccess}
          handleChange={handleChangeValue('Url')}
          str={(webhookUpdated && webhookUpdated.Url) || ''}
          webhooks={props.webhooks}
        />
        <PrehookToggle
          readOnly={!hasEditAccess}
          onChange={handleChangeValue('BeforeProcessRequest')}
          isPrehook={Boolean(
            webhookUpdated && webhookUpdated.BeforeProcessRequest
          )}
        />
      </FieldSection>
      <ApiClientAssignments
        readOnly={!hasEditAccess}
        apiClientAssignments={
          (webhookUpdated && webhookUpdated.ApiClientIDs) || []
        }
        handleChange={handleChangeValue('ApiClientIDs')}
      />
      <ConfigurationData
        readOnly={!hasEditAccess}
        configData={(webhookUpdated && webhookUpdated.ConfigData) || {}}
        handleChange={handleChangeValue('ConfigData')}
      />
      <TriggerEvents
        readOnly={!hasEditAccess}
        webhookRoutes={(webhookUpdated && webhookUpdated.WebhookRoutes) || []}
        handleChange={handleChangeValue('WebhookRoutes')}
      />
      <RolesSelector
        readOnly={!hasEditAccess}
        headerText="Elevated Roles"
        descriptionElement={() => {
          return (
            <React.Fragment>
              You may wish to provide roles in addition to what is included on
              the user request. Roles selected here will be inluded on an access
              token (<code>access_token</code>) provided with the webhook.
            </React.Fragment>
          );
        }}
        elevatedRoles={webhookUpdated && webhookUpdated.ElevatedRoles}
        handleChangeValue={handleChangeValue}
        handleChangeObject={handleChangeObject}
        rolesFieldName="ElevatedRoles"
        showCustomRoles={false}
        resourceUpdated={webhookUpdated}
      />
    </React.Fragment>
  );
}

export default EditWebhook;
