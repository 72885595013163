import React from 'react';

import {
  withStyles,
  Theme,
  createStyles,
  Typography,
  Chip,
} from '@material-ui/core';
import {
  OrderCloudUser,
  RequiredDeep,
} from '@ordercloud/portal-javascript-sdk';

const styles = (theme: Theme) =>
  createStyles({
    companyType: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    companyTypeLabel: {
      paddingRight: 5,
    },
    companyNameAlt: {
      borderRadius: 0,
      borderLeft: 'none',
    },
    companyNameLabelAlt: {
      paddingLeft: 5,
      paddingRight: 5,
      maxWidth: theme.spacing(15),
    },
    companyName: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    companyNameLabel: {
      paddingLeft: 5,
      maxWidth: theme.spacing(15),
    },
  });

interface UserAccessProps extends RequiredDeep<OrderCloudUser> {
  classes: any;
  showUsername?: boolean;
}

class UserAccess extends React.Component<UserAccessProps> {
  public render() {
    const { classes, showUsername } = this.props;
    return (
      <React.Fragment>
        <Chip
          size="small"
          color="primary"
          classes={{
            root: classes.companyType,
            label: classes.companyTypeLabel,
          }}
          variant="outlined"
          label={this.props.CommerceRole}
        />
        <Chip
          size="small"
          color="primary"
          classes={{
            root: Boolean(showUsername)
              ? classes.companyNameAlt
              : classes.companyName,
            label: Boolean(showUsername)
              ? classes.companyNameLabelAlt
              : classes.companyNameLabel,
          }}
          label={
            <Typography
              title={this.props.CompanyName}
              style={{ fontSize: 'inherit' }}
              noWrap
              color="inherit"
            >
              {this.props.CompanyName}
            </Typography>
          }
        />
        {Boolean(showUsername) && (
          <Chip
            size="small"
            color="primary"
            classes={{
              root: classes.companyName,
              label: classes.companyNameLabel,
            }}
            label={
              <Typography
                title={this.props.Username}
                style={{ fontSize: 'inherit' }}
                noWrap
                color="inherit"
              >
                {this.props.Username}
              </Typography>
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UserAccess);
