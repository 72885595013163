import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { AppReducerState } from '../../../../../redux';
import AuthService from '../../../../../services/auth.service';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { connect } from 'react-redux';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  Box,
} from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CopyResource from './CopyResource';
import cloneDeep from 'lodash/cloneDeep';
import EditResource from './EditResource';
import pluralize from 'pluralize';
import PlusIcon from '@material-ui/icons/AddCircle';
import { teal, grey } from '@material-ui/core/colors';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import { PrettyResourceType, MESSAGE_SENDERS } from './constants';
import {
  ResourceRequestStatus,
  RoleComparison,
  REQUEST_NOT_MADE,
  SafelyGetResource,
  REQUEST_SUCCESSFUL,
  REQUEST_AUTH_FAILURE,
} from '../requestStatus';
import { RolesRequiredComparison } from '../RolesRequiredComparison';
import { generateRandomString } from '../../../../../services/utilites.helper';
import { ApiRole, ApiClients, Meta } from 'ordercloud-javascript-sdk';
import { getSellerOrgImpersonationTokenAndAPIClients } from '../../../../../redux/contextOptions/contextThunk.actions';
import { Pagination } from '@material-ui/lab';

interface PrettyResourceNavigationProps {
  activeTabId: string;
  currentSellerToken: string;
  currentSellerOrgID: string;
  renderEditComponent: (
    hasEditAccess: boolean,
    updatedResource: any,
    resources: any[],
    handleChangeEvent: any,
    handleChangeValue: any,
    handleChangeObject: any,
    isCreatingNew: any,
    assignmentsUpdated: any,
    setAssignmentsUpdated: any
  ) => any;
  headerComponent: any;
  tableRowComponent: any;
  ocService: any;
  resourceName: string;
  emptyResource: any;
  isValidResource: (resource: any) => boolean;
  numberOfTableColumns: number;
  resourceType: PrettyResourceType;
  editRole?: ApiRole;
  getSellerOrgImpersonationTokenAndAPIClients: any;
  getUpdatedSellerOrgTokenAndSetInRedux: (
    sellerOrgID: string,
    currentSellerToken: string
  ) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    root: {
      width: '100%',
      overflowX: 'auto',
      backgroundColor: 'white',
    },
    viewingIndividualResourceRoot: {
      top: 0,
      width: '22%',
      position: 'sticky',
      backgroundColor: 'white',
      borderRight: `1px solid ${grey[400]}`,
      borderBottom: `1px solid ${grey[400]}`,
      height: `calc(100vh - 167px)`, //TEMP FIX FOR SCROLLING ON SECONDARY LIST VIEW
      overflowY: 'auto',
    },
    noResources: {
      margin: theme.spacing(2),
    },
    header: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
    createNew: {
      alignSelf: 'flex-end',
    },
    iconAlignMiddle: {
      verticalAlign: 'middle',
    },
    createNewInSubMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: teal[50],
    },
    createNewRow: {
      backgroundColor: teal[50],
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: teal[100],
      },
    },
    tableCellTableView: {
      paddingTop: '3px',
      paddingBottom: '3px',
    },
    tableCellMenuView: {
      paddingTop: '8px',
      paddingBottom: '8px',
      cursor: 'pointer',
    },
    plusIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

function PrettyResourceNavigation(props: PrettyResourceNavigationProps) {
  const classes = useStyles();

  const {
    activeTabId,
    resourceName,
    currentSellerToken,
    currentSellerOrgID,
    ocService,
    emptyResource,
    renderEditComponent,
    isValidResource,
    tableRowComponent,
    resourceType,
    headerComponent,
    editRole,
  } = props;

  const hasEditAccess = useMemo(() => {
    if (!currentSellerToken) return false;
    if (!editRole) return true;
    const parsedToken = jwtDecode(currentSellerToken);
    if (
      typeof parsedToken.role === 'string' &&
      (parsedToken.role === 'FullAccess' || parsedToken.role === editRole)
    )
      return true;
    return (
      parsedToken.role.includes('FullAccess') ||
      parsedToken.role.includes(editRole)
    );
  }, [currentSellerToken, editRole]);

  const initialResourceState: any[] = [];
  const initialResourceMetaState: Meta = {};
  const [resources, setResources] = useState(initialResourceState);
  const [resourcesMeta, setResourcesMeta] = useState(initialResourceMetaState);
  const [page, setPage] = useState(1);
  // -1 indicates a resource is not selected
  const [activeResourceIndex, setActiveResourceIndex] = useState(-1);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [requestStatus, setRequestStatus] = useState<ResourceRequestStatus>(
    REQUEST_NOT_MADE
  );
  const [authFailureData, setAuthFailureData] = useState<RoleComparison>({
    AssignedRoles: [],
    RequiredRoles: [],
  });

  const getListInformation = useCallback(async () => {
    const updatedSellerOrgToken = await props.getUpdatedSellerOrgTokenAndSetInRedux(
      currentSellerOrgID,
      currentSellerToken
    );
    if (ocService === ApiClients) {
      props.getSellerOrgImpersonationTokenAndAPIClients(
        currentSellerOrgID,
        currentSellerToken,
        activeTabId
      );
    }
    const resources = await SafelyGetResource(updatedSellerOrgToken, {
      ocService,
      currentSellerOrgID,
      currentSellerToken,
      setRequestStatus,
      setAuthFailureData,
      page: page,
    });
    setResources(resources.Items);
    setResourcesMeta(resources.Meta);
    return resources.Items;
  }, [
    props,
    currentSellerOrgID,
    currentSellerToken,
    ocService,
    activeTabId,
    page,
  ]);

  const updateInformation = useCallback(async () => {
    if (!currentSellerToken) {
      await props.getUpdatedSellerOrgTokenAndSetInRedux(
        currentSellerOrgID,
        currentSellerToken
      );
    } else {
      getListInformation();
      setActiveResourceIndex(-1);
    }
  }, [currentSellerOrgID, currentSellerToken, getListInformation, props]);

  useEffect(() => {
    updateInformation();
  }, [updateInformation]);

  const addSharedKeyToMessageSenderIfNeeded = (resource: any) => {
    if (resourceType === MESSAGE_SENDERS && !resource.SharedKey) {
      resource.SharedKey = generateRandomString(16);
    }
  };

  const saveResource = async (resourceID: string, resourceUpdated: any) => {
    const updatedSellerOrgToken = await props.getUpdatedSellerOrgTokenAndSetInRedux(
      currentSellerOrgID,
      currentSellerToken
    );

    // currently a shared key needs to be included on the request for message senders to avoid an error in the api
    addSharedKeyToMessageSenderIfNeeded(resourceUpdated);
    await ocService.Save(resourceID, resourceUpdated, {
      accessToken: updatedSellerOrgToken,
    });
  };

  const duplicateResource = async (event: any, resourceToDuplicate: any) => {
    event.stopPropagation();
    const newResource = cloneDeep(resourceToDuplicate);
    delete newResource.ID;
    setCopiedResourceName(newResource);
    const updatedSellerOrgToken = await props.getUpdatedSellerOrgTokenAndSetInRedux(
      currentSellerOrgID,
      currentSellerToken
    );

    // currently a shared key needs to be included on the request for message senders to avoid an error in the api
    addSharedKeyToMessageSenderIfNeeded(newResource);
    await ocService.Create(newResource, { accessToken: updatedSellerOrgToken });
    getListInformation();
  };

  const setCopiedResourceName = (newResource: any) => {
    if (newResource.Name) {
      newResource.Name = newResource.Name + ' COPY';
    } else {
      newResource.AppName = newResource.AppName + ' COPY';
    }
  };

  const deleteResource = async (resourceID: string) => {
    const updatedSellerOrgToken = await props.getUpdatedSellerOrgTokenAndSetInRedux(
      currentSellerOrgID,
      currentSellerToken
    );
    await ocService.Delete(resourceID, { accessToken: updatedSellerOrgToken });
    getListInformation();
    setActiveResourceIndex(-1);
  };

  const initializeCreateNewResource = () => {
    setIsCreatingNew(true);
    setActiveResourceIndex(resources?.length);
    setResources([...resources, emptyResource]);
  };

  const createNewResource = async (resource: any): Promise<string> => {
    const updatedSellerOrgToken = await props.getUpdatedSellerOrgTokenAndSetInRedux(
      currentSellerOrgID,
      currentSellerToken
    );

    // currently a shared key needs to be included on the request for message senders to avoid an error in the api
    addSharedKeyToMessageSenderIfNeeded(resource);
    const newResourceResponse = await ocService.Create(resource, {
      accessToken: updatedSellerOrgToken,
    });
    setActiveResourceIndex(-1);
    setIsCreatingNew(false);
    return newResourceResponse.ID;
  };

  const abandonCreateNew = (isCreatingNew: boolean) => {
    if (isCreatingNew) {
      setResources(resources?.slice(0, resources?.length - 1));
      setActiveResourceIndex(-1);
      setIsCreatingNew(false);
    }
  };

  const handlePaginationChange = async (e, v) => setPage(v);

  const isTableView = activeResourceIndex === -1;
  return (
    <div className={classes.container}>
      <div
        className={
          isTableView ? classes.root : classes.viewingIndividualResourceRoot
        }
      >
        {requestStatus === REQUEST_AUTH_FAILURE && (
          <RolesRequiredComparison
            roleComparison={authFailureData}
            resourceName={resourceName}
          />
        )}
        {currentSellerToken && requestStatus !== REQUEST_AUTH_FAILURE ? (
          <Table>
            <TableHead>
              <TableRow key="headerRow1" hover={!isTableView}>
                {isTableView && [
                  <TableCell
                    className={
                      isTableView
                        ? classes.tableCellTableView
                        : classes.tableCellMenuView
                    }
                    key={3}
                  >
                    Name
                  </TableCell>,
                ]}
                {!isTableView && (
                  <TableCell
                    key={4}
                    className={
                      isTableView
                        ? classes.tableCellTableView
                        : classes.tableCellMenuView
                    }
                    onClick={e => {
                      setActiveResourceIndex(-1);
                    }}
                  >
                    <Typography variant="button">
                      <ArrowLeft className={classes.iconAlignMiddle} />
                      Back to Table View
                    </Typography>
                  </TableCell>
                )}
                {isTableView && (
                  <React.Fragment>
                    {headerComponent()}
                    <TableCell
                      className={classes.tableCellTableView}
                      align="center"
                      key={5}
                      colSpan={1}
                    >
                      Actions
                    </TableCell>
                    <TableCell
                      className={classes.tableCellTableView}
                      align="center"
                      key={6}
                      colSpan={1}
                    />
                  </React.Fragment>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {hasEditAccess && (
                <CreateNewRow
                  initializeCreateNewResource={initializeCreateNewResource}
                  isTableView={isTableView}
                  resourceName={props.resourceName}
                  numberOfTableColumns={props.numberOfTableColumns}
                  isCreatingNew={isCreatingNew}
                />
              )}
              {currentSellerToken &&
              !resources?.length &&
              requestStatus === REQUEST_SUCCESSFUL ? (
                <TableRow key="noResourcesFound">
                  <TableCell
                    component="th"
                    scope="row"
                    key="noResourcesFound"
                    colSpan={props.numberOfTableColumns}
                  >
                    <Typography
                      classes={{ root: classes.noResources }}
                      variant="body1"
                    >
                      No {resourceName} available
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}
              {resources?.map((resource, index) => (
                <TableRow
                  key={resource.ID}
                  selected={activeResourceIndex === index}
                  hover={true}
                  onClick={e => setActiveResourceIndex(index)}
                >
                  {isTableView || activeResourceIndex === index ? (
                    <TableCell
                      className={
                        isTableView
                          ? classes.tableCellTableView
                          : classes.tableCellMenuView
                      }
                      component="th"
                      scope="row"
                      key={'c' + index}
                    >
                      <strong>{resource.Name || resource.AppName}</strong>
                    </TableCell>
                  ) : (
                    <TableCell
                      className={classes.tableCellMenuView}
                      component="th"
                      scope="row"
                      key={'d' + index}
                    >
                      {resource.Name || resource.AppName}
                    </TableCell>
                  )}
                  {isTableView ? tableRowComponent(resource) : null}
                  {isTableView ? (
                    <React.Fragment>
                      <TableCell
                        className={classes.tableCellTableView}
                        align="center"
                        key={'a' + index}
                      >
                        <CopyResource resource={resource} />
                        {hasEditAccess && (
                          <Tooltip title="Duplicate">
                            <IconButton
                              aria-label="copy"
                              onClick={e => duplicateResource(e, resource)}
                            >
                              <DuplicateIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellTableView}
                        align="center"
                      >
                        <ArrowRight />
                      </TableCell>
                    </React.Fragment>
                  ) : null}
                </TableRow>
              ))}
              {isTableView && resourcesMeta?.TotalPages! > 1 && (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={props.numberOfTableColumns}
                  >
                    <Box display="flex" justifyContent="center">
                      <Pagination
                        count={resourcesMeta.TotalPages}
                        size="large"
                        page={resourcesMeta?.Page}
                        onChange={handlePaginationChange}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        ) : null}
      </div>
      {!isTableView ? (
        <EditResource
          hasEditAccess={hasEditAccess}
          resourceName={resourceName}
          resource={resources[activeResourceIndex]}
          resources={resources}
          saveResource={saveResource}
          deleteResource={deleteResource}
          updateListItems={getListInformation}
          isCreatingNew={isCreatingNew}
          createNewResource={createNewResource}
          abandonCreateNew={abandonCreateNew}
          isValidResource={isValidResource}
          resourceType={resourceType}
          renderEditComponent={renderEditComponent}
        />
      ) : null}
    </div>
  );
}

interface CreateNewRowProps {
  isTableView: boolean;
  isCreatingNew: boolean;
  resourceName: string;
  initializeCreateNewResource: () => void;
  numberOfTableColumns: number;
}
function CreateNewRow(props: CreateNewRowProps) {
  const classes = useStyles();
  if (props.isCreatingNew) {
    return null;
  }
  return (
    <TableRow
      key="createnewresource"
      hover={true}
      className={classes.createNewRow}
      onClick={e => props.initializeCreateNewResource()}
    >
      {props.isTableView ? (
        <React.Fragment>
          <TableCell
            align="center"
            colSpan={props.numberOfTableColumns}
            key="newtablerow"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <PlusIcon className={classes.plusIcon} />
              <strong>
                Create New {pluralize.singular(props.resourceName)}
              </strong>
            </Box>
          </TableCell>
        </React.Fragment>
      ) : (
        <TableCell
          key="newtablerow"
          classes={{ root: classes.createNewInSubMenu }}
        >
          Create New {pluralize.singular(props.resourceName)}
          <PlusIcon classes={{ root: classes.iconAlignMiddle }} />
        </TableCell>
      )}
    </TableRow>
  );
}

function mapStateToProps(state: AppReducerState) {
  return {
    activeTabId: state.tabs.tabsMeta.activeId,
    currentEnvironment: AuthService.getCurrentEnvironmentFromState(state),
    currentSellerOrgID: AuthService.getCurrentSellerOrgIDFromState(state),
    currentSellerToken: AuthService.getCurrentSellerOrgTokenFromState(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getSellerOrgImpersonationTokenAndAPIClients: (
      sellerOrgID: string,
      token: string,
      tabId: string
    ) => {
      dispatch(
        getSellerOrgImpersonationTokenAndAPIClients(sellerOrgID, token, tabId)
      );
    },
    getUpdatedSellerOrgTokenAndSetInRedux: (
      sellerOrgID: string,
      currentSellerToken?: string
    ) =>
      dispatch(
        AuthService.getUpdatedSellerOrgTokenAndSetInRedux(
          sellerOrgID,
          currentSellerToken
        )
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrettyResourceNavigation);
