export const API_CLIENTS = 'API_CLIENTS';
export type API_CLIENTS = typeof API_CLIENTS;

export const SECURITY_PROFILES = 'SECURITY_PROFILES';
export type SECURITY_PROFILES = typeof SECURITY_PROFILES;

export const WEBHOOKS = 'WEBHOOKS';
export type WEBHOOKS = typeof WEBHOOKS;

export const MESSAGE_SENDERS = 'MESSAGE_SENDERS';
export type MESSAGE_SENDERS = typeof MESSAGE_SENDERS;

export type PrettyResourceType =
  | API_CLIENTS
  | SECURITY_PROFILES
  | WEBHOOKS
  | MESSAGE_SENDERS;
