import React, { useCallback } from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';
import ForgotPassword from '../../Auth/ForgotPassword';
import Login from '../../Auth/Login';
import Register from '../../Auth/Register';
import ResetPassword from '../../Auth/ResetPassword';
import Setup from '../../Auth/Setup';
import { PortalAuth } from '../../Shared/hooks/usePortalAuth';
import UnlockAccount from '../../Auth/UnlockAccount';

interface UnauthenticatedProps {
  onLogin: PortalAuth['login'];
  onAccountLogin: PortalAuth['refresh'];
  loginAttempts: number;
}

export interface UnauthenticatedComponentProps
  extends RouteComponentProps<any, any, any> {
  onLogin: UnauthenticatedProps['onLogin'];
  onAccountLogin: UnauthenticatedProps['onAccountLogin'];
  loginAttempts: UnauthenticatedProps['loginAttempts'];
}

const UnauthenticatedRoutes = (props: UnauthenticatedProps) => {
  const { onLogin, onAccountLogin, loginAttempts } = props;

  const renderComponent = useCallback(
    (Component: React.ComponentType<any>) => (props: RouteComponentProps) => {
      return (
        <Component
          {...props}
          onLogin={onLogin}
          onAccountLogin={onAccountLogin}
          loginAttempts={loginAttempts}
        />
      );
    },
    [loginAttempts, onAccountLogin, onLogin]
  );

  return (
    <Switch>
      <Route path="/login" exact render={renderComponent(Login)} />
      <Route path="/register" exact render={renderComponent(Register)} />
      <Route
        path="/forgot-password"
        exact
        render={renderComponent(ForgotPassword)}
      />
      <Route exact path="/setup" render={renderComponent(Setup)} />
      <Route path="/reset-password" render={renderComponent(ResetPassword)} />
      <Route path="/unlock" render={renderComponent(UnlockAccount)} />
      <Redirect from="/" to="/login"></Redirect>
    </Switch>
  );
};

export default UnauthenticatedRoutes;
