import {
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  createStyles,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useContext } from 'react';
import { ApiSpecContext } from '../../../App/ApiSpecContext';
import { RedButton } from '../../../Styled/Button';
import CustomScrollbars from '../../../Styled/Scrollbars';
import FieldSection from './FieldSection';

interface RolesSelectorProps {
  elevatedRoles?: string[];
  customRoles?: string[];
  handleChangeValue: any;
  handleChangeObject: any;
  headerText: string;
  descriptionElement: any;
  showCustomRoles?: boolean;
  rolesFieldName: string;
  resourceUpdated?: any;
  vertical?: boolean;
  inRequestBody?: boolean;

  // readonly removes change functionality non-custom roles
  // readonly can be applied to custom roles in the future if needed
  readOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: (props: any) => ({
      margin: theme.spacing(0.25),
      height: props.plain ? theme.spacing(3) : undefined,
      '&.MuiChip-clickable.MuiChip-outlined:hover': {
        backgroundColor: theme.palette.grey[400],
      },
      '&.MuiChip-clickable.MuiChip-outlined.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
      '&.MuiChip-clickable.MuiChip-outlined:not(.Mui-selected)': {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: theme.palette.grey[400],
        },
      },
    }),
    textFieldInput: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    customRolesContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    container: {
      margin: theme.spacing(0, 0, 2),
      overflow: 'hidden',
      background: theme.palette.background.paper,
    },
    rolesContainer: (props: any) => ({
      border: `1px solid ${theme.palette.divider}`,
      borderTop: 0,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    }),
    rolesContainerCollapse: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      borderTop: `1px solid ${theme.palette.divider} !important`,
    },
    rolesInnerContainer: {
      display: 'grid',
      gap: theme.spacing(0.5),
      padding: theme.spacing(1),
      [theme.breakpoints.down('xl')]: {
        gridTemplateColumns: 'repeat( auto-fill, minmax(220px, 1fr) )',
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: 'repeat( auto-fill, minmax(190px, 1fr) )',
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    InputAdornmentContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
    },
    FilterByLabel: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    selectedSwitch: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 150,
      },
    },
    noRolesText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 100,
      fontSize: theme.typography.h5.fontSize,
    },
  })
);

function RolesSelector(props: RolesSelectorProps) {
  const [searchText, setSearchText] = useState('');
  const [viewingStatus, setViewingStatus] = useState('all');
  const [customRoleText, setCustomRoleText] = useState('');
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const [activeFilter, setActiveFilter] = useState<'Admin' | 'Reader' | null>(
    null
  );
  const classes = useStyles({ plain: props.inRequestBody });
  const customRoles = props.customRoles || [];
  const resourceUpdated = props.resourceUpdated || [];
  const { availableRoles } = useContext(ApiSpecContext);
  const elevatedRoles = useMemo(() => props.elevatedRoles || [], [
    props.elevatedRoles,
  ]);
  //toggles the name of the field we are editing depending of the
  //context of the component
  const fieldName = props.rolesFieldName;

  const toggleInclusion = role => {
    if (props.readOnly) {
      return;
    }
    if (elevatedRoles.includes(role)) {
      props.handleChangeValue(fieldName)(elevatedRoles.filter(r => r !== role));
    } else {
      props.handleChangeValue(fieldName)([...elevatedRoles, role]);
    }
  };
  const determineVisibileRoles = useCallback(() => {
    let roles = availableRoles;

    if (viewingStatus === 'onlySelected' || props.readOnly) {
      roles = roles.filter(r => elevatedRoles.includes(r));
    }

    roles = roles.filter(r =>
      r.toLowerCase().includes(searchText.toLowerCase())
    );

    if (activeFilter) {
      roles = roles.filter(r => r.includes(activeFilter));
    }

    return roles.sort((a, b) => {
      if (a === 'FullAccess') return -1;
      if (b === 'FullAccess') return 1;
      return a.localeCompare(b);
    });
  }, [
    availableRoles,
    elevatedRoles,
    props.readOnly,
    searchText,
    viewingStatus,
    activeFilter,
  ]);

  const visibleRoles = determineVisibileRoles();

  const handleFilterChange = (filter: 'Admin' | 'Reader') => () => {
    setActiveFilter(prevFilter => (prevFilter === filter ? null : filter));
  };

  const handleCustomRoleChange = e => {
    if (alreadyAdded) {
      setAlreadyAdded(false);
    }
    setCustomRoleText(e.target.value);
  };

  const addCustomRole = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!customRoles.includes(customRoleText)) {
      props.handleChangeValue('CustomRoles')([...customRoles, customRoleText]);
      setCustomRoleText('');
    } else {
      setAlreadyAdded(true);
    }
  };

  const removeCustomRole = (roleName: string) => {
    props.handleChangeValue('CustomRoles')(
      customRoles.filter(r => r !== roleName)
    );
  };

  const removeAllRoles = () => {
    const newRoleObject = {
      ...resourceUpdated,
      [fieldName]: [],
    };
    if (props.showCustomRoles) {
      newRoleObject.CustomRoles = [];
    }
    props.handleChangeObject(newRoleObject);
  };

  useEffect(() => {
    if (props.readOnly === true) {
      setViewingStatus('all');
      setSearchText('');
    }
  }, [props.readOnly]);

  const handleSwitchChange = event => {
    setViewingStatus(event.target.checked ? 'onlySelected' : 'all');
  };

  const ViewToggleSwitch = ({ status, onChange }) => {
    const isSelected = status === 'onlySelected';

    return (
      <FormControlLabel
        className={classes.selectedSwitch}
        control={
          <Switch checked={isSelected} onChange={onChange} color="primary" />
        }
        label={isSelected ? 'View All' : 'View Selected'}
      />
    );
  };

  return (
    <FieldSection
      headerText={props.headerText}
      infoText={props.descriptionElement()}
      vertical={props.vertical}
      plain={props.inRequestBody}
    >
      <div className={classes.container}>
        <Collapse in={!props.readOnly}>
          <TextField
            fullWidth
            id="filter-security-profiles"
            placeholder="Filter API roles by keyword"
            type="search"
            variant="outlined"
            onChange={e => setSearchText(e.target.value)}
            value={searchText}
            InputProps={{
              classes: {
                root: classes.textFieldInput,
              },
              startAdornment: (
                <InputAdornment
                  className={classes.InputAdornmentContainer}
                  position="start"
                >
                  <ViewToggleSwitch
                    status={viewingStatus}
                    onChange={handleSwitchChange}
                  />
                  <Typography className={classes.FilterByLabel}>
                    Filter by:{' '}
                  </Typography>
                  <ButtonGroup disableElevation color="default" size="small">
                    <Button
                      variant={
                        activeFilter === 'Admin' ? 'contained' : 'outlined'
                      }
                      onClick={handleFilterChange('Admin')}
                    >
                      Admin
                    </Button>
                    <Button
                      variant={
                        activeFilter === 'Reader' ? 'contained' : 'outlined'
                      }
                      onClick={handleFilterChange('Reader')}
                    >
                      Reader
                    </Button>
                  </ButtonGroup>
                </InputAdornment>
              ),
              endAdornment: !props.readOnly && (
                <InputAdornment position="end">
                  <RedButton
                    size="small"
                    variant="outlined"
                    onClick={removeAllRoles}
                  >
                    Clear
                  </RedButton>
                </InputAdornment>
              ),
            }}
          />
        </Collapse>
        <div
          className={`${classes.rolesContainer} ${
            props.readOnly ? classes.rolesContainerCollapse : ''
          }`}
        >
          <CustomScrollbars
            autoHide
            autoHeight
            autoHeightMin={props.inRequestBody ? 34 : 44}
            autoHeightMax={props.inRequestBody ? 300 : 'none'}
          >
            <div className={classes.rolesInnerContainer}>
              {props.showCustomRoles &&
                customRoles.map(role => (
                  <Chip
                    label={role}
                    key={role + 'c'}
                    clickable
                    onDelete={() => removeCustomRole(role)}
                    className={classes.chip}
                  />
                ))}
              {visibleRoles.map(role => (
                <Chip
                  label={role}
                  key={role}
                  clickable={!props.readOnly}
                  onClick={() => toggleInclusion(role)}
                  className={classes.chip}
                  color={elevatedRoles.includes(role) ? 'primary' : 'default'}
                  variant={
                    elevatedRoles.includes(role) ? 'default' : 'outlined'
                  }
                />
              ))}
            </div>
            {viewingStatus === 'onlySelected' && visibleRoles.length === 0 && (
              <Typography className={classes.noRolesText}>
                no roles selected
              </Typography>
            )}
          </CustomScrollbars>
        </div>
      </div>
      {props.showCustomRoles && !props.readOnly && (
        <form
          noValidate
          autoComplete="off"
          onSubmit={addCustomRole}
          className={classes.customRolesContainer}
        >
          <TextField
            label="Custom Roles"
            id="add-security-profile-field"
            placeholder="Enter custom role"
            variant="outlined"
            value={customRoleText}
            error={alreadyAdded}
            helperText={
              alreadyAdded && 'This custom role has already been added'
            }
            onChange={handleCustomRoleChange}
            margin="normal"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    color="primary"
                    type="submit"
                    variant="outlined"
                    size="small"
                  >
                    Add
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </form>
      )}{' '}
    </FieldSection>
  );
}

export default RolesSelector;
