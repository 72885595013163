import { createStyles, Link, makeStyles, Theme } from '@material-ui/core';
import { Accounts } from '@ordercloud/portal-javascript-sdk';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link as ReactRouterLink } from 'react-router-dom';
import * as yup from 'yup';
import appConstants from '../../config/app.constants';
import { Alert } from '../App/AlertContainer';
import FormSubmitButton from '../Shared/FormSubmitButton';
import FormTextField from '../Shared/FormTextField';
import AuthContainer from './AuthContainer';
import sharedStyles from '../Shared/SharedStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    backToLogin: {
      display: 'block',
      margin: 10,
    },
  })
);

const ForgotPassword = props => {
  const classes = useStyles(props);
  const [success, setSuccess] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const recaptchaKey = String(appConstants.recaptchaClientKey);

  if (success) {
    return (
      <AuthContainer>
        <h2>Forgot password email sent</h2>
        <p>
          Please check your email for further instructions to reset your
          password
        </p>
      </AuthContainer>
    );
  }
  return (
    <AuthContainer>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={yup.object({
          email: yup
            .string()
            .email('Invalid email address')
            .required('Email is required'),
        })}
        onSubmit={async ({ email }, { setSubmitting }) => {
          try {
            if (recaptchaKey && !recaptchaToken) {
              return Alert.error('Please verify that you are a human');
            }
            if (recaptchaKey) {
              await Accounts.ForgotPassword(
                { Email: email, Recaptcha: { Token: recaptchaToken } },
                { requestType: 'quiet' }
              );
              setSubmitting(false);
              setSuccess(true);
            }
          } catch (e) {
            if (
              (e as any) &&
              (e as any).data &&
              (e as any).data.Errors &&
              (e as any).data.Errors.length &&
              (e as any).data.Errors[0].ErrorCode === 'RecaptchaError'
            ) {
              Alert.error('Recaptcha validation failed');
            }
            setSubmitting(false);
            setSuccess(true);
          }
        }}
      >
        <Form>
          <p>Please provide the email associated with your account</p>
          <FormTextField
            name="email"
            label="Email"
            className={classes.textFields}
            autoFocus={true}
          />
          {recaptchaKey && (
            <ReCAPTCHA sitekey={recaptchaKey} onChange={setRecaptchaToken} />
          )}
          <FormSubmitButton
            className={classes.formButtons}
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </FormSubmitButton>
          <span className={classes.backToLogin}>
            <Link component={ReactRouterLink} to="/login">
              Back to Login
            </Link>
          </span>
        </Form>
      </Formik>
    </AuthContainer>
  );
};

export default ForgotPassword;
