import {
  RequiredDeep,
  ListPage,
  Organization,
} from '@ordercloud/portal-javascript-sdk';
import { emptySellerOrg } from '../contextSelections/contextSelections.constants';

// OPTIONS
export const SET_SELLER_ORGS = 'SET_SELLER_ORGS';
export type SET_SELLER_ORGS = typeof SET_SELLER_ORGS;

export const SET_API_CLIENTS = 'SET_API_CLIENTS';
export type SET_API_CLIENTS = typeof SET_API_CLIENTS;

export const SET_USERS = 'SET_USERS';
export type SET_USERS = typeof SET_USERS;

const initialMeta = {
  Page: 0,
  PageSize: 0,
  TotalCount: 0,
  TotalPages: 0,
  ItemRange: [0],
};

const initialSellerOrg = emptySellerOrg;

export const initialSellerOrgReducer: RequiredDeep<ListPage<Organization>> = {
  Meta: initialMeta,
  Items: [initialSellerOrg],
};
