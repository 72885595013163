import {
  Avatar,
  createStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Chip,
  Box,
  Button,
} from '@material-ui/core';
import { Folder } from '@material-ui/icons';
import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { AppReducerState } from '../../redux';
import {
  ContextChoice,
  ContextSelectionsReducerState,
} from '../../redux/contextSelections/contextSelections.types';
import { setTab } from '../../redux/tab/tab.actions';
import { ConsoleTab, TabsReducerState } from '../../redux/tab/tab.types';
import TabContextSnapShot from '../ApiConsole/TabContextSnapShot';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    primaryListText: {
      lineHeight: 1.3,
    },
  })
);

interface ApiConsoleListItemProps {
  tab: ConsoleTab;
  context?: ContextChoice;
  onOpenItem: (id: string) => void;
}

const ApiConsoleListItem: FC<ApiConsoleListItemProps> = (
  props: ApiConsoleListItemProps
) => {
  const { tab, context, onOpenItem } = props;
  const classes = useStyles();
  const handleOpenItem = () => {
    onOpenItem(tab.id);
  };
  return (
    <ListItem
      classes={{ root: classes.listItem }}
      button
      onClick={handleOpenItem}
    >
      <ListItemAvatar>
        <Avatar>
          <Folder />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        classes={{ primary: classes.primaryListText }}
        primary={
          <TabContextSnapShot
            tabContext={context}
            tab={tab}
            variant="inherit"
          />
        }
        secondary={tab.name}
      />
      {context && context.SELLER_ORG && context.SELLER_ORG.Environment && (
        <Box marginLeft={2}>
          <Chip size="small" label={context?.SELLER_ORG.Environment} />
        </Box>
      )}
    </ListItem>
  );
};

interface ListOwnProps {
  history: any;
  onTabClick?: () => void;
  onCreateClick?: () => void;
  showCreateButton?: boolean;
}

interface ListStateProps {
  tabState: TabsReducerState;
  contextState: ContextSelectionsReducerState;
}

interface ListDispatchProps {
  dispatchSetTab: (tabId: string) => any;
}

type ApiConsoleTabListProps = ListOwnProps & ListStateProps & ListDispatchProps;

const ApiConsoleTabList: FC<ApiConsoleTabListProps> = props => {
  const {
    history,
    dispatchSetTab,
    onTabClick,
    onCreateClick,
    showCreateButton,
  } = props;
  const { tabs, tabsMeta } = props.tabState;
  const { contextChoices } = props.contextState;

  const goToConsole = useCallback(() => {
    history.push('/console');
  }, [history]);

  const handleOpenTab = useCallback(
    (tabId: string) => {
      if (onTabClick) {
        onTabClick();
      }
      dispatchSetTab(tabId);
      goToConsole();
    },
    [dispatchSetTab, goToConsole, onTabClick]
  );

  return (
    <List disablePadding>
      <ListSubheader>
        Recent Tabs
        {showCreateButton && (
          <Button
            style={{ float: 'right', top: 8 }}
            variant="outlined"
            color="primary"
            onClick={onCreateClick}
          >
            New Tab
          </Button>
        )}
      </ListSubheader>
      {tabsMeta.allIds.map(tabId => (
        <ApiConsoleListItem
          key={tabId}
          tab={tabs[tabId]}
          context={contextChoices[tabId] && contextChoices[tabId].saved}
          onOpenItem={handleOpenTab}
        />
      ))}
    </List>
  );
};

function mapStateToProps(state: AppReducerState) {
  return { tabState: state.tabs, contextState: state.contextSelections };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetTab: (tabId: string) => dispatch(setTab(tabId)),
  };
}

export default connect<
  ListStateProps,
  ListDispatchProps,
  ListOwnProps,
  AppReducerState
>(
  mapStateToProps,
  mapDispatchToProps
)(ApiConsoleTabList);
