import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent, useMemo } from 'react';
import FieldSection from '../FieldSection';
import { isValidPasswordConfig, PasswordConfig2 } from './index';

interface EditPasswordConfigProps {
  data?: PasswordConfig2;
  readOnly?: boolean;
  handleChangeValue: (field: string) => (updated: PasswordConfig2) => void;
}

const EditPasswordConfig: FunctionComponent<EditPasswordConfigProps> = (
  props: EditPasswordConfigProps
) => {
  const { data, readOnly, handleChangeValue } = props;

  const errors = useMemo(() => {
    console.log('hit', data);
    return isValidPasswordConfig(data);
  }, [data]);

  const handleChangeEvent = (key: keyof PasswordConfig2) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    handleChangeValue('PasswordConfig')(
      data ? { ...data, [key]: e.target.value } : { [key]: e.target.value }
    );
  };

  const handleCheckboxChange = (key: keyof PasswordConfig2) => (
    e: any,
    checked: boolean
  ) => {
    handleChangeValue('PasswordConfig')(
      data ? { ...data, [key]: checked } : { [key]: checked }
    );
  };

  return (
    <FieldSection
      headerText="Password Configuration"
      infoText="Use this configuration to control password behavior for users that have this profile. These fields are inclusive when users are assigned to more than one profile"
    >
      <TextField
        fullWidth
        type="number"
        label="Minimum Character Count"
        helperText={
          errors.MinimumCharacterCount || 'The minimum length of a password.'
        }
        disabled={readOnly}
        error={Boolean(errors.MinimumCharacterCount)}
        inputProps={{
          readOnly: readOnly,
          step: 1,
          min: 8,
        }}
        value={(data && data.MinimumCharacterCount) || ''}
        margin="normal"
        onChange={handleChangeEvent('MinimumCharacterCount')}
        variant="outlined"
      />
      <TextField
        fullWidth
        type="number"
        label="Maximum Consecutive Duplicate Characters"
        helperText={
          errors.MaxConsecutiveDupeChars ||
          'The maximum number of consecutive repeating characters in a password.'
        }
        error={Boolean(errors.MaxConsecutiveDupeChars)}
        disabled={readOnly}
        inputProps={{
          readOnly: readOnly,
          step: 1,
          min: 1,
          max: 24,
        }}
        value={(data && data.MaxConsecutiveDupeChars) || ''}
        margin="normal"
        onChange={handleChangeEvent('MaxConsecutiveDupeChars')}
        variant="outlined"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={readOnly}
            checked={data && data.UpperCaseRequired}
            onChange={handleCheckboxChange('UpperCaseRequired')}
          />
        }
        label="Upper Case Required"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={readOnly}
            checked={data && data.LowerCaseRequired}
            onChange={handleCheckboxChange('LowerCaseRequired')}
          />
        }
        label="Lower Case Required"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={readOnly}
            checked={data && data.SpecialCharacterRequired}
            onChange={handleCheckboxChange('SpecialCharacterRequired')}
          />
        }
        label="Special Character Required"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={readOnly}
            checked={data && data.NumericRequired}
            onChange={handleCheckboxChange('NumericRequired')}
          />
        }
        label="Numeric Character Required"
      />
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            fullWidth
            type="number"
            label="Minimum Password Age"
            helperText={
              errors.MinimumPasswordAge ||
              'The password may not be changed again until this number of minutes has passed.'
            }
            error={Boolean(errors.MinimumPasswordAge)}
            disabled={readOnly}
            InputProps={{
              readOnly: readOnly,
              endAdornment: (
                <InputAdornment position="end">Minutes</InputAdornment>
              ),
            }}
            inputProps={{
              step: 1,
              min: 1,
              max: 60,
            }}
            value={(data && data.MinimumPasswordAge) || ''}
            margin="normal"
            onChange={handleChangeEvent('MinimumPasswordAge')}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            type="number"
            label="Maximum Password Age"
            helperText={
              errors.MaximumPasswordAge ||
              'The password expires after MaximumPasswordAge in days.'
            }
            error={Boolean(errors.MaximumPasswordAge)}
            disabled={readOnly}
            InputProps={{
              readOnly: readOnly,
              endAdornment: (
                <InputAdornment position="end">Days</InputAdornment>
              ),
            }}
            inputProps={{
              step: 1,
              min: 1,
              max: 365,
            }}
            value={(data && data.MaximumPasswordAge) || ''}
            margin="normal"
            onChange={handleChangeEvent('MaximumPasswordAge')}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <TextField
        fullWidth
        type="number"
        label="Limit Password Reuse"
        helperText={
          errors.LimitPasswordReuse ||
          'The number of previous passwords that are blocked from reuse.'
        }
        error={Boolean(errors.LimitPasswordReuse)}
        disabled={readOnly}
        inputProps={{
          readOnly: readOnly,
          step: 1,
          min: 0,
          max: 24,
        }}
        value={(data && data.LimitPasswordReuse) || ''}
        margin="normal"
        onChange={handleChangeEvent('LimitPasswordReuse')}
        variant="outlined"
      />
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <TextField
            fullWidth
            type="number"
            label="Allowed Failed Attempts"
            helperText={
              errors.AllowedFailedAttempts ||
              'The number of failed attempts before the account is Locked for the LockoutDuration.'
            }
            error={Boolean(errors.AllowedFailedAttempts)}
            disabled={readOnly}
            inputProps={{
              readOnly: readOnly,
              step: 1,
              min: 1,
              max: 10,
            }}
            value={(data && data.AllowedFailedAttempts) || ''}
            margin="normal"
            onChange={handleChangeEvent('AllowedFailedAttempts')}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            type="number"
            label="Lockout Duration"
            helperText={
              errors.LockoutDuration ||
              'The number of minutes an account is locked when the AllowedFailedAttempts is reached.'
            }
            error={Boolean(errors.LockoutDuration)}
            disabled={readOnly}
            InputProps={{
              readOnly: readOnly,
              endAdornment: (
                <InputAdornment position="end">Minutes</InputAdornment>
              ),
            }}
            inputProps={{
              step: 1,
              min: 10,
              max: 1440,
            }}
            value={(data && data.LockoutDuration) || ''}
            margin="normal"
            onChange={handleChangeEvent('LockoutDuration')}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </FieldSection>
  );
};

export default EditPasswordConfig;
