import React from 'react';
import { Button, TextField, IconButton } from '@material-ui/core';
import FieldSection from '../FieldSection';
import { generateRandomString } from '../../../../../services/utilites.helper';
import { Clear } from '@material-ui/icons';

interface ClientSecretProps {
  clientSecret: string;
  readOnly?: boolean;
  onChange: (event: any) => void;
}

function ClientSecret(props: ClientSecretProps) {
  const genereateAndSetRandomClientSecret = () => {
    props.onChange({ target: { value: generateRandomString(60) } });
  };

  const handleClearClientSecret = () => {
    props.onChange({ target: { value: null } });
  };

  return (
    <FieldSection
      headerText="Client Secret"
      infoText="Enables the OAuth 2.0 Client Credentials grant type. Required on all
    OAuth workflows when present."
    >
      <TextField
        disabled={props.readOnly}
        InputProps={{
          readOnly: props.readOnly,
          endAdornment: Boolean(
            props.clientSecret && props.clientSecret.length
          ) && (
            <IconButton onClick={handleClearClientSecret}>
              <Clear />
            </IconButton>
          ),
        }}
        fullWidth
        id="client-secret-field"
        label="Enter client secret"
        variant="outlined"
        value={props.clientSecret || ''}
        onChange={props.onChange}
        margin="normal"
      />
      {!props.readOnly && (
        <Button
          color="default"
          type="submit"
          variant="contained"
          onClick={genereateAndSetRandomClientSecret}
        >
          Generate Random Client Secret
        </Button>
      )}
    </FieldSection>
  );
}

export default ClientSecret;
