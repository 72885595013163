import {
  setAPIClients,
  setSellerOrgImpersonationToken,
  setSellerOrgs,
  setUsers,
} from '../contextSelections/contextObjectConstructor.actions';
import asyncWrapper from '../requestStatus/thunkWrapper.actions';
import {
  GETTING_SELLER_ORGS,
  GETTING_SELLER_ORG_IMPERSONATION_TOKEN,
  GETTING_API_CLIENTS,
  GETTING_USERS,
  GETTING_USER_IMPERSONATION_TOKEN,
} from '../requestNames.constants';
import { ImpersonationToken } from './contextOptions.types';
// import { getCurrentContext } from '../contextSelections/contextSelections.helpers';
import { destroyTabs, setTabToken } from '../tab/tab.actions';
import AuthService from '../../services/auth.service';
import { ContextFields } from '../contextSelections/contextSelections.helpers';
import { Organizations, ApiClients } from '@ordercloud/portal-javascript-sdk';

export function getSellerOrgs(
  pageNumber: number = 1,
  searchText: string = '',
  tabID: string = ''
) {
  return dispatch => {
    asyncWrapper(dispatch, GETTING_SELLER_ORGS, async () => {
      const orgListResponse = await Organizations.List({
        search: searchText,
        page: pageNumber,
      });

      const isSearchResult = searchText.length > 0;
      dispatch(setSellerOrgs(orgListResponse, tabID, isSearchResult));
    });
  };
}

export function getSellerOrgImpersonationTokenIfExpired(
  sellerOrgID: string,
  sellerOrgImpersonationToken: ImpersonationToken = ''
) {
  return async dispatch => {
    if (AuthService.isTokenExpired(sellerOrgImpersonationToken)) {
      await asyncWrapper(
        dispatch,
        GETTING_SELLER_ORG_IMPERSONATION_TOKEN,
        async () => {
          const tokenResponse = await ApiClients.GetToken(sellerOrgID);
          sellerOrgImpersonationToken = tokenResponse.access_token;
          dispatch(
            setSellerOrgImpersonationToken(
              sellerOrgID,
              sellerOrgImpersonationToken
            )
          );
        }
      );
    }
  };
}

export function getSellerOrgImpersonationTokenAndAPIClients(
  sellerOrgID: string,
  sellerOrgImpersonationToken: ImpersonationToken = '',
  tabID: string,
  searchText: string = ''
) {
  // accepts active tabID for search calls
  return async dispatch => {
    // if there is no valid token in redux, get it
    if (AuthService.isTokenExpired(sellerOrgImpersonationToken)) {
      await asyncWrapper(
        dispatch,
        GETTING_SELLER_ORG_IMPERSONATION_TOKEN,
        async () => {
          const tokenResponse = await ApiClients.GetToken(sellerOrgID);
          sellerOrgImpersonationToken = tokenResponse.access_token;
          dispatch(
            setSellerOrgImpersonationToken(
              sellerOrgID,
              sellerOrgImpersonationToken
            )
          );
        }
      );
    }

    dispatch(setTabToken(tabID, sellerOrgImpersonationToken));
    dispatch(
      getAPIClients(sellerOrgImpersonationToken, sellerOrgID, tabID, searchText)
    );
  };
}

export function getAPIClients(
  sellerOrgImpersonationToken: ImpersonationToken,
  sellerOrgID: string,
  tabID: string,
  searchText: string = '',
  pageNumber: number = 1
) {
  return async dispatch => {
    asyncWrapper(dispatch, GETTING_API_CLIENTS, async () => {
      const apiClientsResponse = await ApiClients.List(sellerOrgID, {
        search: searchText,
        page: pageNumber,
      });
      const isSearchResult = searchText.length > 0;
      dispatch(
        setAPIClients(sellerOrgID, apiClientsResponse, tabID, isSearchResult)
      );
    });
  };
}

export function getUserList(
  sellerOrgID: string,
  clientID: string,
  pageNumber: number = 1,
  tabID: string = '',
  searchText: string = ''
) {
  return async dispatch => {
    if (searchText.length) {
      asyncWrapper(dispatch, GETTING_USERS, async () => {
        const usersResponse = await ApiClients.ListUsers(
          sellerOrgID,
          clientID,
          {
            search: searchText,
            page: pageNumber,
          }
        );
        dispatch(setUsers(usersResponse, tabID));
      });
    }
  };
}

export function getAndSaveTabToken(tabID: string, context: ContextFields) {
  return async dispatch => {
    asyncWrapper(dispatch, GETTING_USER_IMPERSONATION_TOKEN, async () => {
      let userTokenResponse;
      if (context.sellerOrgID && !context.username) {
        try {
          userTokenResponse = await ApiClients.GetToken(context.sellerOrgID);
        } catch (ex) {
          dispatch(destroyTabs([tabID]));
        }
      } else {
        try {
          userTokenResponse = await ApiClients.GetToken(context.sellerOrgID, {
            clientid: context.clientID,
            username: context.username,
          });
        } catch (ex) {
          dispatch(destroyTabs([tabID]));
        }
      }
      dispatch(setTabToken(tabID, userTokenResponse.access_token));
    });
  };
}
