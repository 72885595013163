import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ApiClientAssignment } from 'ordercloud-javascript-sdk';
import AuthService from '../../../../../services/auth.service';
import { connect } from 'react-redux';
import { AppReducerState } from '../../../../../redux';
import {
  Typography,
  ListItemText,
  ListItem,
  Checkbox,
  List,
  Chip,
} from '@material-ui/core';
import { maroon } from '../../../../../themes/ocPalette.constants';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { teal } from '@material-ui/core/colors';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemRoot: {
      padding: theme.spacing(1, 2),
      color: 'rgba(0, 0, 0, 0.87)',
    },
    container: {
      position: 'relative',
    },
    resultsContainer: {
      height: 250,
      flexGrow: 1,
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing(2),
    },
    secondaryText: {
      fontFamily: 'monospace',
      color: maroon[500],
    },
    chip: {
      marginRight: '2px',
      marginTop: '2px',
    },
    chipContainer: {
      height: '120px !important',
      border: `2px dotted ${teal[200]}`,
      borderRadius: theme.spacing(2),
      padding: '3px',
    },
    noUserSelected: {
      marginTop: theme.spacing(2),
      color: theme.palette.error.light,
    },
  })
);

type UserType = 'Buyer' | 'Supplier';
interface AssignmentSearchProps {
  readOnly?: boolean;
  toggleUser: (userID: string) => void;
  userType: UserType;
  currentSellerOrgToken?: string;
  apiClientAssignmentsUpdated: ApiClientAssignment[];
  ocUserService: any;
}

function AssignmentSearch(props: AssignmentSearchProps) {
  const classes = useStyles();

  const {
    readOnly,
    userType,
    toggleUser,
    currentSellerOrgToken,
    apiClientAssignmentsUpdated,
    ocUserService,
  } = props;

  const [searchResults, setSearchResults] = useState([] as any[]);
  const [currentUsers, setCurrentUsers] = useState([] as any[]);

  const getSearchResults = async (event: any) => {
    const userResponse = await ocUserService.List(
      { search: event.target.value, pageSize: 100 },
      { accessToken: currentSellerOrgToken }
    );
    setSearchResults(userResponse.Items);
  };

  const getAssignedUsers = useCallback(async () => {
    const idsString = apiClientAssignmentsUpdated
      .map(a => a[`${userType}ID`])
      .join('|');
    const buyersResponse = await ocUserService.List(
      { pageSize: 100, filters: { ID: idsString } },
      { accessToken: currentSellerOrgToken }
    );
    setCurrentUsers(buyersResponse.Items);
    setHasRetrievedAssignedUsers(true);
  }, [
    apiClientAssignmentsUpdated,
    currentSellerOrgToken,
    ocUserService,
    userType,
  ]);

  const addUserToCurrentUsers = (user: any) => {
    setCurrentUsers([...currentUsers, user]);
  };

  const [hasRetrievedAssignedUsers, setHasRetrievedAssignedUsers] = useState(
    false
  );

  useEffect(() => {
    if (
      !hasRetrievedAssignedUsers &&
      apiClientAssignmentsUpdated.length &&
      currentSellerOrgToken
    ) {
      getAssignedUsers();
    }
  }, [
    hasRetrievedAssignedUsers,
    apiClientAssignmentsUpdated,
    getAssignedUsers,
    currentSellerOrgToken,
  ]);

  useEffect(() => {
    if (!currentSellerOrgToken) return;
    const getAssignmentsAndUsers = async () => {
      const userResponse = await ocUserService.List(
        { pageSize: 100 },
        { accessToken: currentSellerOrgToken }
      );
      setSearchResults(userResponse.Items);
    };
    getAssignmentsAndUsers();
  }, [currentSellerOrgToken, ocUserService]);

  const currentIDs =
    (apiClientAssignmentsUpdated &&
      apiClientAssignmentsUpdated.map(a => a[`${userType}ID`])) ||
    [];

  return (
    <React.Fragment>
      <Scrollbars className={classes.chipContainer}>
        {apiClientAssignmentsUpdated
          .filter(a => a[`${userType}ID`])
          .map(a => {
            const user =
              currentUsers &&
              currentUsers.find(u => u.ID === a[`${userType}ID`]);
            return (
              user &&
              (readOnly ? (
                <Chip
                  key={user.ID}
                  className={classes.chip}
                  label={user.Name}
                  size="small"
                  color="secondary"
                />
              ) : (
                <Chip
                  key={user.ID}
                  className={classes.chip}
                  label={user.Name}
                  size="small"
                  onClick={() => toggleUser(user.ID)}
                  onDelete={() => toggleUser(user.ID)}
                  color="secondary"
                />
              ))
            );
          })}
        {!apiClientAssignmentsUpdated.filter(a => a[`${userType}ID`])
          .length && (
          <Typography
            variant="body1"
            classes={{ root: classes.noUserSelected }}
          >
            <CheckBoxIcon
              fontSize="small"
              color="error"
              style={{ verticalAlign: 'middle' }}
            />
            {'     '} Make sure to select specific {userType} users below
          </Typography>
        )}
      </Scrollbars>
      {!readOnly && (
        <React.Fragment>
          <TextField
            fullWidth
            label={`Search for ${userType} Users`}
            onChange={e => getSearchResults(e)}
            margin="normal"
            variant="outlined"
          />
          <Scrollbars
            renderTrackHorizontal={props => (
              <div
                {...props}
                style={{ display: 'none' }}
                className="track-horizontal"
              />
            )}
            style={{ height: 275 }}
          >
            <List>
              {searchResults.map((result, index) => (
                <ListItem
                  button
                  key={index}
                  className={classes.listItemRoot}
                  onClick={() => {
                    addUserToCurrentUsers(result);
                    toggleUser(result.ID);
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" noWrap title={result.Name}>
                        {result.Name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        noWrap
                        variant="body2"
                        className={classes.secondaryText}
                      >
                        {result.ID}
                      </Typography>
                    }
                  />
                  <Checkbox checked={currentIDs.includes(result.ID)} />
                </ListItem>
              ))}
            </List>
          </Scrollbars>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function mapStateToProps(state: AppReducerState) {
  return {
    currentSellerOrgToken: AuthService.getCurrentSellerOrgTokenFromState(state),
  };
}

export default connect(mapStateToProps)(AssignmentSearch);
