import {
  createStyles,
  Drawer,
  Link,
  List,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {
  CodeOutlined,
  CodeTwoTone,
  DashboardOutlined,
  DashboardTwoTone,
  PeopleOutlined,
  PeopleOutlineTwoTone,
  SettingsOutlined,
  SettingsTwoTone,
  WorkOutline,
  WorkTwoTone,
} from '@material-ui/icons';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { CurrentUser } from '@ordercloud/portal-javascript-sdk';
import { createTab } from '../../redux/tab/tab.actions';
import ocTheme from '../../themes/theme.constants';
import ApiConsoleTabList from '../Shared/ApiConsoleTabList';
import ListItemNavLink from '../Shared/ListItemNavLink';
import { navHeight, navHeightMobile } from './Header';
import {
  setMarketplaceInvitations,
  setMarketplaceTeamInvitations,
  setTeamInvitations,
} from '../../redux/invitations/invitations.actions';

interface PortalNavigationProps {
  permanent?: boolean;
  width?: number;
  history: any;
  open: boolean;
  onSetMenu: (open: boolean) => void;
}

interface DispatchProps {
  dispatchCreateTab: () => void;
  dispatchSetMarketplaceInvitations: (invitations) => void;
  dispatchSetMarketplaceTeamInvitations: (invitations) => void;
  dispatchSetTeamInvitations: (invitations) => void;
}

interface PortalNavigationStyleProps {
  width: number;
  permanent?: boolean;
  shouldShrink?: boolean;
}

type PortalNavProps = PortalNavigationProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemNavLink: {
      '& .MuiChip-root': {
        color: 'white',
        backgroundColor: theme.palette.error.main,
        border: 'none',
        height: 20,
        minWidth: 20,
        '& .MuiChip-label': {
          fontSize: 12,
          fontWeight: theme.typography.fontWeightBold,
          textOverflow: 'initial',
          whiteSpace: 'initial',
          overflow: 'initial',
          padding: theme.spacing(0, 0.5),
        },
      },
    },
    shrinkListItemNavLink: {
      '& .MuiChip-root': {
        color: 'white',
        backgroundColor: theme.palette.error.main,
        border: 'none',
        position: 'absolute',
        top: '1px',
        right: '6px',
        minWidth: '15px',
        height: '15px',
        '& .MuiChip-label': {
          fontSize: 12,
          fontWeight: theme.typography.fontWeightBold,
          textOverflow: 'initial',
          whiteSpace: 'initial',
          overflow: 'initial',
          padding: theme.spacing(0, 0.5),
        },
      },
    },
    drawerRoot: {
      top: `${navHeightMobile}px !important`,
      [theme.breakpoints.up('sm')]: {
        top: `${navHeight}px !important`,
      },
    },
    drawer: ({ width }: PortalNavigationStyleProps) => ({
      transition: `${theme.transitions.create('width')} !important`,
      width,
    }),
    drawerPaper: ({ width }: PortalNavigationStyleProps) => ({
      transition: `${theme.transitions.create('width')} !important`,
      width,
      overflowY: 'visible',
      overflowX: 'hidden',
      borderRight: `1px solid ${theme.palette.divider}`,
      top: navHeightMobile,
      height: `calc(100% - ${navHeight}px)`,
      background: theme.palette.background.paper,
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        top: navHeight,
      },
    }),
    drawerPaperShrink: {
      width: `${MINI_PORTAL_NAVIGATION_WIDTH}px !important`,
    },
    grow: {
      flexGrow: 1,
    },
    gravatarContainer: (props: PortalNavigationStyleProps) => ({
      background: theme.palette.action.hover,
      position: 'relative',
      borderRadius: '50%',
      transition: theme.transitions.create(['width', 'height']),
      width: props.shouldShrink ? 36 : 100,
      height: props.shouldShrink ? 36 : 100,
      margin: `0 auto ${theme.spacing(1)}px`,
      overflow: 'hidden',
      '& > a': {
        display: props.shouldShrink ? 'none' : undefined,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: 40,
        transition: theme.transitions.create('transform'),
        transform: 'translateY(40px)',
        ...theme.typography.caption,
        color: theme.palette.common.white,
        backgroundColor: 'rgba(0,0,0,0.5)',
        textDecoration: 'none',
        lineHeight: '12px',
        paddingTop: theme.spacing(0.5),
      },
      '&:hover > a': {
        transform: 'translateY(0)',
      },
    }),
  })
);

export const DEFAULT_PORTAL_NAVIGATION_WIDTH = 250;
export const MINI_PORTAL_NAVIGATION_WIDTH = ocTheme.spacing(7);

const PortalNavigation = (props: PortalNavProps) => {
  const {
    permanent,
    width,
    history,
    open,
    onSetMenu,
    dispatchCreateTab,
    dispatchSetMarketplaceInvitations,
    dispatchSetMarketplaceTeamInvitations,
    dispatchSetTeamInvitations,
  } = props;

  const shouldShrink = useMemo(() => {
    return !permanent && !open;
  }, [permanent, open]);
  const classes = useStyles({
    permanent,
    shouldShrink,
    width: width || DEFAULT_PORTAL_NAVIGATION_WIDTH,
  });
  let hoverTimeout: NodeJS.Timeout;

  useEffect(() => {
    onSetMenu(!!permanent);
  }, [onSetMenu, permanent]);

  const handleBackdropClick = (event, reason) => {
    if (reason === 'backdropClick') {
      onSetMenu(false);
    }
  };

  const onMouseEnter = () => {
    if (permanent) return;
    hoverTimeout = setTimeout(() => {
      onSetMenu(true);
    }, 500);
  };

  const onMouseLeave = () => {
    if (permanent) return;
    hoverTimeout ? clearTimeout(hoverTimeout) : onSetMenu(false);
  };

  const handleCreateTab = useCallback(() => {
    dispatchCreateTab();
    history.push('/console');
  }, [dispatchCreateTab, history]);

  React.useEffect(() => {
    CurrentUser.ListTeamInvites().then((teamInvites: any) => {
      dispatchSetTeamInvitations(teamInvites.Items);
    });
  }, [dispatchSetTeamInvitations]);

  React.useEffect(() => {
    CurrentUser.ListOrgInvites().then((orgInvites: any) => {
      dispatchSetMarketplaceInvitations(orgInvites.Items);
    });
    CurrentUser.ListOrgTeamInvites().then((orgTeamInvites: any) => {
      dispatchSetMarketplaceTeamInvitations(orgTeamInvites.Items);
    });
  }, [
    dispatchSetMarketplaceInvitations,
    dispatchSetMarketplaceTeamInvitations,
  ]);

  const invitations = useSelector((state: any) => {
    return state?.invitations;
  });

  const orgInviteCount = React.useMemo(() => {
    let result = 0;

    if (
      invitations?.marketplaceInvitations &&
      invitations?.marketplaceInvitations?.length > 0
    ) {
      result += invitations.marketplaceInvitations.length;
    }
    if (
      invitations?.marketplaceTeamInvitations &&
      invitations.marketplaceTeamInvitations.length > 0
    ) {
      result += invitations.marketplaceTeamInvitations.length;
    }
    return result;
  }, [invitations]);

  const teamInviteCount = React.useMemo(() => {
    let result = 0;

    if (
      invitations?.teamInvitations &&
      invitations.teamInvitations.length > 0
    ) {
      result += invitations.teamInvitations.length;
    }
    return result;
  }, [invitations]);

  return (
    <Drawer
      style={{ zIndex: 1299 }}
      elevation={permanent ? 0 : 3}
      open={true}
      className={`${classes.drawer} ${
        shouldShrink ? classes.drawerPaperShrink : ''
      }`}
      classes={{
        root: classes.drawerRoot,
        paper: `${classes.drawerPaper} ${
          shouldShrink ? classes.drawerPaperShrink : ''
        }`,
      }}
      PaperProps={{
        onMouseEnter,
        onMouseLeave,
      }}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: classes.drawerRoot,
          },
        },
        hideBackdrop: permanent || shouldShrink,
        disableEnforceFocus: permanent || shouldShrink,
        onClose: permanent || shouldShrink ? undefined : handleBackdropClick,
      }}
    >
      <List disablePadding>
        <ListItemNavLink
          to="/"
          exact
          icon={<DashboardOutlined />}
          activeIcon={<DashboardTwoTone />}
          primary="Dashboard"
        />
        <ListItemNavLink
          className={`${
            shouldShrink
              ? classes.shrinkListItemNavLink
              : classes.listItemNavLink
          }`}
          to="/marketplaces"
          icon={<WorkOutline />}
          activeIcon={<WorkTwoTone />}
          primary="Marketplaces"
          badge={orgInviteCount || ''} // only show if > 0
        />
        <ListItemNavLink
          className={`${
            shouldShrink
              ? classes.shrinkListItemNavLink
              : classes.listItemNavLink
          }`}
          to="/teams"
          icon={<PeopleOutlined />}
          activeIcon={<PeopleOutlineTwoTone />}
          primary="Teams"
          badge={teamInviteCount || ''} // only show if > 0
        />
        <ListItemNavLink
          to="/console"
          icon={<CodeOutlined />}
          activeIcon={<CodeTwoTone />}
          primary="API Console"
          disableMenu={!permanent}
          menu={menuProps => (
            <Fragment>
              <ApiConsoleTabList
                showCreateButton={true}
                onCreateClick={handleCreateTab}
                history={history}
                onTabClick={menuProps.closeMenu}
              />
            </Fragment>
          )}
        />
        <ListItemNavLink
          to="/settings"
          icon={<SettingsOutlined />}
          activeIcon={<SettingsTwoTone />}
          primary="Settings"
        />
      </List>
      <div className={classes.grow}></div>
      <Link
        align="center"
        href="https://support.sitecore.com/status"
        target="_blank"
      >
        Status Page
      </Link>
      <Link
        align="center"
        href="https://www.sitecore.com/trust/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </Link>
    </Drawer>
  );
};

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    dispatchSetMarketplaceInvitations: (invitations: any) =>
      dispatch(setMarketplaceInvitations(invitations)),
    dispatchSetMarketplaceTeamInvitations: (invitations: any) =>
      dispatch(setMarketplaceTeamInvitations(invitations)),
    dispatchSetTeamInvitations: (invitations: any) =>
      dispatch(setTeamInvitations(invitations)),
    dispatchCreateTab: () => dispatch(createTab()),
  };
}

export default connect(null, mapDispatchToProps)(PortalNavigation);
