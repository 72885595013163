import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { teal, grey } from '@material-ui/core/colors';
import { MessageSender } from 'ordercloud-javascript-sdk';
import FieldSection from '../FieldSection';
import {
  determineMessageSenderType,
  DEFAULT,
  MessageSenderType,
  orderCloudMandrillIntegrationURL,
  CUSTOM_URL,
  CUSTOM_MANDRILL_ACCOUNT,
  MessageSenderTypeAttributes,
} from './constants';
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemRoot: {
      padding: theme.spacing(1, 2),
      color: 'rgba(0, 0, 0, 0.87)',
    },
    listItemRootActive: {
      padding: theme.spacing(1, 2),
      color: 'rgba(0, 0, 0, 0.87)',
      border: `2px ${teal[200]} dashed`,
      borderRadius: '4px',
    },
    formLabelHiding: {
      display: 'none',
    },
    messageTypeSubText: {
      marginLeft: theme.spacing(2),
      color: grey[400],
    },
  })
);

interface MessageTypeSelectorProps {
  readOnly?: boolean;
  messageSenderUpdated: Partial<MessageSender>;
  handleChangeObject: (newValue: any) => void;
}

function MessageTypeSelector(props: MessageTypeSelectorProps) {
  const classes = useStyles();

  const currentlySelectedMessageType = determineMessageSenderType(
    props.messageSenderUpdated
  );
  const { handleChangeObject, messageSenderUpdated, readOnly } = props;

  const updateMessageSenderForNewType = (
    messageSenderType: MessageSenderType
  ) => {
    // only update the message type on changes
    if (currentlySelectedMessageType !== messageSenderType) {
      switch (messageSenderType) {
        case DEFAULT:
          handleChangeObject({
            ...messageSenderUpdated,
            URL: orderCloudMandrillIntegrationURL,
            xp: getXpWithoutApiKey(messageSenderUpdated),
          });
          break;
        case CUSTOM_MANDRILL_ACCOUNT:
          handleChangeObject({
            ...messageSenderUpdated,
            URL: orderCloudMandrillIntegrationURL,
            xp: {
              ...messageSenderUpdated.xp,
              ApiKey: '',
            },
          });
          break;
        case CUSTOM_URL:
          handleChangeObject({
            ...messageSenderUpdated,
            URL: '',
            xp: getXpWithoutApiKey(messageSenderUpdated),
          });
          break;
      }
    }
  };

  const getXpWithoutApiKey = (messageSender: Partial<MessageSender>) => {
    const messageSenderXPCopy = cloneDeep(messageSender.xp);
    if (typeof messageSenderXPCopy.ApiKey !== 'undefined') {
      delete messageSenderXPCopy.ApiKey;
    }
    return messageSenderXPCopy;
  };

  return (
    <FieldSection
      headerText="Configuration Type"
      infoText="OrderCloud message senders can be configured in the three ways shown below. With each of these options the object sent with the request will include the information described in the message options."
    >
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.formLabelHiding}>
          Message Sender Type
        </FormLabel>
        <RadioGroup
          aria-label="message sender type"
          name="messageSenderType"
          value={currentlySelectedMessageType}
          onChange={event =>
            updateMessageSenderForNewType(
              (event.target as HTMLInputElement).value as MessageSenderType
            )
          }
        >
          {Object.entries(MessageSenderTypeAttributes).map(
            ([messageType, messageSenderTypeAttribute]) => {
              return (
                <FormControlLabel
                  key={messageType}
                  value={messageType}
                  control={
                    <Radio
                      color="primary"
                      readOnly={readOnly}
                      disabled={readOnly}
                    />
                  }
                  label={
                    <MessageTypeLabel
                      messageSenderTypeAttributes={messageSenderTypeAttribute}
                    />
                  }
                  labelPlacement="end"
                />
              );
            }
          )}
        </RadioGroup>
      </FormControl>
    </FieldSection>
  );
}

interface MessageTypeLabelProps {
  messageSenderTypeAttributes: MessageSenderTypeAttributes;
}

const MessageTypeLabel = (props: MessageTypeLabelProps) => {
  const { Name, Description } = props.messageSenderTypeAttributes;
  const classes = useStyles();

  return (
    <span>
      <strong>{Name}</strong>
      <span className={classes.messageTypeSubText}></span>
      {Description}
    </span>
  );
};

export default MessageTypeSelector;
