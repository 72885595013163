/**
 * Used for testing purposes. Per cypress best-practices any element that needs to
 * be referenced in the test project should have a 'data-test-id' attribute set
 *
 * the values for the attributes stored here will be referenced
 * by the main project as well as the testing project
 */

const TestIds = {
  login: {
    email: 'email',
    password: 'password',
  },
  tabs: {
    newTabBtn: 'newTabBtn',
    tabsWrapper: 'tabsWrapper',
    tabPrefix: 'tab_',
    tabActionsMenu: {
      title: 'tab_actions_menu',
      actions: {
        close: 'close',
        closeOthers: 'closeOthers',
        closeToRight: 'close_to_right',
        closeToLeft: 'close_to_left',
        closeAll: 'close_all',
      },
    },
  },
};

export default TestIds;
