import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  createStyles,
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Paper,
  Button,
  InputAdornment,
  Typography,
  Input,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AuthService from '../../../../../services/auth.service';
import { AppReducerState } from '../../../../../redux';
import { connect } from 'react-redux';
import {
  XpIndices as ocXpIndices,
  XpIndex,
  XpThingType,
} from 'ordercloud-javascript-sdk';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {
  REQUEST_NOT_MADE,
  ResourceRequestStatus,
  RoleComparison,
  SafelyGetResource,
} from '../requestStatus';
import { RolesRequiredComparison } from '../RolesRequiredComparison';
import SaveIcon from '@material-ui/icons/Save';
import DiscardIcon from '@material-ui/icons/Delete';
import cloneDeep from 'lodash/cloneDeep';
import { ApiClients } from '@ordercloud/portal-javascript-sdk';
import { ApiSpecContext } from '../../../../App/ApiSpecContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    chip: {
      marginRight: '2px',
      marginTop: '3px',
    },
    chipInputAdornmentForm: {
      marginRight: 0,
      color: theme.palette.secondary.main,
    },
    chipInput: {
      color: theme.palette.secondary.main,
    },
    informationalText: {
      padding: theme.spacing(2),
    },
    tableCell: {
      paddingTop: '3px',
      paddingBottom: '3px',
    },
    tableCellChipContainer: {
      padding: theme.spacing(2),
    },
    discardButton: {
      marginLeft: theme.spacing(1),
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      '&:hover': {
        borderColor: theme.palette.error.dark,
        color: theme.palette.error.dark,
      },
    },
  })
);

interface XpIndicesProps {
  currentSellerToken: string;
  currentSellerOrgID: string;
  getUpdatedSellerOrgTokenAndSetInRedux: (
    sellerOrgID: string,
    currentSellerToken: string
  ) => any;
}

function XpIndices(props: XpIndicesProps) {
  const { currentSellerToken, currentSellerOrgID } = props;
  const { operationsByResource } = useContext(ApiSpecContext);

  const thingTypes =
    operationsByResource['Xp Indices'][1].requestBody.content[
      'application/json'
    ].schema.allOf[0].properties.ThingType.enum;

  const [xpIndices, setXpIndices] = useState<XpIndex[]>([]);
  const [xpIndicesUpdated, setXpIndicesUpdated] = useState<XpIndex[]>([]);
  const [requestStatus, setRequestStatus] = useState<ResourceRequestStatus>(
    REQUEST_NOT_MADE
  );
  const [authFailureData, setAuthFailureData] = useState<RoleComparison>({
    AssignedRoles: [],
    RequiredRoles: [],
  });

  // holding the sorted thingtypes for a given org token in state to prevent UI jumping around
  const [sortedThingTypes, setSortedThingTypes] = useState<string[]>([]);
  const getIndices = useCallback(async () => {
    const sellerOrgTokenResponse = await ApiClients.GetToken(
      currentSellerOrgID
    );
    const xpIndices = await SafelyGetResource(
      sellerOrgTokenResponse.access_token,
      {
        ocService: ocXpIndices,
        currentSellerOrgID,
        currentSellerToken,
        setRequestStatus,
        setAuthFailureData,
        page: 1,
      }
    );
    return xpIndices.Items;
  }, [
    currentSellerOrgID,
    currentSellerToken,
    setRequestStatus,
    setAuthFailureData,
  ]);

  const getAndSetIndices = async (thingType: string = '') => {
    const indices = await getIndices();
    setXpIndices(indices);

    if (!thingType) {
      setXpIndicesUpdated(cloneDeep(indices));
    } else {
      // if we do not want to reset the updated changes for other things
      resetUpdatedForThingType(indices, thingType);
    }
  };

  const deleteIndex = async (thingType: string, index: string) => {
    setXpIndicesUpdated(
      xpIndicesUpdated?.filter(
        x => !(x.Key === index && x.ThingType === thingType)
      )
    );
  };

  const addIndex = (thingType: any) => async (indexName: string) => {
    const newIndex: XpIndex = { ThingType: thingType, Key: indexName };
    setXpIndicesUpdated([...xpIndicesUpdated, newIndex]);
  };

  const handleSaveThingIndices = async (thingType: any) => {
    const updatedSellerOrgToken = await props.getUpdatedSellerOrgTokenAndSetInRedux(
      currentSellerOrgID,
      currentSellerToken
    );
    const [indicesToDelete, indicesToCreate] = getIndexDiffs(thingType);

    const requests = [
      ...indicesToCreate.map(newIndex =>
        ocXpIndices.Put(newIndex, { accessToken: updatedSellerOrgToken })
      ),
      ...indicesToDelete.map(indexToDelete => {
        return ocXpIndices.Delete(
          indexToDelete.ThingType as XpThingType,
          indexToDelete.Key as string,
          { accessToken: updatedSellerOrgToken }
        );
      }),
    ];
    await Promise.all(requests);
    getAndSetIndices(thingType);
  };

  const getIndexDiffs = (thingType: string) => {
    const xpIndicesForThingType = xpIndices?.filter(
      x => x.ThingType === thingType
    );
    const xpIndicesForThingTypeUpdated = xpIndicesUpdated?.filter(
      x => x.ThingType === thingType
    );
    const inidicesToDelete = xpIndicesForThingType?.filter(xpIndexExisting => {
      return !xpIndicesForThingTypeUpdated.some(
        xpIndexUpdated =>
          JSON.stringify(xpIndexUpdated) === JSON.stringify(xpIndexExisting)
      );
    });
    const inidicesToAdd = xpIndicesForThingTypeUpdated?.filter(
      xpIndexUpdated => {
        return !xpIndicesForThingType.some(
          xpIndicesExisting =>
            JSON.stringify(xpIndicesExisting) === JSON.stringify(xpIndexUpdated)
        );
      }
    );
    return [inidicesToDelete, inidicesToAdd];
  };

  const resetUpdatedForThingType = (indices: XpIndex[], thingType: string) => {
    // combine all of the existing updated indices for the other things with the non updated
    // indices for the thing on which changes were discarded
    const othersIndices = xpIndicesUpdated?.filter(
      x => x.ThingType !== thingType
    );
    const ownIndicies = indices?.filter(x => x.ThingType === thingType);
    const newIndices = [...othersIndices, ...ownIndicies];
    setXpIndicesUpdated(newIndices);
  };

  useEffect(() => {
    const getSortAndSetIndices = async () => {
      const indices = await getIndices();
      sortThingTypes(indices);
      setXpIndices(indices);
      setXpIndicesUpdated(cloneDeep(indices));
    };
    //sorting thingTypes based on the number of indexs created
    const sortThingTypes = (indices: XpIndex[]) => {
      const sortedThingTypes = thingTypes.sort((a, b) => {
        const numberOfXpIndicesForThingTypeA = indices?.filter(
          x => x.ThingType === a
        ).length;
        const numberOfXpIndicesForThingTypeB = indices?.filter(
          x => x.ThingType === b
        ).length;
        return numberOfXpIndicesForThingTypeA > numberOfXpIndicesForThingTypeB
          ? -1
          : 1;
      });
      setSortedThingTypes(sortedThingTypes);
    };
    getSortAndSetIndices();
  }, [currentSellerToken, getIndices, thingTypes]);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper square={true} className={classes.informationalText}>
        <Typography variant="body1" display="inline">
          When listing resources in OrderCloud, you may wish to include a filter
          to narrow the items returned. By default the first level of fields on
          a given resource can be filtered. In order to filter on custom XP
          values, you will need to specify below which values OrderCloud should
          index to make filtering possible.
        </Typography>
      </Paper>
      {requestStatus === 'REQUEST_AUTH_FAILURE' ? (
        <RolesRequiredComparison
          roleComparison={authFailureData}
          resourceName="XP Indices"
        />
      ) : (
        <Paper square={true} className={classes.root}>
          <Table>
            <colgroup>
              <col style={{ width: '12%' }} />
              <col style={{ width: '58%' }} />
              <col style={{ width: '30%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} key={1}>
                  Indexable Resource
                </TableCell>
                <TableCell className={classes.tableCell} key={2}>
                  Indices
                </TableCell>
                <TableCell className={classes.tableCell} key={3}>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedThingTypes.map((thingType, index) => {
                const xpIndicesForThingTypeUpdated = xpIndicesUpdated
                  ?.filter(x => x.ThingType === thingType)
                  .map(x => x.Key as string);
                const xpIndicesForThingType = xpIndices
                  ?.filter(x => x.ThingType === thingType)
                  .map(x => x.Key);
                const areChanges =
                  JSON.stringify(xpIndicesForThingTypeUpdated) !==
                  JSON.stringify(xpIndicesForThingType);
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCell} key={1}>
                      <strong>{thingType}</strong>
                    </TableCell>
                    <TableCell
                      className={classes.tableCellChipContainer}
                      key={2}
                    >
                      {xpIndicesForThingTypeUpdated.map(index => {
                        return (
                          <Chip
                            key={index}
                            color="secondary"
                            label={`xp.${index}`}
                            className={classes.chip}
                            onDelete={() => deleteIndex(thingType, index)}
                          />
                        );
                      })}
                      <AddIndexForm addIndex={addIndex(thingType)} />
                    </TableCell>
                    <TableCell className={classes.tableCell} key={3}>
                      {areChanges ? (
                        [
                          <Button
                            key={1}
                            color="secondary"
                            variant="contained"
                            onClick={() => handleSaveThingIndices(thingType)}
                          >
                            <SaveIcon />
                            Save Changes
                          </Button>,
                          <Button
                            color="secondary"
                            key={2}
                            variant="outlined"
                            className={classes.discardButton}
                            onClick={() =>
                              resetUpdatedForThingType(xpIndices, thingType)
                            }
                          >
                            <DiscardIcon />
                            Discard Changes
                          </Button>,
                        ]
                      ) : (
                        <Button disabled={true} color="primary">
                          No Changes Made to {thingType} Indices
                        </Button>
                      )}{' '}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
    </React.Fragment>
  );
}

interface AddIndexFormProps {
  addIndex: (indexName: string) => void;
}

function AddIndexForm(props: AddIndexFormProps) {
  let inputField = useRef(document.createElement('div'));
  const [index, setIndex] = useState<string>('');
  const [isAddingIndex, setIsAddingIndex] = useState(false);
  const { addIndex } = props;
  const classes = useStyles();
  const enterTextForm = () => {
    setIsAddingIndex(true);
    setIndex('');
    inputField.current.focus();
  };

  const closeIfNoText = () => {
    if (!index) {
      setIsAddingIndex(false);
    }
  };

  return (
    <React.Fragment>
      {isAddingIndex ? (
        <Chip
          icon={
            <CloseIcon
              onClick={() => {
                setIsAddingIndex(false);
              }}
            />
          }
          color="secondary"
          variant="outlined"
          label={
            <form
              onSubmit={e => {
                e.preventDefault();
                setIndex('');
                setIsAddingIndex(false);
                addIndex(index);
              }}
              noValidate
            >
              <Input
                autoFocus
                value={index || ''}
                color="secondary"
                startAdornment={
                  <InputAdornment
                    classes={{ root: classes.chipInputAdornmentForm }}
                    position="start"
                  >
                    <span>xp.</span>
                  </InputAdornment>
                }
                classes={{ root: classes.chipInput }}
                onBlur={() => closeIfNoText()}
                onChange={e => setIndex(e.target.value)}
              />
              <Button color="secondary" type="submit" size="small">
                Add
              </Button>
            </form>
          }
          className={classes.chip}
        />
      ) : (
        <Chip
          icon={<AddIcon />}
          color="secondary"
          variant="outlined"
          label="Add Index"
          className={classes.chip}
          onClick={() => {
            enterTextForm();
          }}
        />
      )}
    </React.Fragment>
  );
}

function mapStateToProps(state: AppReducerState) {
  return {
    currentSellerOrgID: AuthService.getCurrentSellerOrgIDFromState(state),
    currentSellerToken: AuthService.getCurrentSellerOrgTokenFromState(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getUpdatedSellerOrgTokenAndSetInRedux: (
      sellerOrgID: string,
      currentSellerToken: string
    ) =>
      dispatch(
        AuthService.getUpdatedSellerOrgTokenAndSetInRedux(
          sellerOrgID,
          currentSellerToken
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(XpIndices);
