import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Theme,
  createStyles,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { ApiClient } from 'ordercloud-javascript-sdk';
import FieldSection from '../FieldSection';

interface ClientSecretProps {
  readOnly?: boolean;
  apiClient: Partial<ApiClient>;
  onChange: (field: string) => (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tokenField: {
      width: '49%',
      margin: 0,
      marginTop: theme.spacing(2),
    },
  })
);

function TokenDurations(props: ClientSecretProps) {
  const classes = useStyles();

  const { AccessTokenDuration, RefreshTokenDuration } = props.apiClient;

  return (
    <FieldSection
      headerText="Token Durations"
      infoText="Set how long each API token will remain valid."
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          disabled={props.readOnly}
          id="access-tokens"
          type="number"
          label="Access Tokens"
          variant="outlined"
          value={AccessTokenDuration || ''}
          className={classes.tokenField}
          error={
            !AccessTokenDuration ||
            isNaN(AccessTokenDuration) ||
            AccessTokenDuration < 10 ||
            AccessTokenDuration > 600
          }
          helperText="Must be between 10 and 600"
          onChange={props.onChange('AccessTokenDuration')}
          margin="normal"
          InputProps={{
            readOnly: props.readOnly,
            endAdornment: (
              <InputAdornment position="end">minutes</InputAdornment>
            ),
          }}
          inputProps={{ min: '10', max: '600' }}
        />
        <TextField
          disabled={props.readOnly}
          id="refresh-token"
          type="number"
          label="Refresh Tokens"
          variant="outlined"
          value={RefreshTokenDuration || ''}
          className={classes.tokenField}
          onChange={props.onChange('RefreshTokenDuration')}
          error={
            (RefreshTokenDuration && RefreshTokenDuration > 43200) ||
            (RefreshTokenDuration && RefreshTokenDuration < 0)
              ? true
              : false
          }
          helperText="43200 max"
          margin="normal"
          InputProps={{
            readOnly: props.readOnly,
            endAdornment: (
              <InputAdornment position="end">minutes</InputAdornment>
            ),
          }}
          inputProps={{ min: '0', max: '43200' }}
        />
      </div>
    </FieldSection>
  );
}

export default TokenDurations;
