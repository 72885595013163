import asyncWrapper from '../requestStatus/thunkWrapper.actions';
import {
  UPDATE_DEVCENTER_USER,
  ACCEPT_TERMS_AND_CONDITIONS,
  SET_DEVCENTER_USER_EMAIL,
} from './auth.types';
import { setDevCenterUser } from './auth.actions';
import { User, CurrentUser } from '@ordercloud/portal-javascript-sdk';

export function updateDevcenterUser(user: User) {
  return dispatch => {
    asyncWrapper(dispatch, UPDATE_DEVCENTER_USER, async () => {
      await CurrentUser.Save(user);
      dispatch(setDevCenterUser(user));
    });
  };
}

export function updateUserEmail() {
  return dispatch => {
    asyncWrapper(dispatch, SET_DEVCENTER_USER_EMAIL, async () => {
      const updatedUser = await CurrentUser.Get();
      dispatch(setDevCenterUser(updatedUser));
    });
  };
}

export function acceptTermsAndConditions() {
  return dispatch => {
    asyncWrapper(dispatch, ACCEPT_TERMS_AND_CONDITIONS, async () => {
      await CurrentUser.AcceptTerms();
      const updatedUser = await CurrentUser.Get();
      dispatch(setDevCenterUser(updatedUser));
    });
  };
}
