import * as Yup from 'yup';

// https://github.com/jquense/yup/issues/97#issuecomment-306547261
// make sure to add type annotations to ./types/yup/index.d.ts so that it works with typescript
function equalTo(this: any, ref: any, msg?: string) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);
