import appConstants from '../config/app.constants';
/**
 * Takes in a path and resolves to portal URL
 */

export function withPrefix(path) {
  return [appConstants.docsUrl, normalizePath(path)].join(`/`);
}

function normalizePath(path) {
  // removes extra fourward slashes to ensure a proper URL
  if (path.charAt(0) === '/') {
    return path.replace(/\//, ``);
  }
  return path;
}

function navigate(path: string) {
  var prefixedTo = withPrefix(path);
  window.location.assign(prefixedTo);
}

export function goToExternal(route: string) {
  navigate(route);
}

export function getPathName() {
  return window.location.pathname;
}

export function resolveAbsolutePath(fileAbsolutePath: string) {
  var path = fileAbsolutePath.split('/content')[1].replace('.mdx', '');
  if (path.startsWith('/docs')) {
    return path.replace('/docs', ''); // served from root
  }
  if (path.startsWith('/documents')) {
    return path.replace('/documents', '/knowledge-base');
  }
  return path;
}
