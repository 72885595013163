export const generateRandomString = (stringLength: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < stringLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const VALID_CHARACTERS = /^[a-zA-Z0-9-_{}]+$/;
const INVALID_SPECIAL_CHARACTERS = /^[^~`@!#$%^&*+=[\];,/{}|\\":<>?]+$/;

export const isValidInput = (value?: string, isName?: boolean) => {
  if (!value) return true;
  if (isName)
    // only blacklist unsafe characters in user, org, or team names
    // allow non-english characters
    return INVALID_SPECIAL_CHARACTERS.test(String(value).toLowerCase());
  return VALID_CHARACTERS.test(String(value).toLowerCase());
};

const BREAKPOINT_VALUES = {
  xs: 0,
  sm: 600,
  md: 960,
  mdlg: 1024,
  lg: 1280,
  xl: 1920,
};

const RESPONSE_DRAWER_WIDTH_BY_BREAKPOINT = {
  xs: '100%',
  sm: '100%',
  md: 350,
  mdlg: 400,
  lg: 550,
  xl: 700,
};

const RESOURCE_DRAWER_WIDTH_BY_BREAKPOINT = {
  xs: 300,
  sm: 250,
  md: 250,
  mdlg: 225,
  lg: 225,
  xl: 300,
};

//TODO: Make this a hook that utilizises useWidth or useMediaQuery
function getBreakpoint() {
  const innerWidth = window.innerWidth;
  let breakpoint = 'sm';
  Object.entries(BREAKPOINT_VALUES).forEach(([key, value]) => {
    if (innerWidth >= value) {
      breakpoint = key;
    }
  });
  return breakpoint;
}

export function getResponseDrawerWidth() {
  return RESPONSE_DRAWER_WIDTH_BY_BREAKPOINT[getBreakpoint()];
}

export function getResourceDrawerWidth() {
  return RESOURCE_DRAWER_WIDTH_BY_BREAKPOINT[getBreakpoint()];
}
