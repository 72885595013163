import {
  SetContextChoice,
  ContextChoicesReducerState,
  ContextChoice,
  SearchText,
  ContextChoices,
  SearchTexts,
  ContextItemInfo,
} from './contextSelections.types';
import {
  SELLER_ORG,
  API_CLIENT,
  USER,
  emptySearchTexts,
  emptyContext,
  emptyUser,
  CONTEXT_TYPE,
} from './contextSelections.constants';
import {
  APIClientReducerState,
  SellerOrgReducerState,
  UsersReducerState,
  ContextResource,
  ContextResourceList,
} from '../contextOptions/contextOptions.types';
// import { store } from '../../index';
import { AppReducerState } from '..';
import {
  Organization,
  ApiClient,
  OrderCloudUser,
  RequiredDeep,
} from '@ordercloud/portal-javascript-sdk';

export function constructNewContext(
  reduxAction: SetContextChoice,
  previousState: ContextChoicesReducerState
): ContextChoice {
  switch (reduxAction.payload.contextType) {
    case SELLER_ORG:
      return constructSellerOrgContext(
        reduxAction.payload.contextResource as RequiredDeep<Organization>
      );
    case API_CLIENT:
      return constructAPIClientContext(
        previousState[reduxAction.payload.tabID].inSelection,
        reduxAction.payload.contextResource as RequiredDeep<ApiClient>
      );
    case USER:
      return constructUserContext(
        previousState[reduxAction.payload.tabID].inSelection,
        reduxAction.payload.contextResource as RequiredDeep<OrderCloudUser>
      );
  }
}

export function contructNewSearch(
  contextType: string,
  existingSelection: SearchText
): SearchText {
  if (existingSelection) {
    const selectionToReturn: SearchText = Object.assign({}, existingSelection);
    if (contextType === SELLER_ORG) {
      selectionToReturn.API_CLIENT = '';
      selectionToReturn.USER = '';
    }
    if (contextType === API_CLIENT) {
      selectionToReturn.USER = '';
    }
    return selectionToReturn;
  } else {
    return emptySearchTexts;
  }
}

function constructSellerOrgContext(
  sellerOrg: RequiredDeep<Organization>
): ContextChoice {
  return {
    ...emptyContext,
    SELLER_ORG: sellerOrg,
  };
}
function constructAPIClientContext(
  prevState: ContextChoice,
  apiClient: RequiredDeep<ApiClient>
): ContextChoice {
  return {
    ...prevState,
    API_CLIENT: apiClient,
    USER: emptyUser,
  };
}
function constructUserContext(
  previousContext: ContextChoice,
  user: RequiredDeep<OrderCloudUser>
): ContextChoice {
  return {
    ...previousContext,
    USER: user,
  };
}

export function selectItemsInfo(
  type: CONTEXT_TYPE,
  state: AppReducerState
): ContextItemInfo<any> {
  const activeTabID = state.tabs.tabsMeta.activeId;
  const searchTexts = state.contextSelections.searchText;

  // need to determine if there is text in the search bar to show the correct list
  const isInSearch = isSearchContext(searchTexts, activeTabID, type);
  let objectToReturn;
  switch (type) {
    case SELLER_ORG:
      objectToReturn = selectSellerOrgs({
        isInSearch,
        sellerOrgsDictionary: state.contextOptions.sellerOrgs,
        activeTabID,
      });
      break;
    case API_CLIENT:
      objectToReturn = selectAPIClients({
        isInSearch,
        contextChoices: state.contextSelections.contextChoices,
        activeTabID,
        apiClients: state.contextOptions.apiClients,
      });
      break;
    case USER:
      objectToReturn = selectUsers({
        isInSearch,
        userListDictionary: state.contextOptions.users,
        contextChoices: state.contextSelections.contextChoices,
        activeTabID,
      });
      break;
  }
  return objectToReturn;
}

interface SelectAPIClientsParams {
  isInSearch: boolean;
  contextChoices: ContextChoices;
  activeTabID: string;
  apiClients: APIClientReducerState;
}
export function selectAPIClients({
  isInSearch,
  contextChoices,
  activeTabID,
  apiClients,
}: SelectAPIClientsParams): ContextItemInfo<RequiredDeep<ApiClient>> {
  if (
    !contextChoices[activeTabID] ||
    !contextChoices[activeTabID].inSelection.SELLER_ORG.Id
  ) {
    return { items: [], areMore: false, pageNumber: 0 };
  } else {
    const lookupID = isInSearch
      ? activeTabID
      : contextChoices[activeTabID].inSelection.SELLER_ORG.Id;
    return getInfoFromReturnObject(apiClients[lookupID]) as ContextItemInfo<
      RequiredDeep<ApiClient>
    >;
  }
}

interface SelectSellerOrgParams {
  isInSearch: boolean;
  sellerOrgsDictionary: SellerOrgReducerState;
  activeTabID: string;
}
export function selectSellerOrgs({
  isInSearch,
  sellerOrgsDictionary,
  activeTabID,
}: SelectSellerOrgParams): ContextItemInfo<RequiredDeep<Organization>> {
  const currentItemsObject = isInSearch
    ? sellerOrgsDictionary[activeTabID]
    : sellerOrgsDictionary.defaultTab;
  return getInfoFromReturnObject(currentItemsObject) as ContextItemInfo<
    RequiredDeep<Organization>
  >;
}

interface SelectUsersParams {
  isInSearch: boolean;
  userListDictionary: UsersReducerState;
  contextChoices: ContextChoices;
  activeTabID: string;
}
export function selectUsers({
  isInSearch,
  userListDictionary,
  contextChoices,
  activeTabID,
}: SelectUsersParams): ContextItemInfo<RequiredDeep<OrderCloudUser>> {
  const apiClientID =
    contextChoices[activeTabID] &&
    contextChoices[activeTabID].inSelection.API_CLIENT.Id;
  const lookupID = isInSearch ? activeTabID.toString() : apiClientID;
  const currentItemsObject = userListDictionary[lookupID];
  return getInfoFromReturnObject(currentItemsObject) as ContextItemInfo<
    RequiredDeep<OrderCloudUser>
  >;
}

function isSearchContext(
  searchTexts: SearchTexts,
  activeTabID: string,
  contextType: CONTEXT_TYPE
): boolean {
  return (
    searchTexts[activeTabID] && searchTexts[activeTabID][contextType].length > 0
  );
}

export function checkIfRequestInProgress(
  contextType: CONTEXT_TYPE,
  currentRequests: string[]
): boolean {
  return currentRequests.some(r => r.includes(contextType));
}

export interface ContextFields {
  sellerOrgID: string;
  clientID: string;
  username: string;
}

// export function getCurrentContext(tabID, contextSelections): ContextFields {
//   const context = contextSelections.contextChoices[tabID].inSelection;
//   return {
//     sellerOrgID: context.SELLER_ORG.ID,
//     clientID: context.API_CLIENT.ID,
//     username: context.USER.Username,
//   };
// }

function getInfoFromReturnObject(
  currentItemsObject: ContextResourceList
): ContextItemInfo<ContextResource> {
  const items = (currentItemsObject && currentItemsObject.Items) || [];
  const areMore =
    currentItemsObject &&
    currentItemsObject.Meta.Page < currentItemsObject.Meta.TotalPages;
  const pageNumber = currentItemsObject && currentItemsObject.Meta.Page;
  return { items, areMore, pageNumber };
}

export function getCurrentApiClients(
  state: AppReducerState
): RequiredDeep<ApiClient>[] {
  try {
    const currentTabId = state.tabs.tabsMeta.activeId;
    const currentSellerID =
      state.contextSelections.contextChoices[currentTabId].saved.SELLER_ORG.Id;
    return state.contextOptions.apiClients[currentSellerID].Items;
  } catch (e) {
    return [];
  }
}
