import {
  createStyles,
  Theme,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Typography,
  SvgIcon,
  Tooltip,
} from '@material-ui/core';
import React, { useState, useEffect, useMemo } from 'react';
import PasswordField from '../Shared/PasswordField';
import sharedStyles from '../Shared/SharedStyles';
import { Button, TextField } from '@material-ui/core';
import AuthContainer from './AuthContainer';
import TestIds from '../../constants/test-ids.constants';
import { Link } from '@material-ui/core';
import { Link as ReactRouterLink } from 'react-router-dom';
import ProgressLoader from '../Shared/ProgressLoader';
import { UnauthenticatedComponentProps } from '../App/Routing/UnauthenticatedRoutes';
import ReCAPTCHA from 'react-google-recaptcha';
import appConstants from '../../config/app.constants';
import { Alert } from '../App/AlertContainer';

const testIds = TestIds.login;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...sharedStyles(theme),
    accountButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1),
      margin: theme.spacing(1, 0, 2.5, 0),
    },
    accountButton: {
      '&:hover': {
        color: 'white',
        '& path': {
          fill: 'white',
        },
      },
    },
    assistanceGroup: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rounded: {
      borderRadius: 999,
    },
  })
);

const Login = (props: UnauthenticatedComponentProps) => {
  const classes = useStyles(props);
  const { location, history } = props;
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [remember, setRemember] = useState(false);

  const recaptchaKey = String(appConstants.recaptchaClientKey);

  useEffect(() => {
    if (props.loginAttempts >= 2 && recaptchaKey) {
      setShowRecaptcha(true);
    }
  }, [props.loginAttempts, recaptchaKey]);

  // useEffect(() => {
  //   // lets us pre-load the spec while user is logging in
  //   Initialize('Production')
  //     .then(() => Initialize('Staging'))
  //     .then(() => Initialize('Sandbox'));
  // }, []);

  const errorMessage = useMemo(() => {
    return location.state && location.state.errorCode && location.state.message
      ? location.state.message === 'Object already exists'
        ? 'The email associated with that account is already registered. Please login with your Username and Password to update your authentication settings.'
        : location.state.message
      : null;
  }, [location.state]);

  const handleRecaptchaChange = (token: string) => {
    setRecaptchaVerified(false);
    setRecaptchaToken(token);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (showRecaptcha && !recaptchaToken) {
      return Alert.error('Please verify that you are a human');
    }
    const { onLogin } = props;
    setLoading(true);

    try {
      if (showRecaptcha && !recaptchaVerified) {
        setRecaptchaVerified(true);
      }
      await onLogin(username, password, remember, recaptchaToken);
      history.push('/');
    } catch (e) {
      if ((e as any)?.data?.Errors?.[0]?.ErrorCode === 'RecaptchaError') {
        Alert.error('Recaptcha validation failed');
      }
      setLoading(false);
    } finally {
      (window as any).grecaptcha?.reset();
    }
  };

  return (
    <AuthContainer>
      <form onSubmit={handleSubmit}>
        {errorMessage && <Typography>{errorMessage}</Typography>}
        <TextField
          className={classes.textFields}
          type="text"
          label="Username or Email"
          inputProps={{
            'data-test-id': testIds.email,
          }}
          value={username}
          required={true}
          autoFocus={true}
          onChange={e => setUsername(e.target.value)}
        />
        <PasswordField
          className={classes.textFields}
          value={password}
          label="Password"
          required={true}
          inputProps={{ 'data-test-id': testIds.password }}
          onChange={e => setPassword(e.target.value)}
        />
        <div className={classes.assistanceGroup}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={remember}
                onChange={e => setRemember(Boolean(e.target.checked))}
              />
            }
            label="Remember me"
          />
          <Link
            component={ReactRouterLink}
            color="secondary"
            to="/forgot-password"
          >
            Forgot Password?
          </Link>
        </div>
        {Boolean(showRecaptcha && recaptchaKey) && (
          <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaChange} />
        )}
        <ProgressLoader loading={loading}>
          <Button
            className={classes.formButtons}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
          >
            Login
          </Button>
        </ProgressLoader>
        <div className={classes.accountButtons}>
          <Tooltip
            title={
              <Typography>
                Login with Sitecore is no longer available for this web
                application. If you previously used this method to access your
                account, please reset your password using the "Forgot Password"
                flow.
              </Typography>
            }
          >
            <div>
              <Button
                className={classes.accountButton}
                size="small"
                variant="outlined"
                disabled
                startIcon={
                  <SvgIcon viewBox="0 0 512 513" style={{ opacity: 0.5 }}>
                    {/* Inline SVG in order to change hover color */}
                    <path
                      d="M244.42 373.898C264.445 371.478 283.807 365.185 301.428 355.37C344.54 331.142 369.481 289.811 371.619 238.861L371.975 234.585H431.833C428.12 204.969 416.966 176.776 399.41 152.637L338.127 191.473C347.234 205.252 352.641 221.144 353.827 237.618C355.013 254.092 351.937 270.595 344.896 285.536C328.15 324.016 288.601 357.508 244.42 373.898Z"
                      fill="#FE2911"
                    />
                    <path
                      d="M432.546 243.493H380.526C376.963 295.156 349.884 338.624 305.703 363.209C280.164 377.223 251.461 384.46 222.33 384.23C199.08 384.198 176.027 379.973 154.277 371.76C180.438 388.52 210.857 397.423 241.926 397.413H242.283C269.74 397.432 296.697 390.067 320.329 376.088C343.962 362.109 363.4 342.031 376.607 317.959L379.101 313.683L414.018 335.774C426.601 311.047 433.192 283.707 433.258 255.963C432.902 252.044 432.902 247.768 432.546 243.493"
                      fill="#FE2911"
                    />
                    <path
                      d="M242.282 406.677C186.344 406.677 133.968 378.886 96.5567 331.498C110.289 360.764 131.788 385.7 158.713 403.592C185.638 421.483 216.956 431.644 249.256 432.967C281.557 434.29 313.601 426.724 341.899 411.094C370.196 395.465 393.663 372.37 409.742 344.325L382.664 327.223C368.198 351.534 347.64 371.65 323.02 385.585C298.401 399.519 270.572 406.789 242.282 406.677"
                      fill="#FE2911"
                    />
                    <path
                      d="M256.178 442.307C219.393 442.307 183.434 431.399 152.849 410.962C122.264 390.526 98.4251 361.478 84.3481 327.494C70.2712 293.509 66.588 256.113 73.7644 220.035C80.9407 183.957 98.6543 150.817 124.665 124.806C150.676 98.7956 183.816 81.082 219.894 73.9057C255.972 66.7293 293.368 70.4125 327.352 84.4894C361.337 98.5664 390.384 122.405 410.821 152.99C431.258 183.576 442.166 219.535 442.166 256.319V256.319C442.178 280.731 437.382 304.906 428.051 327.465C418.721 350.023 405.039 370.523 387.786 387.793C370.533 405.064 350.047 418.767 327.498 428.12C304.949 437.474 280.779 442.294 256.367 442.307C256.304 442.307 256.241 442.307 256.178 442.307ZM256.178 0.497613C205.546 0.462391 156.041 15.4421 113.922 43.5424C71.8037 71.6428 38.9637 111.602 19.5551 158.366C0.146571 205.13 -4.95889 256.6 4.88436 306.266C14.7276 355.932 39.0775 401.564 74.8548 437.391C110.632 473.218 156.23 497.631 205.882 507.544C255.534 517.456 307.011 512.422 353.802 493.079C400.594 473.735 440.598 440.951 468.757 398.872C496.916 356.792 511.965 307.308 512 256.676V256.319C512.055 188.527 485.178 123.489 437.281 75.5128C389.384 27.5369 324.39 0.553163 256.597 0.497613C256.457 0.497613 256.318 0.497613 256.178 0.497613Z"
                      fill="#FE2911"
                    />
                  </SvgIcon>
                }
              >
                Sitecore
              </Button>
            </div>
          </Tooltip>
          <Button
            className={classes.accountButton}
            size="small"
            variant="outlined"
            href={`${appConstants.middlewareUrl}/oidc/Github/login`}
            startIcon={
              <SvgIcon viewBox="0 0 512 513">
                {/* Inline SVG in order to change hover color */}
                <path
                  d="M256.274 0.49752C114.919 0.49752 0.273926 118.019 0.273926 262.969C0.273926 378.938 73.6179 477.321 175.357 512.033C188.135 514.461 192.274 506.324 192.274 499.413V450.549C121.063 466.429 106.237 419.577 106.237 419.577C94.5886 389.24 77.7993 381.169 77.7993 381.169C54.5673 364.874 79.5699 365.224 79.5699 365.224C105.277 367.061 118.802 392.28 118.802 392.28C141.629 432.395 178.685 420.802 193.298 414.087C195.581 397.136 202.215 385.544 209.554 379.004C152.701 372.333 92.9246 349.826 92.9246 249.277C92.9246 220.602 102.93 197.198 119.293 178.825C116.647 172.198 107.879 145.491 121.789 109.358C121.789 109.358 143.293 102.315 192.21 136.261C212.626 130.443 234.514 127.534 256.274 127.424C278.034 127.534 299.943 130.443 320.402 136.261C369.277 102.315 390.738 109.358 390.738 109.358C404.669 145.513 395.901 172.22 393.255 178.825C409.682 197.198 419.602 220.624 419.602 249.277C419.602 350.088 359.719 372.289 302.717 378.785C311.89 386.922 320.274 402.889 320.274 427.386V499.413C320.274 506.39 324.37 514.592 337.362 512.011C439.015 477.256 512.274 378.894 512.274 262.969C512.274 118.019 397.65 0.49752 256.274 0.49752Z"
                  fill="black"
                />
              </SvgIcon>
            }
          >
            GitHub
          </Button>
          <Button
            className={classes.accountButton}
            size="small"
            variant="outlined"
            href={`${appConstants.middlewareUrl}/oidc/Google/login`}
            startIcon={
              <SvgIcon viewBox="0 0 502 513">
                <path
                  fill="#4285F4"
                  d="M501.609 262.258c0-16.854-1.494-32.854-4.054-48.427H256.489v96.213h138.026c-6.186 31.574-24.32 58.24-51.2 76.374v64h82.347c48.213-44.587 75.947-110.294 75.947-188.16Z"
                />
                <path
                  fill="#34A853"
                  d="M256.489 512.498c69.12 0 126.933-23.04 169.173-62.08l-82.347-64c-23.04 15.36-52.266 24.746-86.826 24.746-66.774 0-123.307-45.013-143.574-105.813H28.0085v65.92C70.0352 454.898 156.435 512.498 256.489 512.498Z"
                />
                <path
                  fill="#FBBC05"
                  d="M112.915 305.351c-5.333-15.36-8.106-31.787-8.106-48.853 0-17.067 2.986-33.494 8.106-48.854v-65.92H28.0086C10.5152 176.284.488586 215.111.488586 256.498c0 41.386 10.026614 80.213 27.520014 114.773l84.9064-65.92Z"
                />
                <path
                  fill="#EA4335"
                  d="M256.489 101.831c37.76 0 71.466 13.013 98.133 38.4l72.96-72.9601C383.422 25.8842 325.609.49752 256.489.49752 156.435.49752 70.0352 58.0976 28.0085 141.724l84.9065 65.92c20.267-60.8 76.8-105.813 143.574-105.813Z"
                />
              </SvgIcon>
            }
          >
            Google
          </Button>
        </div>
        <Link component={ReactRouterLink} color="primary" to="/register">
          Sign Up For Free
        </Link>
      </form>
    </AuthContainer>
  );
};

export default Login;
