import {
  Chip,
  ClickAwayListener,
  createStyles,
  Grow,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { IconProps } from '@material-ui/core/Icon';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { DEFAULT_PORTAL_NAVIGATION_WIDTH } from '../Layout/PortalNavigation';

/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.text.primary,
    },
    activeIcon: {
      color: theme.palette.primary.main,
    },
    menuPaper: {
      position: 'absolute',
      width: DEFAULT_PORTAL_NAVIGATION_WIDTH * 2,
      maxWidth: '100vw',
      left: '100%',
      top: 0,
      transformOrigin: 'top left',
      // border: `1px solid ${theme.palette.divider}`,
    },
    listItemText: {
      whiteSpace: 'nowrap',
      display: 'block',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'hidden',
    },
  })
);

interface ListItemNavLinkProps {
  icon?: ReactElement<IconProps>;
  activeIcon?: ReactElement<IconProps>;
  disableMenu?: boolean;
  badge?: string | number;
  menu?: (callbacks: {
    openMenu: () => void;
    closeMenu: () => void;
  }) => ReactElement<any>;
  primary?: string;
  secondary?: string;
  to: string;
  exact?: boolean;
  strict?: boolean;
  className?: string;
}

const ListItemNavLink = (props: ListItemNavLinkProps) => {
  const {
    to,
    strict,
    exact,
    activeIcon,
    icon,
    disableMenu,
    badge,
    menu,
    primary,
    secondary,
  } = props;

  const classes = useStyles();
  const [hoverTimeout, setHoverTimeout] = useState<
    NodeJS.Timeout | undefined
  >();

  const [menuOpen, setMenuOpen] = useState(false);

  const renderLink = useMemo(
    () =>
      React.forwardRef((listItemProps: any, ref: any) => (
        <Link {...listItemProps} to={to} innerRef={ref} />
      )),
    [to]
  );

  const escapedPath = useMemo(() => {
    return to ? to.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1') : undefined;
  }, [to]);

  const handleMenuOpen = useCallback(() => {
    if (disableMenu) return;
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(undefined);
    }
    setHoverTimeout(
      setTimeout(() => {
        setMenuOpen(true);
      }, 400)
    );
  }, [disableMenu, hoverTimeout]);

  const handleMenuClose = useCallback(() => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(undefined);
    }
    setMenuOpen(false);
  }, [hoverTimeout]);

  const callbacks = useMemo(() => {
    if (!menu) return {};
    return {
      onMouseEnter: handleMenuOpen,
      onMouseLeave: handleMenuClose,
    };
  }, [handleMenuClose, handleMenuOpen, menu]);

  const listItem = useCallback(
    (isActive: boolean) => {
      return (
        <ListItem
          className={props.className}
          button
          component={renderLink}
          selected={isActive}
          {...callbacks}
        >
          {Boolean(icon) && (
            <ListItemIcon
              className={isActive ? classes.activeIcon : classes.icon}
            >
              {activeIcon && icon ? (
                isActive ? (
                  activeIcon
                ) : (
                  icon
                )
              ) : icon ? (
                icon
              ) : (
                <span></span>
              )}
            </ListItemIcon>
          )}
          <ListItemText
            classes={{
              primary: classes.listItemText,
              secondary: classes.listItemText,
            }}
            primary={primary}
            secondary={secondary}
          />
          {badge && (
            <Chip
              label={badge}
              size="small"
              color="secondary"
              variant="outlined"
            ></Chip>
          )}
          {menu && (
            <Grow in={menuOpen}>
              <Paper className={classes.menuPaper} elevation={2}>
                {menu({ openMenu: handleMenuOpen, closeMenu: handleMenuClose })}
              </Paper>
            </Grow>
          )}
        </ListItem>
      );
    },
    [
      activeIcon,
      badge,
      callbacks,
      classes.activeIcon,
      classes.icon,
      classes.listItemText,
      classes.menuPaper,
      handleMenuClose,
      handleMenuOpen,
      icon,
      menu,
      menuOpen,
      primary,
      props.className,
      renderLink,
      secondary,
    ]
  );

  return (
    <Route
      path={escapedPath}
      exact={exact}
      strict={strict}
      children={({ match }) => {
        const isActive = !!match;

        return menu ? (
          <ClickAwayListener onClickAway={handleMenuClose}>
            {listItem(isActive)}
          </ClickAwayListener>
        ) : (
          listItem(isActive)
        );
      }}
    />
  );
};

export default ListItemNavLink;
