import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React, { useEffect } from 'react';
import ORDERCLOUD_THEME from '../../themes/theme.constants';
import usePortalAuth from '../Shared/hooks/usePortalAuth';
import AlertContainer from './AlertContainer';
import PortalRoutes from './Routing/PortalRoutes';
import UnauthenticatedRoutes from './Routing/UnauthenticatedRoutes';
import PortalContext from './PortalContext';
import { Router, Route } from 'react-router';
import history from '../../services/history';
import { ConfigureErrorHandler } from '../../services/httpError.helper';
import UpdateUsernameDialog from './UpdateUsernameDialog';
import AcceptTermsDialog from './AcceptTermsDialog';
import ApiSpecContextProvider from './ApiSpecContext';
import ConfirmEmailDialog from '../Shared/ConfirmEmailDialog';

const Application = (props: any) => {
  const {
    login,
    refresh,
    logout,
    setLoginAttempts,
    decoded_token,
    login_attempts,
  } = usePortalAuth();

  /* ON FIRST LOAD */
  useEffect(() => {
    const placeholder = document.getElementById('placeholder');
    if (placeholder) {
      placeholder.classList.add('hide');
    }
  }, []);

  useEffect(() => {
    ConfigureErrorHandler(logout);
  }, [logout]);

  const onDialogClose = () => {
    return;
  };

  return (
    <MuiThemeProvider theme={ORDERCLOUD_THEME}>
      <CssBaseline />
      <AlertContainer />
      <Router history={history}>
        {decoded_token ? (
          <PortalContext username={decoded_token.usr}>
            <ApiSpecContextProvider>
              <AcceptTermsDialog />
              <UpdateUsernameDialog />
              <ConfirmEmailDialog
                onLogout={logout}
                profileUpdate={false}
                open={false}
                onClose={onDialogClose}
                loginAttempts={login_attempts}
                setLoginAttempts={setLoginAttempts}
              />
              <Route
                path="/"
                component={props => (
                  <PortalRoutes
                    {...props}
                    onLogout={logout}
                    loginAttempts={login_attempts}
                    setLoginAttempts={setLoginAttempts}
                  />
                )}
              />
            </ApiSpecContextProvider>
          </PortalContext>
        ) : (
          <UnauthenticatedRoutes
            onLogin={login}
            onAccountLogin={refresh}
            loginAttempts={login_attempts}
          />
        )}
      </Router>
    </MuiThemeProvider>
  );
};

export default Application;
