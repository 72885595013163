import React, { useCallback } from 'react';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import Layout from '../../Layout/Layout';
import Settings from '../../Settings';
import { PortalAuth } from '../../Shared/hooks/usePortalAuth';
import NoMatchPage from './NoMatchPage';
import { SitecoreCircularProgress } from '../../Styled/CircularProgress';

//Using React.lazy for codesplitting purposes
const Dashboard = React.lazy(() => import('../../Dashboard/Dashboard'));
const ApiConsole = React.lazy(() => import('../../ApiConsole/ApiConsole'));
const OrganizationDetail = React.lazy(() =>
  import('../../Organizations/OrganizationDetail')
);
const OrganizationList = React.lazy(() =>
  import('../../Organizations/OrganizationList')
);
const TeamDetail = React.lazy(() => import('../../Teams/TeamDetail'));
const TeamList = React.lazy(() => import('../../Teams/TeamList'));

export interface PortalRouteProps extends RouteComponentProps {
  onLogout: PortalAuth['logout'];
  setLoginAttempts: (attempts: number) => void;
  loginAttempts: number;
}

const PortalRoutes = (props: PortalRouteProps) => {
  const {
    onLogout,
    setLoginAttempts,
    loginAttempts,
    location,
    history,
  } = props;

  const renderComponent = useCallback(
    (Component: React.ComponentType<any>) => (props: RouteComponentProps) => {
      return (
        <Component
          {...props}
          onLogout={onLogout}
          setLoginAttempts={setLoginAttempts}
          loginAttempts={loginAttempts}
        />
      );
    },
    [onLogout, setLoginAttempts, loginAttempts]
  );

  return (
    <React.Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: 'calc(100vh - 64px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SitecoreCircularProgress variant="indeterminate" />
        </div>
      }
    >
      <Layout location={location} history={history} onLogout={onLogout}>
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/console" component={ApiConsole} />
          <Route path="/settings/:tab?" render={renderComponent(Settings)} />
          <Route path="/teams" exact component={TeamList} />
          <Route path="/teams/:teamId/:tab?" exact component={TeamDetail} />
          <Route path="/marketplaces" exact component={OrganizationList} />
          <Route
            path="/marketplaces/:orgId/:tab?"
            render={renderComponent(OrganizationDetail)}
          />
          <Redirect from="/setup" to="/"></Redirect>
          <Redirect from="/forgot-password" to="/"></Redirect>
          <Redirect from="/reset-password" to="/"></Redirect>
          <Redirect from="/register" to="/"></Redirect>
          <Redirect from="/login" to="/"></Redirect>
          <Route component={NoMatchPage} />
        </Switch>
      </Layout>
    </React.Suspense>
  );
};

export default PortalRoutes;
