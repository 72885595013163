import React, { useState } from 'react';
import {
  createStyles,
  Theme,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { WebhookRoute } from 'ordercloud-javascript-sdk';
import RestfulVerb from '../../../RestfulVerb';
import { Close } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { Scrollbars } from 'react-custom-scrollbars-2';
import FieldSection from '../FieldSection';
import { useContext } from 'react';
import { ApiSpecContext } from '../../../../App/ApiSpecContext';

interface ConfigurationDataProps {
  readOnly?: boolean;
  webhookRoutes: WebhookRoute[];
  handleChange: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    restfulVerbContainer: {
      width: theme.spacing(8),
      display: 'inline-block',
    },
    resourceSelect: {
      width: theme.spacing(35),
    },
    operationMenuItem: {
      display: 'flex',
    },
    resourceSelectContainer: {
      marginRight: theme.spacing(2),
    },
    infoText: {
      marginBottom: theme.spacing(3),
    },
    noTriggersSelected: {
      '& > div': {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-root': {
          color: theme.palette.error.light,
        },
      },
    },
    listOperations: {
      [theme.breakpoints.up('xl')]: {
        maxWidth: '75%',
      },
      '& > div': {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: theme.spacing(1),
      },
    },
    iconButtonWrapper: {
      display: 'block',
      width: '100%',
      '& .MuiIconButton-root': {
        backgroundColor: theme.palette.grey[100],
        padding: 5,

        '&:hover': {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
  })
);

function TriggerEvents(props: ConfigurationDataProps) {
  const toggleOperation = (operation, event) => {
    // stop propagation to prevent the menu from closing
    event.stopPropagation();
    if (isIncludedInWebhookRoutes(operation)) {
      const key = props.webhookRoutes.findIndex(w => {
        return (
          w.Route === `v1${operation.path}` &&
          w.Verb === operation.verb.toUpperCase()
        );
      });
      removeOperation(key);
    } else {
      props.handleChange([
        ...props.webhookRoutes,
        {
          Route: `v1${operation.path}`,
          Verb: operation.verb.toUpperCase(),
        },
      ]);
    }
  };
  const removeOperation = key => {
    props.webhookRoutes.splice(key, 1);
    props.handleChange(props.webhookRoutes);
  };

  const isIncludedInWebhookRoutes = operation => {
    return props.webhookRoutes.some(
      w =>
        w.Route === `v1${operation.path}` &&
        w.Verb === operation.verb.toUpperCase()
    );
  };
  const classes = useStyles();
  const [selectedResource, setSelectedResource] = useState(0);

  const { hookableResources, hookableOperationsByResource } = useContext(
    ApiSpecContext
  );
  return (
    <FieldSection
      headerText="Trigger Events"
      infoText="Select the relevant OrderCloud routes that will trigger this webhook."
    >
      {!props.readOnly && (
        <React.Fragment>
          <FormControl classes={{ root: classes.resourceSelectContainer }}>
            <InputLabel htmlFor="select-resource">Select Resource</InputLabel>
            <Select
              classes={{ root: classes.resourceSelect }}
              inputProps={{
                name: 'Select Resource',
                id: 'select-resource',
              }}
              value={selectedResource}
              onChange={e => setSelectedResource(Number(e.target.value))}
            >
              {hookableResources.map((resource, key) => {
                return (
                  <MenuItem value={key} key={key}>
                    {resource.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="view-operations">View Operations</InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              inputProps={{
                name: 'View Operations',
                id: 'view-operations',
              }}
              value=""
              classes={{ root: classes.resourceSelect }}
            >
              <option value="" />
              {hookableOperationsByResource[
                hookableResources[selectedResource].name
              ].map(operation => {
                return (
                  <MenuItem
                    classes={{ root: classes.operationMenuItem }}
                    value={operation.operationId}
                    key={operation.operationId}
                    onClick={e => toggleOperation(operation, e)}
                  >
                    <Checkbox checked={isIncludedInWebhookRoutes(operation)} />
                    <RestfulVerb verb={operation.verb} /> {operation.summary}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </React.Fragment>
      )}
      <Scrollbars
        className={`${classes.listOperations} ${props.webhookRoutes &&
          !props.webhookRoutes.length &&
          classes.noTriggersSelected}`}
        renderTrackHorizontal={props => (
          <div
            {...props}
            style={{ display: 'none' }}
            className="track-horizontal"
          />
        )}
        style={{ width: '100%', height: '200px', marginTop: '10px' }}
      >
        {props.webhookRoutes && !props.webhookRoutes.length ? (
          <Typography variant="body1">
            <WarningIcon
              fontSize="small"
              color="error"
              style={{ verticalAlign: 'middle' }}
            />
            {'     '}With no trigger routes selected, the webhook will never
            fire.
          </Typography>
        ) : null}
        {props.webhookRoutes.map((webhookRoute, key) => {
          return (
            <span key={key} className={classes.iconButtonWrapper}>
              {!props.readOnly && (
                <IconButton size="small" onClick={() => removeOperation(key)}>
                  <Close fontSize="small" color="error" />
                </IconButton>
              )}
              <span className={classes.restfulVerbContainer}>
                <RestfulVerb verb={webhookRoute.Verb as string} />
              </span>
              <code>{webhookRoute.Route}</code>
            </span>
          );
        })}
      </Scrollbars>
    </FieldSection>
  );
}

export default TriggerEvents;
