import { TableCell } from '@material-ui/core';
import React from 'react';
import { ApiClient, RequiredDeep } from 'ordercloud-javascript-sdk';
import ApiClientAccess from '../../../ContextSelector/ApiClientAccess';
import ActiveIndicator from '../../../../Shared/ActiveIndicator';
import { maroon } from '../../../../../themes/ocPalette.constants';

const apiClientStyles = {
  fontFamily: 'monospace',
  color: maroon[500],
};

export function ApiClientTableHeaderRow() {
  return (
    <React.Fragment>
      <TableCell align="left">ID</TableCell>
      <TableCell align="left">Access</TableCell>
      <TableCell align="left">Is Active?</TableCell>
    </React.Fragment>
  );
}

function ApiClientTableRow(apiClient: RequiredDeep<ApiClient>) {
  return (
    <React.Fragment>
      <TableCell align="left">
        <span style={apiClientStyles}>{apiClient.ID}</span>
      </TableCell>
      <TableCell align="left">
        <ApiClientAccess {...apiClient} />
      </TableCell>
      <TableCell align="left">
        <ActiveIndicator
          isActive={apiClient.Active}
          isActiveToolTipText="API client can be deactivated to disable requests from certain apps"
          isInactiveToolTipText="To use API client change the active status on the edit page"
        />
      </TableCell>
    </React.Fragment>
  );
}

export default ApiClientTableRow;
