import React from 'react';
import Webhooks from './Webhooks';
import SecurityProfiles from './SecurityProfiles';
import ApiClients from './ApiClients';
import XpIndices from './XpIndices';
import MessageSenders from './MessageSenders';

const prettyUIComponents = {
  Webhooks,
  SecurityProfiles,
  ApiClients,
  XpIndices,
  MessageSenders,
};

interface PrettyUIProps {
  operationId: string;
}
export default function PrettyUI(props: PrettyUIProps) {
  // gets the resource name from the operation id
  const resourceName = props.operationId.split('.')[0];
  const SpecificPrettyUIComponent = prettyUIComponents[resourceName];
  return <SpecificPrettyUIComponent />;
}

export function hasPrettyUI(operationId: string | undefined): boolean {
  if (!operationId) {
    return false;
  }
  const routesWithPrettyUIs = Object.keys(prettyUIComponents);
  return routesWithPrettyUIs.some(route => operationId.includes(route));
}

export function determinePrettyUIView(
  operationId: string | undefined,
  previousOperationId: string | undefined,
  currentViewingState: boolean | undefined
): boolean {
  if (!operationId) {
    return false;
  }
  if (
    previousOperationId &&
    operationId.split('.')[0] === previousOperationId.split('.')[0]
  ) {
    // if the user is viewing changing from one operation
    // to another within the same resource return false
    return !!currentViewingState;
  } else {
    const routesWithPrettyUIs = Object.keys(prettyUIComponents);
    return routesWithPrettyUIs.some(route => operationId.includes(route));
  }
}
