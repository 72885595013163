import { MessageSender } from 'ordercloud-javascript-sdk';

export const CUSTOM_URL = 'CUSTOM_URL';
export type CUSTOM_URL = typeof CUSTOM_URL;

export const CUSTOM_MANDRILL_ACCOUNT = 'CUSTOM_MANDRILL_ACCOUNT';
export type CUSTOM_MANDRILL_ACCOUNT = typeof CUSTOM_MANDRILL_ACCOUNT;

export const DEFAULT = 'DEFAULT';
export type DEFAULT = typeof DEFAULT;

export type MessageSenderType = CUSTOM_URL | CUSTOM_MANDRILL_ACCOUNT | DEFAULT;

export interface MessageSenderTypeAttributes {
  Name: string;
  Description: string;
  InfoText: string;
  IsThisType: (messageSender: Partial<MessageSender>) => boolean;
}

export const MessageSenderTypeAttributes: {
  [messageSenderType: string]: MessageSenderTypeAttributes;
} = {
  DEFAULT: {
    Name: 'Default Sender',
    Description:
      'Send your message through the already created default OrderCloud mandrill account.',
    InfoText:
      'No need to define a URL or key, choose your name and message template and OrderCloud will handle the rest.',
    IsThisType: (messageSender: Partial<MessageSender>) =>
      messageSender.URL === orderCloudMandrillIntegrationURL &&
      (!messageSender.xp || typeof messageSender.xp.ApiKey === 'undefined'),
  },
  CUSTOM_MANDRILL_ACCOUNT: {
    Name: 'Custom Mandrill Account',
    Description: 'Send your message to a mandrill account that you own.',
    InfoText:
      'Enter the ApiKey for your mandrill account and choose a name and a message template.',
    IsThisType: (messageSender: Partial<MessageSender>) =>
      messageSender.URL === orderCloudMandrillIntegrationURL &&
      messageSender.xp &&
      typeof messageSender.xp.ApiKey !== 'undefined',
  },
  CUSTOM_URL: {
    Name: 'Custom URL',
    Description:
      'Send your message information to a custom url that will handle sending the email.',
    InfoText:
      'Choose a URL, and message template. The JSON shown below your selections will be sent in the request.',
    IsThisType: (messageSender: Partial<MessageSender>) =>
      messageSender.URL !== orderCloudMandrillIntegrationURL,
  },
};

export const orderCloudMandrillIntegrationURL =
  'https://oc-integrations.azurewebsites.net/v1/mandrill/{messagetype}';

export const determineMessageSenderType = (
  messageSender: Partial<MessageSender>
): string => {
  let messagetype = '';
  Object.entries(MessageSenderTypeAttributes).forEach(
    ([messageSenderType, messageSenderTypeAttributes]) => {
      if (messageSenderTypeAttributes.IsThisType(messageSender)) {
        messagetype = messageSenderType;
      }
    }
  );
  return messagetype;
};

export const newMessageSender: Partial<MessageSender> = {
  ID: '',
  Name: 'New Message Sender',
  MessageTypes: [],
  URL: orderCloudMandrillIntegrationURL,
  SharedKey: '',
  xp: {},
};

export interface MessageSenderTemplate {
  Name: string;
  Description: string;
  MessageType: string;
  FromEmail: string;
  Subject: string;
  TemplateName: string;
  MainContent: string;
}

export const DefaultMessageSenderTemplates = [
  {
    Name: 'New User Invitation',
    Description: 'Notify a new user that they have been invited.',
    MessageType: 'NewUserInvitation',
    FromEmail: 'noreply@ordercloud.io',
    Subject: "You've been invited to Ordercloud!",
    TemplateName: 'NewUserInvitation',
    MainContent: 'NewUserInvitation',
  },
  {
    Name: 'Forgotten Password',
    Description: "Notify users when they've initiated a password reset.",
    MessageType: 'ForgottenPassword',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Here is the link to reset your password',
    TemplateName: 'ForgottenPassword',
    MainContent: 'ForgottenPassword',
  },
  {
    Name: 'Forgotten Username',
    Description: "Notify users when they've initiated a username reset.",
    MessageType: 'ForgottenUsername',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Here is the forgotten username',
    TemplateName: 'ForgottenUsername',
    MainContent: 'ForgottenUsername',
  },
  {
    Name: 'Order Submitted',
    Description: 'Notify the buyer their order was received.',
    MessageType: 'OrderSubmitted',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Hey, thanks for the order',
    TemplateName: 'OrderSubmitted',
    MainContent: 'OrderSubmitted',
  },
  {
    Name: 'Order Submitted for Approval',
    Description:
      'Notify the buyer their order has been submitted for approval.',
    MessageType: 'OrderSubmittedForApproval',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your order has been submitted for approval',
    TemplateName: 'OrderSubmittedForApproval',
    MainContent: 'OrderSubmittedForApproval',
  },
  {
    Name: 'Order Approved',
    Description: 'Notify the buyer their order has been approved.',
    MessageType: 'OrderApproved',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your order has been approved',
    TemplateName: 'OrderApproved',
    MainContent: 'OrderApproved',
  },
  {
    Name: 'Order Declined',
    Description: 'Notify the buyer their order was declined',
    MessageType: 'OrderDeclined',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your order has been declined',
    TemplateName: 'OrderDeclined',
    MainContent: 'OrderDeclined',
  },
  {
    Name: 'Order Submitted for Your Approval',
    Description: 'Notify approving users they have a new pending order.',
    MessageType: 'OrderSubmittedForYourApproval',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'An order awaits your approval',
    TemplateName: 'OrderSubmittedForYourApproval',
    MainContent: 'OrderSubmittedForYourApproval',
  },
  {
    Name: 'Order Submitted for Your Approval Has Been Approved',
    Description:
      'Notify approving users their pending order was approved by another party.',
    MessageType: 'OrderSubmittedForYourApprovalHasBeenApproved',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'An order awaiting your approval was approved',
    TemplateName: 'OrderSubmittedForYourApprovalHasBeenApproved',
    MainContent: 'OrderSubmittedForYourApprovalHasBeenApproved',
  },
  {
    Name: 'Order Submitted for Your Approval Has Been Declined',
    Description:
      'Notify approving users their pending order was declined by another party.',
    MessageType: 'OrderSubmittedForYourApprovalHasBeenDeclined',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'An order awaiting your approval was declined',
    TemplateName: 'OrderSubmittedForYourApprovalHasBeenDeclined',
    MainContent: 'OrderSubmittedForYourApprovalHasBeenDeclined',
  },
  {
    Name: 'Shipment Created',
    Description: 'Notify the buyer when a shipment was made for their order.',
    MessageType: 'ShipmentCreated',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your order has been shipped',
    TemplateName: 'ShipmentCreated',
    MainContent: 'ShipmentCreated',
  },
  {
    Name: 'Order Return Submitted',
    Description: 'Notify the buyer their return order was received.',
    MessageType: 'OrderReturnSubmitted',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your return order has been submitted',
    TemplateName: 'OrderReturnSubmitted',
    MainContent: 'OrderReturnSubmitted',
  },
  {
    Name: 'Order Return Submitted for Approval',
    Description:
      'Notify the buyer their return order has been submitted for approval.',
    MessageType: 'OrderReturnSubmittedForApproval',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your order return has been submitted for approval',
    TemplateName: 'OrderReturnSubmittedForApproval',
    MainContent: 'OrderReturnSubmittedForApproval',
  },
  {
    Name: 'Order Return Approved',
    Description: 'Notify the buyer their return order has been approved.',
    MessageType: 'OrderReturnApproved',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your return order has been approved',
    TemplateName: 'OrderReturnApproved',
    MainContent: 'OrderReturnApproved',
  },
  {
    Name: 'Order Return Completed',
    Description: 'Notify the buyer their return order was completed.',
    MessageType: 'OrderReturnCompleted',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your return order has been completed',
    TemplateName: 'OrderReturnCompleted',
    MainContent: 'OrderReturnCompleted',
  },
  {
    Name: 'Order Return Declined',
    Description: 'Notify the buyer their return order was declined',
    MessageType: 'OrderReturnDeclined',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your return order has been declined',
    TemplateName: 'OrderReturnDeclined',
    MainContent: 'OrderReturnDeclined',
  },
  {
    Name: 'Order Return Submitted for Your Approval',
    Description: 'Notify approving users they have a new pending return order.',
    MessageType: 'OrderReturnSubmittedForYourApproval',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'A return order awaits your approval',
    TemplateName: 'OrderReturnSubmittedForYourApproval',
    MainContent: 'OrderReturnSubmittedForYourApproval',
  },
  {
    Name: 'Order Return Submitted for Your Approval Has Been Approved',
    Description:
      'Notify approving users their pending return order was approved by another party.',
    MessageType: 'OrderReturnSubmittedForYourApprovalHasBeenApproved',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'A return order awaiting your approval was approved',
    TemplateName: 'OrderReturnSubmittedForYourApprovalHasBeenApproved',
    MainContent: 'OrderReturnSubmittedForYourApprovalHasBeenApproved',
  },
  {
    Name: 'Order Return Submitted for Your Approval Has Been Declined',
    Description:
      'Notify approving users their pending return order was declined by another party.',
    MessageType: 'OrderReturnSubmittedForYourApprovalHasBeenDeclined',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'A return order awaiting your approval was declined',
    TemplateName: 'OrderReturnSubmittedForYourApprovalHasBeenDeclined',
    MainContent: 'OrderReturnSubmittedForYourApprovalHasBeenDeclined',
  },
  {
    Name: 'Subscription Reminder',
    Description:
      'Notify the buyer their subscription order will be processed soon.',
    MessageType: 'SubscriptionReminder',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your subscription order will be processed soon',
    TemplateName: 'SubscriptionReminder',
    MainContent: 'SubscriptionReminder',
  },
  {
    Name: 'Product Collections Invitation Accepted',
    Description:
      'Notify the owner that their product collection invitation has been accepted.',
    MessageType: 'ProductCollectionInvitationAccepted',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your product collection invitation has been accepted',
    TemplateName: 'ProductCollectionInvitationAccepted',
    MainContent: 'ProductCollectionInvitationAccepted',
  },
  {
    Name: 'Product Collections Invitation Declined',
    Description:
      'Notify the owner that their product collection invitation has been denied.',
    MessageType: 'ProductCollectionInvitationDeclined',
    FromEmail: 'noreply@ordercloud.io',
    Subject: 'Your product collection invitation has been denied',
    TemplateName: 'ProductCollectionInvitationDeclined',
    MainContent: 'ProductCollectionInvitationDeclined',
  },
];

export const getTemplateType = (template: MessageSenderTemplate) => {
  const firstWordOfName = template.Name.split(' ')[0];
  if (firstWordOfName === 'Order' || firstWordOfName === 'Shipment') {
    return `${firstWordOfName} event`;
  } else {
    return `User event`;
  }
};
