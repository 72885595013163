import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface CopyResourceProps {
  resource: any;
}

class CopyResource extends React.Component<CopyResourceProps> {
  state = {
    toolTipOpen: false,
  };

  handleTooltipClose = () => {
    setTimeout(() => {
      this.setState({ toolTipOpen: false });
    }, 2000);
  };

  handleTooltipOpen = () => {
    this.setState({ toolTipOpen: true });
    this.handleTooltipClose();
  };

  stopPropagation = event => {
    event.stopPropagation();
  };

  public render() {
    const stringifiedResource = JSON.stringify(this.props.resource);
    return (
      <Tooltip
        PopperProps={{ disablePortal: true }}
        onClose={this.handleTooltipClose}
        open={this.state.toolTipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied"
      >
        <CopyToClipboard
          text={stringifiedResource}
          onCopy={this.handleTooltipOpen}
        >
          <IconButton aria-label="copy" onClick={this.stopPropagation}>
            <CopyIcon
              color={this.state.toolTipOpen ? 'secondary' : 'disabled'}
            />
          </IconButton>
        </CopyToClipboard>
      </Tooltip>
    );
  }
}

export default CopyResource;
