import React, { useEffect, useState } from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Webhook } from 'ordercloud-javascript-sdk';
import { Typography, FormLabel } from '@material-ui/core';

interface OptionType {
  label: string;
}

let suggestions: OptionType[] = [];

const splitUrl = (url: string): string[] => {
  try {
    const urlObj = new URL(url);
    return [urlObj.origin, urlObj.pathname];
  } catch (e) {
    return ['', ''];
  }
};

function renderInputComponent(inputProps: any) {
  const { classes, ref, urlDirty, value, ...other } = inputProps;

  return (
    <React.Fragment>
      <TextField
        fullWidth
        className={classes.targetUrl}
        label="Target Origin"
        margin="normal"
        variant="outlined"
        error={urlDirty && !value}
        helperText={urlDirty && !value ? 'Must provide URL' : ' '}
        value={value}
        {...other}
      />
    </React.Fragment>
  );
}

function renderSuggestion(
  suggestion: OptionType,
  { query, isHighlighted }: Autosuggest.RenderSuggestionParams
) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span
            key={part.text}
            style={{ fontWeight: part.highlight ? 500 : 400 }}
          >
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value: string) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function buildSuggestionsList(webhooks: Webhook[]) {
  const origins = webhooks.map(w => splitUrl(w.Url)[0]);
  const noDuplicatesStrings = Array.from(new Set(origins));
  suggestions = noDuplicatesStrings.map(str => {
    return { label: str };
  });
}

function getSuggestionValue(suggestion: OptionType) {
  return suggestion.label;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 250,
      flexGrow: 1,
    },
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing(2),
    },
  })
);

interface AutoCompleteUrlProps {
  readOnly?: boolean;
  handleChange: any;
  webhooks: Webhook[];
  str: string;
}

function AutoCompleteUrl(props: AutoCompleteUrlProps) {
  const classes = useStyles();
  const [stateSuggestions, setSuggestions] = React.useState<OptionType[]>([]);

  const handleSuggestionsFetchRequested = ({ value }: any) => {
    setSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  useEffect(() => {
    buildSuggestionsList(props.webhooks);
  }, [props.webhooks]);

  const [urlDirty, setUrlDirty] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    { newValue }: Autosuggest.ChangeEvent
  ) => {
    props.handleChange(newValue);
    setUrlDirty(true);
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return props.readOnly ? (
    <React.Fragment>
      <FormLabel>Payload URL</FormLabel>
      <Typography>{props.str || 'None Set'}</Typography>
    </React.Fragment>
  ) : (
    <Autosuggest
      {...autosuggestProps}
      inputProps={{
        classes,
        id: 'react-autosuggest-simple',
        label: 'Payload URL *',
        placeholder: 'Enter a url',
        value: props.str,
        onChange: handleChange,
        urlDirty,
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={options => (
        <Paper {...options.containerProps} square>
          {options.children}
        </Paper>
      )}
    />
  );
}

export default AutoCompleteUrl;
