import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Link,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { User } from '@ordercloud/portal-javascript-sdk';
import React, { useCallback, useEffect, useState } from 'react';
import { FC } from 'react';
import { connect } from 'react-redux';
import { AppReducerState } from '../../redux';
import { acceptTermsAndConditions } from '../../redux/auth/authThunk.actions';

interface StateProps {
  user: User;
}

interface DispatchProps {
  acceptTerms: () => any;
}

type AcceptTermsDialogProps = StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: 'blur(5px)',
  },
}));

const AcceptTermsDialog: FC<AcceptTermsDialogProps> = props => {
  const { user, acceptTerms } = props;
  const classes = useStyles();

  const [accepted, setAccepted] = useState(!!user.TermsAccepted);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!accepted) return;
      acceptTerms();
    },
    [accepted, acceptTerms]
  );

  useEffect(() => {
    setAccepted(!!user.TermsAccepted);
  }, [user]);

  const handleCheckboxChange = (e, v) => {
    setAccepted(v);
    console.log(e, v);
  };

  const onClose = () => {
    return;
  };

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      open={Boolean(user && user.Username && !user.TermsAccepted)}
      onClose={onClose}
      disableEscapeKeyDown
      aria-labelledby="update-username-dialog"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="update-username-dialog">
          New Terms and Conditions
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            I confirm that I have read and agree to the{' '}
            <Link
              target="_blank"
              href="https://www.sitecore.com/trust/privacy-policy"
            >
              Sitecore Privacy Policy
            </Link>
            , which explains how Sitecore collects, processes and shares my
            personal data. I consent to my data being processed in accordance
            with Sitecore’s Privacy Policy so that Sitecore can optimize my
            experience with the Sitecore brand. Consent to Sitecore’s Privacy
            Policy
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                value={accepted}
                onChange={handleCheckboxChange}
              ></Checkbox>
            }
            label="I consent to receive communications about Sitecore’s
            business in accordance with Sitecore’s Privacy Policy. I understand
            that I can opt-out at any time."
          ></FormControlLabel>
          <DialogActions>
            <Button
              type="submit"
              disabled={!accepted}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state: AppReducerState) => {
  return {
    user: state.devCenterUser,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    acceptTerms: () => {
      return dispatch(acceptTermsAndConditions());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTermsDialog);
