import { TableCell } from '@material-ui/core';
import React from 'react';
import { SecurityProfile } from 'ordercloud-javascript-sdk';

export function SecurityProfileTableHeaderRow() {
  return (
    <React.Fragment>
      <TableCell align="left">ID</TableCell>
      <TableCell align="left"># Roles</TableCell>
    </React.Fragment>
  );
}

export function SecurityProfileTableRow(
  securityProfile: Partial<SecurityProfile>,
  customRoles: number = securityProfile.CustomRoles &&
  securityProfile.CustomRoles.length
    ? securityProfile.CustomRoles.length
    : 0,
  elevatedRoles: number = securityProfile.Roles && securityProfile.Roles.length
    ? securityProfile.Roles.length
    : 0,
  rolesCount: number = customRoles + elevatedRoles
) {
  return (
    <React.Fragment>
      <TableCell align="left">{securityProfile.ID}</TableCell>
      <TableCell align="left">{rolesCount}</TableCell>
    </React.Fragment>
  );
}
