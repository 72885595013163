import React from 'react';
import {
  determineMessageSenderType,
  MessageSenderTypeAttributes,
} from './constants';
import { MessageSender } from 'ordercloud-javascript-sdk';
import { Tooltip, Chip } from '@material-ui/core';

interface MessageTypeIndicatorProps {
  messageSender: MessageSender;
}

const MessageTypeIndicator = (props: MessageTypeIndicatorProps) => {
  const messageSenderType = determineMessageSenderType(props.messageSender);
  const messageSenderTypeAttributes =
    MessageSenderTypeAttributes[messageSenderType];

  return (
    <Tooltip title={messageSenderTypeAttributes.Description}>
      <Chip
        size="small"
        variant="outlined"
        color="primary"
        label={messageSenderTypeAttributes.Name}
      />
    </Tooltip>
  );
};

export default MessageTypeIndicator;
