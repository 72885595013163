import React from 'react';
import { Tooltip, Typography, Theme, createStyles } from '@material-ui/core';
import Circle from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/styles';
import { red, green } from '@material-ui/core/colors';

interface ActiveIndicatorProps {
  isActive: boolean;
  isActiveToolTipText: string;
  isInactiveToolTipText: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    isActive: {
      color: green[500],
    },
    isInactive: {
      color: red[500],
    },
    isActiveText: {
      color: green[500],
      verticalAlign: 'top',
      marginBottom: '-6px',
    },
    isInactiveText: {
      color: red[500],
      verticalAlign: 'top',
      marginBottom: '-6px',
    },
  })
);

function ActiveIndicator(props: ActiveIndicatorProps) {
  const { isActive, isActiveToolTipText, isInactiveToolTipText } = props;

  const classes = useStyles();

  return (
    <Tooltip
      title={isActive ? isActiveToolTipText : isInactiveToolTipText}
      placement="top-start"
    >
      {isActive ? (
        <React.Fragment>
          <Circle className={classes.isActive} />
          <Typography
            display="inline"
            align="center"
            variant="body1"
            className={classes.isActiveText}
          >
            ACTIVE
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Circle className={classes.isInactive} />
          <Typography
            display="inline"
            variant="body1"
            className={classes.isInactiveText}
          >
            INACTIVE
          </Typography>
        </React.Fragment>
      )}
    </Tooltip>
  );
}

export default ActiveIndicator;
