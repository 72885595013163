import { CreateTab, DestroyTabs, CopyTab } from './tab.types';
import {
  CREATE_TAB,
  DESTROY_TABS,
  SET_TAB_OPERATION,
  SET_TAB,
  SET_TAB_NAME,
  SET_TAB_ENTRY_TYPE,
  SET_TAB_TOKEN,
  CLEAR_TAB_TOKENS,
  SET_TAB_REQUEST,
  MOVE_TAB,
  SET_IS_VIEWING_PRETTY_UI,
  COPY_TAB,
} from './tab.constants';

export function createTab(): CreateTab {
  return {
    type: CREATE_TAB,
    payload: {
      tab: {
        id: Date.now().toString(),
        name: 'Untitled',
        entryType: 'form',
      },
    },
  };
}

export function destroyTabs(tabIdsToDelete: string[]): DestroyTabs {
  return {
    type: DESTROY_TABS,
    payload: {
      tabIdsToDelete,
    },
  };
}

export function setTabOperation(tabId: string, operation: any) {
  return {
    type: SET_TAB_OPERATION,
    payload: {
      tabId,
      operation,
    },
  };
}

export function copyTab(newTabID: string, copiedTabID: string): CopyTab {
  return {
    type: COPY_TAB,
    payload: {
      newTabID,
      copiedTabID,
    },
  };
}

export function setTab(tabId: string) {
  return {
    type: SET_TAB,
    payload: {
      tabId,
    },
  };
}

export function setTabName(tabId: string, name: string) {
  return {
    type: SET_TAB_NAME,
    payload: {
      tabId,
      name,
    },
  };
}

export function setTabEntryType(tabId: string, entryType: string) {
  return {
    type: SET_TAB_ENTRY_TYPE,
    payload: {
      tabId,
      entryType,
    },
  };
}

export function setTabToken(tabId: string, token: string) {
  return {
    type: SET_TAB_TOKEN,
    payload: {
      tabId,
      userToken: token,
    },
  };
}

export function clearTabTokens() {
  return {
    type: CLEAR_TAB_TOKENS,
  };
}

export function setTabRequest(tabId: string, requestId: string) {
  return {
    type: SET_TAB_REQUEST,
    payload: {
      tabId,
      requestId,
    },
  };
}

export function moveTab(tabId: string, newIndex: number) {
  return {
    type: MOVE_TAB,
    payload: {
      tabId,
      newIndex,
    },
  };
}

export function setIsViewingPrettyUI(
  tabId: string,
  isViewingPrettyUI: boolean
) {
  return {
    type: SET_IS_VIEWING_PRETTY_UI,
    payload: {
      tabId,
      isViewingPrettyUI,
    },
  };
}
