import { combineReducers, Reducer } from 'redux';
import { FailureAction, StatusAction } from './requestStatus.types';
import {
  REQUEST_FAILURE,
  REQUEST_START,
  REQUEST_END,
} from './requestStatus.constants';
const errorMessages: Reducer<object[], FailureAction> = (
  state: object[] = [],
  action: FailureAction
) => {
  switch (action.type) {
    case REQUEST_FAILURE:
      return [...state, { [action.payload.requestName]: action.payload.error }];
    default:
      return state;
  }
};
const currentRequests: Reducer<string[], StatusAction> = (
  state: string[] = [],
  action: StatusAction
) => {
  switch (action.type) {
    case REQUEST_START:
      if (state.includes(action.payload.requestName)) {
        return state;
      } else {
        return [...state, action.payload.requestName];
      }
    case REQUEST_END:
      return [...state.filter(i => i !== action.payload.requestName)];
    default:
      return state;
  }
};
export default combineReducers({
  currentRequests,
  errorMessages,
});
