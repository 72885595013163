import { combineReducers, Reducer } from 'redux';
import {
  CANCEL_CONTEXT_SELECTION,
  emptyContext,
  emptySearchTexts,
  SET_CONTEXT_SELECTOR_OPEN,
  SET_SEARCH_TEXT,
  SET_SELLER_ORG_IMPERSONATION_TOKEN,
  SET_USER_CHOICE,
  USER,
  COPY_TAB_CONTEXT,
  SELLER_ORG,
  CLEAR_SELLER_ORG_IMPERSONATION_TOKENS,
  DESTROY_CONTEXT_OPTION,
  DESTROY_CONTEXT_SEARCH,
} from './contextSelections.constants';
import {
  constructNewContext,
  contructNewSearch,
} from './contextSelections.helpers';
import {
  ContextChoicesReducerState,
  ContextSelectionAction,
  SearchTextReducerState,
  SellerOrgsTokenReducerState,
  CopyTabContext,
} from './contextSelections.types';

const contextChoices: Reducer<
  ContextChoicesReducerState,
  ContextSelectionAction
> = (
  state: ContextChoicesReducerState = {},
  action: ContextSelectionAction | CopyTabContext
) => {
  switch (action.type) {
    case SET_USER_CHOICE:
      const newContext = constructNewContext(action, state);

      // save context on the final user selection or on the seller org selection for pretty UIs
      const shouldSaveContext =
        action.payload.contextType === USER ||
        action.payload.contextType === SELLER_ORG;
      const newState = {
        ...state,
        [action.payload.tabID]: {
          saved: shouldSaveContext
            ? newContext
            : state[action.payload.tabID].saved || emptyContext,
          inSelection: newContext,
          isTabOpen: !shouldSaveContext,
        },
      };
      return newState;
    case SET_CONTEXT_SELECTOR_OPEN:
      return {
        ...state,
        [action.payload.tabID]: {
          ...state[action.payload.tabID],
          inSelection:
            (state[action.payload.tabID] &&
              state[action.payload.tabID].saved) ||
            emptyContext,
          isTabOpen: true,
        },
      };
    case CANCEL_CONTEXT_SELECTION:
      return {
        ...state,
        [action.payload.tabID]: {
          ...state[action.payload.tabID],
          isTabOpen: false,
        },
      };
    case COPY_TAB_CONTEXT:
      const { copiedTabID, newTabID } = action.payload;
      const contextSelectionToCopy = state[copiedTabID];
      return {
        ...state,
        [newTabID]: contextSelectionToCopy,
      };
    case DESTROY_CONTEXT_OPTION:
      var stateCopy = Object.assign({}, state);
      delete stateCopy[action.payload.tabID];
      return stateCopy;
    default:
      return state;
  }
};

const searchText: Reducer<SearchTextReducerState, ContextSelectionAction> = (
  state: SearchTextReducerState = {},
  action: ContextSelectionAction | CopyTabContext
) => {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return {
        ...state,
        [action.payload.TabID]: {
          ...emptySearchTexts,
          ...state[action.payload.TabID],
          [action.payload.ContextType]: action.payload.SearchText,
        },
      };
    case SET_USER_CHOICE:
      return {
        ...state,
        [action.payload.tabID]: contructNewSearch(
          action.payload.contextType,
          state[action.payload.tabID]
        ),
      };
    case COPY_TAB_CONTEXT:
      const { copiedTabID, newTabID } = action.payload;
      const searchTextToCopy = state[copiedTabID];
      return {
        ...state,
        [newTabID]: searchTextToCopy,
      };
    case DESTROY_CONTEXT_SEARCH:
      var stateCopy = Object.assign({}, state);
      delete stateCopy[action.payload.tabID];
      return stateCopy;
    default:
      return state;
  }
};

const sellerOrgTokens: Reducer<
  SellerOrgsTokenReducerState,
  ContextSelectionAction
> = (
  state: SellerOrgsTokenReducerState = {},
  action: ContextSelectionAction
) => {
  switch (action.type) {
    case SET_SELLER_ORG_IMPERSONATION_TOKEN:
      return {
        ...state,
        [action.payload.sellerOrgID]: action.payload.token,
      };
    case CLEAR_SELLER_ORG_IMPERSONATION_TOKENS:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  contextChoices,
  searchText,
  sellerOrgTokens,
});
