export const GETTING_SELLER_ORGS = 'GETTING_SELLER_ORGS';
export type GETTING_SELLER_ORGS = typeof GETTING_SELLER_ORGS;

export const GETTING_SELLER_ORG_IMPERSONATION_TOKEN =
  'GETTING_SELLER_ORG_IMPERSONATION_TOKEN';
export type GETTING_SELLER_ORG_IMPERSONATION_TOKEN = typeof GETTING_SELLER_ORG_IMPERSONATION_TOKEN;

export const GETTING_API_CLIENTS = 'GETTING_API_CLIENTS';
export type GETTING_API_CLIENTS = typeof GETTING_API_CLIENTS;

export const GETTING_USERS = 'GETTING_USERS';
export type GETTING_USERS = typeof GETTING_USERS;

export const GETTING_USER_IMPERSONATION_TOKEN =
  'GETTING_USER_IMPERSONATION_TOKEN';
export type GETTING_USER_IMPERSONATION_TOKEN = typeof GETTING_USER_IMPERSONATION_TOKEN;

export const MAKE_CONSOLE_REQUEST = 'MAKE_CONSOLE_REQUEST';
export type MAKE_CONSOLE_REQUEST = typeof MAKE_CONSOLE_REQUEST;
