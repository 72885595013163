export const menuItems = {
  MainNavigation: [
    {
      value: 'portal',
      label: 'Portal',
      to: '/',
      mobileMenu: false,
      authRequired: true,
      isPortalLink: true,
    },
    {
      value: 'learn',
      label: 'Learn',
      to: '/learn/ordercloud-basics/architecture',
      mobileMenu: false,
      authRequired: false,
      isPortalLink: false,
    },
    {
      value: 'kb',
      label: 'Knowledge Base',
      to: '/knowledge-base',
      mobileMenu: false,
      authRequired: false,
      isPortalLink: false,
    },
    {
      value: 'tools',
      label: 'Developer Tools',
      to: '/developer-tools',
      mobileMenu: false,
      authRequired: false,
      isPortalLink: false,
    },
    {
      value: 'api-reference',
      label: 'Api Reference',
      to: '/api-reference',
      mobileMenu: false,
      authRequired: false,
      isPortalLink: false,
    },
    {
      value: 'community',
      label: 'Community',
      to: '/slack',
      mobileMenu: false,
      authRequired: false,
      isPortalLink: false,
    },
    {
      value: 'release-notes',
      label: 'Release Notes',
      to: '/release-notes/',
      mobileMenu: true,
      authRequired: false,
      isPortalLink: false,
    },
  ],
  DropdownControls: [
    {
      label: 'Dashboard',
      to: '/',
    },
    {
      label: 'Marketplaces',
      to: '/marketplaces',
    },
    {
      label: 'API Console',
      to: '/console',
    },
    {
      label: 'My Profile',
      to: '/settings/profile',
    },
  ],
};
