import React, { createContext, FunctionComponent } from 'react';
import { AppReducerState } from '../../redux';
import useApiSpec from '../Shared/hooks/useApiSpec';
import { connect } from 'react-redux';

interface ApiSpecContextProps {
  currentBaseUrl: string;
}

export const ApiSpecContext = createContext<any>({});

const ApiSpecContextProvider: FunctionComponent<ApiSpecContextProps> = ({
  children,
  currentBaseUrl,
}) => {
  const apiSpecValue = useApiSpec(currentBaseUrl);
  return (
    <ApiSpecContext.Provider value={apiSpecValue}>
      {children}
    </ApiSpecContext.Provider>
  );
};

function mapStateToProps(state: AppReducerState) {
  const activeTabId = state.tabs.tabsMeta.activeId;
  const contextChoices = state.contextSelections.contextChoices[activeTabId];
  const currentBaseUrl =
    contextChoices &&
    contextChoices.inSelection &&
    contextChoices.inSelection.SELLER_ORG &&
    contextChoices.inSelection.SELLER_ORG.CoreApiUrl
      ? contextChoices.inSelection.SELLER_ORG.CoreApiUrl
      : 'https://api.ordercloud.io';

  return {
    currentBaseUrl,
  };
}

export default connect(mapStateToProps)(ApiSpecContextProvider);
